// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,400;0,700;1,100;1,400;1,700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.C_FkHYj4NUSR5vdfWlC9 {
  display: flex;
}
.DXhhV4NivIOwxgkYVahG {
  display: flex;
}
.LUIDFgnAWfo3yHO3_3to {
  visibility: hidden;
}`, "",{"version":3,"sources":["webpack://./src/pages/private/production-page/tabs-page/manufacturing/step-actions/step-actions.module.scss"],"names":[],"mappings":"AAEE;EACE,aAAA;AAAJ;AAEE;EACE,aAAA;AAAJ;AAEI;EACE,kBAAA;AAAN","sourcesContent":["@use '@anatoscope/circlestorybook/dist/vars';\n.step-actions {\n  &__date {\n    display: flex;\n  }\n  &__actions {\n    display: flex;\n\n    &--hidden {\n      visibility: hidden;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"step-actions__date": `C_FkHYj4NUSR5vdfWlC9`,
	"step-actions__actions": `DXhhV4NivIOwxgkYVahG`,
	"step-actions__actions--hidden": `LUIDFgnAWfo3yHO3_3to`
};
export default ___CSS_LOADER_EXPORT___;

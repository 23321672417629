import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from './streaming-page.module.scss';
import { useGetOneManufacturingOrderQuery } from '../../../services/manufacturing-orders-api.services';
import { useTranslation } from 'react-i18next';
import { WorkflowStepEnum } from '../../../enum/workflow-step';

const StreamingPage = () => {
  const { t } = useTranslation();

  const [streamingParams, setStreamingParams] = useState(null);
  useEffect(() => {
    const css = document.createElement('link');
    css.setAttribute('rel', 'stylesheet');
    css.setAttribute('href', '/js/anatostreamer/static/anatostreamer.bundle.css');
    document.body.appendChild(css);

    const fonts = document.createElement('link');
    fonts.setAttribute('rel', 'stylesheet');
    fonts.setAttribute('href', 'https://fonts.googleapis.com/icon?family=Material+Icons');
    document.body.appendChild(fonts);

    const script = document.createElement('script');
    script.setAttribute('src', '/js/anatostreamer/static/anatostreamer.bundle.js');
    document.body.appendChild(script);

    // Set a callback function to execute when the script is loaded
    script.onload = () => {
      // Code to run after the script is loaded
      if (streamingParams) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const anatostreamer = new AnatoStreamer('streaming-div');
        const config = {
          host: streamingParams.host,
          vnc: {
            port: streamingParams.port,
            path: streamingParams.path,
            passwd: streamingParams.password,
            encrypt: streamingParams.encrypt,
            autoRescale: true, // enable scaleviewport option, default true
            autoQuality: false,
            viewOnly: false,
            retry: 60, // number of connection before connect_failed callback, default 5
            retryInterval: 5000, // interval (ms) between retries, default 1000
            qualityLevel: 5,
            compressionLevel: 5,
            localCursor: true,
            // eslint-disable-next-line camelcase
            log_level: 'warn', // define the console log level, can be `warn`, `error`, `info` or
            waitingBackground: '#2A1565'
          },
          menu: {
            enabled: true,
            // settings: ['vnc-quality', "menu-position"],
            settings: [
              'view-only',
              'scale-mode',
              'vnc-cursor-fallback-policy',
              'vnc-auto-quality',
              'vnc-quality',
              'vnc-compression'
            ]
          },
          webrtc: {
            port: (parseInt(streamingParams.port, 10) + 10).toString(), // webrtc=vnc port+10
            password: streamingParams.password, // webrtc password
            encrypt: streamingParams.encrypt,
            loggerLevel: 'warn',
            codecFilter: ''
          }, // webrtc
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          onConnectionLost: (error: object, params: object) => {
            console.log('Connection lost');
            let seconds = 5;
            document.getElementById('info-streaming').innerHTML = t('streamer.closing', {
              ns: 'circleCadStreamer'
            }).replace('%seconds%', seconds.toString());

            setInterval(() => {
              seconds--;
              document.getElementById('count').textContent = seconds.toString();
              if (seconds === 0) {
                window.close();
              }
            }, 1000);
          },
          onFocusLostVisual: true
        };
        anatostreamer
          .connect(config)
          .then(() => {
            console.log('Connected');
          })
          .catch((error: object) => {
            console.error(error);
          });
      }
    };

    return () => {
      // clean up the script when the component is unmounted
      document.body.removeChild(css);
      document.body.removeChild(script);
      document.body.removeChild(fonts);
    };
  }, [streamingParams]);

  const { orderNumber } = useParams();
  const [skipValue, setSkipValue] = useState<boolean>(false);

  const { data: manufacturingOrder } = useGetOneManufacturingOrderQuery(orderNumber, {
    pollingInterval: 10000,
    skip: skipValue
  });

  useEffect(() => {
    let action = null;
    switch (manufacturingOrder?.currentStep) {
      case WorkflowStepEnum.MODELING_PREPARE: {
        action = 'prepare';
        break;
      }

      case WorkflowStepEnum.MODELING_DESIGN: {
        action = 'design';
        break;
      }

      case WorkflowStepEnum.DELIVERY:
      case WorkflowStepEnum.CONTROL:
      case WorkflowStepEnum.MANUFACTURING: {
        action = 'view';
        break;
      }
    }

    if (action) {
      document.title = document.title = `${t('streamer.' + action, { ns: 'circleCadStreamer' })} ${
        manufacturingOrder.orderNumber
      }`;
    }
  }, [manufacturingOrder]);

  if (manufacturingOrder?.streamingParameters) {
    setSkipValue(true);
    setStreamingParams(JSON.parse(manufacturingOrder?.streamingParameters));
  }

  return (
    <>
      <div className={styles['streaming-page__wait-screen']}>
        <div className={styles['streaming-page__wait-screen--logo-wrapper']}>
          <img
            alt="cirle logo"
            className={styles['streaming-page__wait-screen--logo']}
            src="images/logo/logo-circle-label-white.svg"
          />
          <img alt="" className={styles['streaming-page__spacer']} src="images/separation.png" />
          {manufacturingOrder?.currentStep === WorkflowStepEnum.MODELING_PREPARE && (
            <img
              alt="digital twin"
              className={styles['streaming-page__spacer']}
              src="images/digital-twin.svg"
            />
          )}

          {manufacturingOrder?.currentStep === WorkflowStepEnum.MODELING_DESIGN && (
            <img
              alt="intuitive design"
              className={styles['streaming-page__spacer']}
              src="images/intuitive-design.svg"
            />
          )}
          {(manufacturingOrder?.currentStep === WorkflowStepEnum.DELIVERY ||
            manufacturingOrder?.currentStep === WorkflowStepEnum.CONTROL ||
            manufacturingOrder?.currentStep === WorkflowStepEnum.MANUFACTURING) && (
            <img
              alt="view"
              className={styles['streaming-page__spacer']}
              src="assets/images/view.svg"
            />
          )}
        </div>
        <article className={styles['streaming-page__text-container']}>
          <div className={styles['streaming-page__text-aligner']}>
            <span className={styles['streaming-page__text-wrapper']}>
              {manufacturingOrder?.orderNumber}
            </span>
            <div id="info-streaming">{t('streamer.loading', { ns: 'circleCadStreamer' })}</div>
          </div>
        </article>
      </div>

      <div id="streaming-div" className={styles['streaming-page__container']}></div>
    </>
  );
};

export default StreamingPage;

import { OrdersReducer } from '../../models/order';

interface ReducerState {
  ordersState: OrdersReducer;
}

export const orderSelector = (state: ReducerState) => {
  return state?.ordersState?.order;
};

export const diagnosticSelector = (state: ReducerState) => {
  return state?.ordersState?.order.patient?.diagnostic;
};

export const orderFilesSelector = (state: ReducerState) => {
  return state?.ordersState?.files;
};

export const orderItemsSelector = (state: ReducerState) => {
  return state?.ordersState?.items;
};

export const orderFile3dToDisplaySelector = (state: ReducerState) => {
  return state?.ordersState?.file3dToDisplay;
};

export const orderFileImageToDisplaySelector = (state: ReducerState) => {
  return state?.ordersState?.fileImageToDisplay;
};

export const orderFileTextureToDisplaySelector = (state: ReducerState) => {
  return state?.ordersState?.fileTextureToDisplay;
};

export const currentItemSelector = (state: ReducerState) => {
  return state?.ordersState?.currentItem;
};

export const addProductStepIndexSelector = (state: ReducerState) => {
  return state?.ordersState?.addProductStepIndex;
};

export const selectedFamilyValueSelector = (state: ReducerState) => {
  return state?.ordersState?.selectedFamilyValue;
};

export const selectedCategoryValueSelector = (state: ReducerState) => {
  return state?.ordersState?.selectedCategoryValue;
};

export const orderErrorSelector = (state: ReducerState) => {
  return state?.ordersState?.error;
};

export const isLoadingFilesSelector = (state: ReducerState) => {
  return state.ordersState.isLoadingFiles;
};

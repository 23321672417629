import { ManufacturingProductionTypeEnum } from './component';
import { WorkflowManufacturingStepEnum } from './workflow-step';

export interface ManufacturingStepDisplay {
  [key: string]: WorkflowManufacturingStepEnum[];
}

export enum manufacturingStepKeysEnum {
  DESIGN = 'DESIGN',
  PRODUCTION = 'PRODUCTION'
}

export const manufacturingStepDisplay: ManufacturingStepDisplay = {
  DESIGN: [WorkflowManufacturingStepEnum.TO_DESIGN, WorkflowManufacturingStepEnum.DESIGNED],
  PRODUCTION: [
    WorkflowManufacturingStepEnum.TO_MANUFACTURE,
    WorkflowManufacturingStepEnum.MANUFACTURED
  ]
};

export interface PatchManufacturingStepBody {
  productId: number;
  componentId: number;
  manufacturingStep?: WorkflowManufacturingStepEnum;
}

export interface PatchManufacturingComponentToothBody {
  productId: number;
  componentId: number;
  tooth?: ManufacturingProductionTypeEnum;
}

import React from 'react';
import { Outlet } from 'react-router-dom';
import './public-app.scss';

const PublicApp = () => {
  return (
    <div className="public-app">
      <main>
        <Outlet />
      </main>
    </div>
  );
};
export default PublicApp;

import React, { useEffect } from 'react';
import styles from './update-laboratory-tags-form.module.scss';
import { Box, Button, Chips, IconButton, Text, TextField } from '@anatoscope/circlestorybook';
import { useAppDispatch } from '../../../../store/hooks';
import { useTranslation } from 'react-i18next';
import useForm from '../../../../utils/useForm';
import { feedbackActions } from '../../../../store/feedback/feedback.reducer';
import { ColorPropsEnum } from '../../../../enum/color.enum';
import { ToastType } from '../../../../enum/feedback';
import { Establishment } from '../../../../models/establishment';
import { useUpdateEstablishmentMutation } from '../../../../services/establishments-api.services';

type props = {
  onSubmitUpdateTagsCallback: () => void;
  laboratory: Establishment;
};

const UpdateLaboratoryTagsForm = ({ onSubmitUpdateTagsCallback, laboratory }: props) => {
  const { t } = useTranslation(['preference']);
  const dispatch = useAppDispatch();
  const [
    patchEstablishment,
    { isLoading: isLoadingPatchEstablishment, isSuccess: isPatchedEstablishment }
  ] = useUpdateEstablishmentMutation();

  const onSubmit = () => {
    if (laboratory?.tags !== values.tags) {
      patchEstablishment({ ...laboratory, tags: values.tags as string[] });
    } else {
      onSubmitUpdateTagsCallback();
    }
  };

  useEffect(() => {
    if (isPatchedEstablishment) {
      dispatch(
        feedbackActions.setToast({
          message: t('editLaboratoryTagsForm.success'),
          type: ToastType.SUCCESS
        })
      );
      onSubmitUpdateTagsCallback();
    }
  }, [isPatchedEstablishment]);

  const { values, errors, handleSubmit, handleChange, handleAdd, handleDelete, resetValue } =
    useForm(
      {
        newTag: '',
        tags: laboratory?.tags || []
      },
      onSubmit,
      // No field required so validation is always positive
      () => {
        return {};
      }
    );

  return (
    <Box color={ColorPropsEnum.WHITE} className={styles['update-laboratory-tags-form__box']}>
      <form onSubmit={handleSubmit}>
        <Text
          size="s"
          type="title"
          label={t('editLaboratoryTagsForm.form.addNewLabTags')}
          className={styles['update-laboratory-tags-form__title']}></Text>
        <div className={styles['update-laboratory-tags-form__addTagForm']}>
          <TextField
            id="newTag"
            name="newTag"
            value={values.newTag}
            onChange={handleChange}
            size="m"
            type="text"
            className={styles['update-laboratory-tags-form__addTagForm__textfield']}
            helperText={
              errors['newTag'] ? t(`editLaboratoryTagsForm.errors.newTag.${errors['newTag']}`) : ''
            }
            variant={errors['newTag'] ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
            data-cy="addNewLaboratoryTagTextField"
          />
          <IconButton
            onClick={() => {
              handleAdd('newTag', 'tags', true);
              resetValue('newTag');
            }}
            size="m"
            bgColor="primary"
            color="white"
            iconSize="sm"
            faIconClass="plus"
            radius="full"
            data-cy="addNewLaboratoryTagButton"
          />
        </div>

        <div className={styles['update-laboratory-tags-form__tagList']}>
          <Text
            size="s"
            type="title"
            label={t('editLaboratoryTagsForm.form.existingLabTags')}
            className={styles['update-laboratory-tags-form__title']}></Text>
          <div
            className={styles['update-laboratory-tags-form__tagList__list']}
            data-cy="sideBarTagList">
            {(values?.tags as string[])?.length > 0
              ? (values.tags as string[]).map((tag) => (
                  <div key={tag}>
                    <Chips
                      key={tag}
                      className={styles['update-laboratory-tags-form__tagList__list__chips']}
                      color={ColorPropsEnum.PURPLE_LIGHT}
                      onDelete={(event: React.FormEvent) => {
                        handleDelete(event, 'tags');
                      }}
                      firstLabel={tag}
                    />
                  </div>
                ))
              : t('noTags')}
          </div>

          <div className={styles['update-laboratory-tags-form__tagList__submit']}>
            <Button
              label={t('editLaboratoryTagsForm.action')}
              isLoading={isLoadingPatchEstablishment}
              type="submit"
              variant="success"
              iconLeft="fa-check"
              data-cy="editLaboratoryTagsSubmit"
            />
          </div>
        </div>
      </form>
    </Box>
  );
};

export default UpdateLaboratoryTagsForm;

import { createSelector } from '@reduxjs/toolkit';
import { MapReducer, Position, sortedPositionsArray } from '../../models/map';
import { PositionKey, ToothShadeEnum, ToothShapeEnum } from '../../enum/component';
import { OrdersReducer } from '../../models/order';

import {
  CursorEnum,
  PositionKeyString,
  SelectionContextEnum,
  ToothSelectionEnum,
  ZoneLinkEnum
} from '../../enum/map.enum';
import { ColorPropsEnum, FamilyColorEnum, SimpleColorsEnum } from '../../enum/color.enum';
import { TeethMode } from '../../enum/product.enum';
import { NotAllowedCursors } from '../../features/order-manager/teeth-map/cursors.utils';
import { currentItemSelector } from '../orders/orders.selectors';

interface ReducerState {
  mapState: MapReducer;
  ordersState: OrdersReducer;
}

interface ComponentsForTeethMap {
  toothPositions: number[][];
  gingivaPositions: number[][];
  framePositions: number[][];
  stumpPositions: number[][];
  inlayPositions: number[][];
  onlayPositions: number[][];
  overlayPositions: number[][];
}

const positionsGroupedByProductId = (
  positions: Array<Position>
): { [key: string]: Array<number> } => {
  return positions.reduce((groupedPositions: Record<string, number[]>, position: Position) => {
    if (position.productIds?.length) {
      position.productIds.forEach((productId) => {
        if (!groupedPositions[productId.uniqueProductId]) {
          groupedPositions[productId.uniqueProductId] = [];
        }
        groupedPositions[productId.uniqueProductId].push(+position.notation);
      });
    } else if (ToothSelectionEnum.SELECTED === position.selection) {
      if (!groupedPositions['-1']) {
        groupedPositions['-1'] = [];
      }
      groupedPositions['-1'].push(+position.notation);
    }
    return groupedPositions;
  }, {});
};

/**
 * Computes all array of values for components needed for the patientMouth in teeth map to work
 * In short , all position number must be sorted in array by productId
 *
 * @param {Position[]} positions
 * @returns {ComponentsForTeethMap}
 */
const computeComponentsForTeethMapByProduct = (
  positions: Array<Position>
): ComponentsForTeethMap => {
  const toothPositions = positionsGroupedByProductId(
    positions.filter((position) => position.tooth)
  );
  const gingivaPositions = positionsGroupedByProductId(
    positions.filter((position) => position.gingiva)
  );
  const framePositions = positionsGroupedByProductId(
    positions.filter((position) => position.frame)
  );
  const stumpPositions = positionsGroupedByProductId(
    positions.filter((position) => position.stump)
  );
  const inlayPositions = positionsGroupedByProductId(
    positions.filter((position) => position.inlay)
  );
  const onlayPositions = positionsGroupedByProductId(
    positions.filter((position) => position.onlay)
  );
  const overlayPositions = positionsGroupedByProductId(
    positions.filter((position) => position.overlay)
  );

  return {
    toothPositions: Object.keys(toothPositions).map((productId) => toothPositions[productId]),
    gingivaPositions: Object.keys(gingivaPositions).map((productId) => gingivaPositions[productId]),
    framePositions: Object.keys(framePositions).map((productId) => framePositions[productId]),
    stumpPositions: Object.keys(stumpPositions).map((productId) => stumpPositions[productId]),
    inlayPositions: Object.keys(inlayPositions).map((productId) => inlayPositions[productId]),
    onlayPositions: Object.keys(onlayPositions).map((productId) => onlayPositions[productId]),
    overlayPositions: Object.keys(overlayPositions).map((productId) => overlayPositions[productId])
  };
};

export const mapSelector = (state: ReducerState) => {
  return state?.mapState?.positions;
};

export const mapContextSelector = (state: ReducerState) => {
  return state?.mapState?.mapContext;
};

const mapTeethShades = (positions: Array<Position>): { [key: string]: Array<number> } => {
  const shades: { [key: string]: Array<number> } = {};
  for (const shade in ToothShadeEnum) {
    const value = positions
      .filter((position) => shade === position.teethShade)
      .map((position) => (position?.notation ? +position.notation : undefined));
    if (value?.length) {
      shades[shade.toLowerCase()] = value;
    }
  }

  return shades;
};

const mapInlayShades = (positions: Array<Position>): { [key: string]: Array<number> } => {
  const shades: { [key: string]: Array<number> } = {};
  for (const shade in ToothShadeEnum) {
    const value = positions
      .filter((position) => shade === position.inlayShade)
      .map((position) => (position?.notation ? +position.notation : undefined));
    if (value?.length) {
      shades[shade.toLowerCase()] = value;
    }
  }

  return shades;
};

const mapOnlayShades = (positions: Array<Position>): { [key: string]: Array<number> } => {
  const shades: { [key: string]: Array<number> } = {};
  for (const shade in ToothShadeEnum) {
    const value = positions
      .filter((position) => shade === position.onlayShade)
      .map((position) => (position?.notation ? +position.notation : undefined));
    if (value?.length) {
      shades[shade.toLowerCase()] = value;
    }
  }

  return shades;
};

const mapOverlayShades = (positions: Array<Position>): { [key: string]: Array<number> } => {
  const shades: { [key: string]: Array<number> } = {};
  for (const shade in ToothShadeEnum) {
    const value = positions
      .filter((position) => shade === position.overlayShade)
      .map((position) => (position?.notation ? +position.notation : undefined));
    if (value?.length) {
      shades[shade.toLowerCase()] = value;
    }
  }

  return shades;
};

const mapShapes = (positions: Array<Position>): { [key: string]: Array<number> } => {
  const shapes: { [key: string]: Array<number> } = {};
  for (const shade in ToothShapeEnum) {
    const value = positions
      .filter((position) => shade === position.teethShade)
      .map((position) => (position?.notation ? +position.notation : undefined));
    if (value?.length) {
      shapes[shade.toLowerCase()] = value;
    }
  }
  return shapes;
};

// Teeth, shades, gingiva, ...
export const mapComponentsSelector = createSelector([mapSelector], (positions) => {
  const positionList = Object.values(positions);
  const componentForTeethMap = computeComponentsForTeethMapByProduct(positionList);

  return {
    EXTRACT: positionList
      .map((position) => (position.extract && position.notation ? +position.notation : undefined))
      .filter((element) => element !== undefined),
    MISSING: positionList
      .map((position) => (position.missing && position.notation ? +position.notation : undefined))
      .filter((element) => element !== undefined),
    TOOTH: componentForTeethMap.toothPositions,
    GINGIVA: componentForTeethMap.gingivaPositions,
    FRAME: componentForTeethMap.framePositions,
    TEETH_SHADES: mapTeethShades(positionList),
    INLAY_SHADES: mapInlayShades(positionList),
    ONLAY_SHADES: mapOnlayShades(positionList),
    OVERLAY_SHADES: mapOverlayShades(positionList),
    SHAPES: mapShapes(positionList),
    STUMP: componentForTeethMap.stumpPositions,
    INLAY: componentForTeethMap.inlayPositions,
    ONLAY: componentForTeethMap.onlayPositions,
    OVERLAY: componentForTeethMap.overlayPositions
  };
});

export const activeItemSelector = createSelector([mapSelector], (positions) => {
  const positionList = Object.values(positions);
  return {
    TOOTH: positionList
      .map((position) =>
        position?.notation && ToothSelectionEnum.SELECTED === position.selection
          ? +position?.notation
          : undefined
      )
      .filter((element) => element !== undefined),
    GINGIVA: positionList.find((position) => ToothSelectionEnum.SELECTED === position.selection)
      ?.gingivaShade,
    SHADE: positionList.find((position) => ToothSelectionEnum.SELECTED === position.selection)
      ?.teethShade,
    SHAPE: positionList.find((position) => ToothSelectionEnum.SELECTED === position.selection)
      ?.shape
  };
});

export const cursorsSelector = createSelector(
  [mapSelector, mapContextSelector, currentItemSelector],
  (positions, mapContext, currentItem) => {
    const cursors: { [key: number]: CursorEnum } = { ...NotAllowedCursors };
    Object.keys(positions).forEach((position) => {
      switch (positions[position].selection) {
        case ToothSelectionEnum.UNSELECTABLE:
          cursors[+position] = CursorEnum.NOT_ALLOWED;
          break;
        case ToothSelectionEnum.SELECTABLE:
          if (mapContext?.teethMode === TeethMode.SINGLE_TOOTH) {
            cursors[+position] = currentItem?.product ? CursorEnum.ADD : CursorEnum.POINTER;
          } else if (
            !mapContext?.userAction ||
            mapContext?.userAction === SelectionContextEnum.RANGE_ENDED ||
            mapContext?.userAction === SelectionContextEnum.ZONE_ENDED
          ) {
            cursors[+position] = CursorEnum.START;
          } else if (
            mapContext?.userAction === SelectionContextEnum.RANGE_STARTED ||
            mapContext?.userAction === SelectionContextEnum.ZONE_STARTED
          ) {
            cursors[+position] = CursorEnum.END;
          } else {
            cursors[+position] = CursorEnum.POINTER;
          }
          break;
        case ToothSelectionEnum.SELECTED:
          if (
            mapContext?.userAction === SelectionContextEnum.ZONE_STARTED &&
            position === mapContext.start
          ) {
            cursors[+position] = CursorEnum.END;
          }
          if (mapContext?.teethMode === TeethMode.SINGLE_TOOTH) {
            cursors[+position] = CursorEnum.REMOVE;
          }
          break;
      }
    });

    return cursors;
  }
);

export const zoneLinkPropsSelector = createSelector([mapSelector], (positions) => {
  let startZone: PositionKeyString | null = null;
  let endZone: PositionKeyString | null = null;
  let nextStartZone: PositionKeyString | null = null;
  const zoneLinkProps: Array<{
    startPosition: PositionKey;
    endPosition: PositionKey;
    color: SimpleColorsEnum.GREY | SimpleColorsEnum.PINK;
  }> = [];

  sortedPositionsArray.forEach((position: PositionKeyString) => {
    switch (positions[position]?.zone_link) {
      case ZoneLinkEnum.START:
        startZone = position;
        break;
      case ZoneLinkEnum.END:
        endZone = position;
        break;
      case ZoneLinkEnum.END_START:
        if (startZone) {
          endZone = position;
          nextStartZone = position;
        }
        break;
    }

    // We have a start and end position for our zone link, we can save it for display
    if (startZone && endZone) {
      zoneLinkProps.push({
        startPosition: +startZone as PositionKey,
        endPosition: +endZone as PositionKey,
        color: ColorPropsEnum.GREY // TODO handle color according to Definitive/Provisional product
      });
      startZone = nextStartZone || null;
      endZone = null;
    }
  });
  return zoneLinkProps;
});

export const lineAndNumberColorsSelector = createSelector([mapSelector], (positions) => {
  const positionList = Object.values(positions);
  return {
    'family-remov': positionList
      .filter((position) => position.familyColor === FamilyColorEnum.FAMILY_REMOV)
      .map((position) => (position?.notation ? +position.notation : undefined)),
    'family-fixed': positionList
      .filter((position) => position.familyColor === FamilyColorEnum.FAMILY_FIXED)
      .map((position) => (position?.notation ? +position.notation : undefined)),
    'family-implant': positionList
      .filter((position) => position.familyColor === FamilyColorEnum.FAMILY_IMPLANT)
      .map((position) => (position?.notation ? +position.notation : undefined)),
    'family-guards': positionList
      .filter((position) => position.familyColor === FamilyColorEnum.FAMILY_GUARDS)
      .map((position) => (position?.notation ? +position.notation : undefined)),
    'family-ocr': positionList
      .filter((position) => position.familyColor === FamilyColorEnum.FAMILY_OCR)
      .map((position) => (position?.notation ? +position.notation : undefined))
  };
});

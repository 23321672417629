import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Family, ProductCategory } from '../../enum/product.enum';
import {
  OrderFile,
  OrderForCreation,
  OrderForUpdate,
  OrderItem,
  OrderItemLight,
  OrdersReducer
} from '../../models/order';
import { DiagnosticCreation } from '../../models/patient';

const initialOrder: OrdersReducer = {
  order: {
    dentistName: null,
    dentistEmail: null,
    clinicName: null,
    clinicId: null,
    toManufacture: true,
    patient: { reference: null, diagnostic: null },
    expectedDate: null,
    instructions: null,
    labId: null,
    labName: null
  },
  files: [],
  items: [],
  currentItem: undefined,
  error: undefined,
  file3dToDisplay: null,
  fileTextureToDisplay: null,
  fileImageToDisplay: null,
  addProductStepIndex: 1,
  selectedFamilyValue: undefined,
  selectedCategoryValue: undefined,
  isLoadingFiles: false
};

export const ordersSlice = createSlice({
  name: 'orders',
  initialState: initialOrder,
  reducers: {
    setOrder: (state, action: PayloadAction<OrderForCreation | OrderForUpdate>) => {
      state.order = action.payload;
    },
    setDiagnostic: (state, action: PayloadAction<DiagnosticCreation>) => {
      state.order.patient.diagnostic = action.payload;
    },
    pushItem: (state, action: PayloadAction<OrderItemLight | OrderItem>) => {
      state.items = [...state.items, action.payload];
    },
    setItems: (state, action: PayloadAction<(OrderItemLight | OrderItem)[]>) => {
      state.items = action.payload;
    },
    setFiles: (state, action: PayloadAction<OrderFile[]>) => {
      state.files = action.payload;
    },
    setFile3dToDisplay: (state, action: PayloadAction<OrderFile>) => {
      state.file3dToDisplay = action.payload;
    },
    setFileImageToDisplay: (state, action: PayloadAction<OrderFile>) => {
      state.fileImageToDisplay = action.payload;
    },
    setFileTextureToDisplay: (state, action: PayloadAction<OrderFile>) => {
      state.fileTextureToDisplay = action.payload;
    },
    setCurrentItem: (state, action: PayloadAction<OrderItemLight>) => {
      state.currentItem = action.payload;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    setAddProductStepIndex: (state, action: PayloadAction<number>) => {
      state.addProductStepIndex = action.payload;
    },
    setSelectedFamilyValue: (state, action: PayloadAction<Family>) => {
      state.selectedFamilyValue = action.payload;
    },
    setSelectedCategoryValue: (state, action: PayloadAction<ProductCategory>) => {
      state.selectedCategoryValue = action.payload;
    },
    setLoadingFiles: (state, action: PayloadAction<boolean>) => {
      state.isLoadingFiles = action.payload;
    },
    resetOrder: () => initialOrder
  },
  extraReducers: (builder: ActionReducerMapBuilder<OrdersReducer>) => {
    builder.addCase('RESET_ALL', () => {
      return { ...initialOrder };
    });
  }
});

export const ordersActions = ordersSlice.actions;

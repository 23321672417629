import React from 'react';
import i18next from 'i18next';
import moment from 'moment';
import {
  ComponentType,
  DentalArchEnum,
  DentalArchEnumWithBoth,
  OrderedLowerPositions,
  OrderedUpperPositions,
  PositionKey,
  ToothShadeEnum
} from '../../../enum/component';
import { ToolEnum } from '../../../enum/map.enum';
import {
  MapComponents,
  notationToIndex,
  TeethmapBubbleFeatureProps,
  TeethmapZoneLinkProps
} from '../../../models/map';
import { Order, OrderItem, OrderItemComponent, OrderItemLight } from '../../../models/order';
import { ColorPropsEnum, FamilyColorEnum } from '../../../enum/color.enum';
import OrderItemTooltip from '../../../pages/private/order-detail-page/tabs/detail-tab/order-item-tooltip/OrderItemTooltip';
import { Family, TeethMode } from '../../../enum/product.enum';
import { defaultProductIconUrl } from '../../../utils/order.utils';
import { isCategoryProvisional } from '../../order-form/utils';

/**
 * Computes positions to select between two limits/nound
 *
 *
 * @param {string} bound1 - tooth notation
 * @param {string} bound2 - tooth notation
 * @returns {Array<string>} - an array of teeth notation to select
 */
export const computeRangeKeys = (bound1: string, bound2: string): Array<string> => {
  const min = Math.min(notationToIndex[bound1], notationToIndex[bound2]);
  const max = Math.max(notationToIndex[bound1], notationToIndex[bound2]);

  return Object.keys(notationToIndex).filter(
    (key) => notationToIndex[key] >= min && notationToIndex[key] <= max
  );
};

export const getRelevantComponentsForDisplay = (mapComponents: MapComponents) => {
  return {
    MISSING: mapComponents['MISSING'],
    EXTRACT: mapComponents['EXTRACT'],
    TOOTH: mapComponents['TOOTH'],
    GINGIVA: mapComponents['GINGIVA'],
    FRAME: mapComponents['FRAME'],
    STUMP: mapComponents['STUMP'],
    INLAY: mapComponents['INLAY'],
    ONLAY: mapComponents['ONLAY'],
    OVERLAY: mapComponents['OVERLAY']
  };
};

/*
 * Retrieves, if one, the desired component of an item
 *
 * @param {item} Item - The item to search in.
 * @param {type} string - The component type desired.
 * @returns {OrderItemComponent | undefined} The OrderItemComponent desired.
 */
export const getComponentInItemByType = (
  item: OrderItem,
  type: ComponentType
): OrderItemComponent | undefined => {
  return item.itemComponents?.find((component) => type === component.componentType);
};

/**
 * Returns the dental arch for an item
 * @param item
 */
export const getItemDentalArch = (item: OrderItem): DentalArchEnumWithBoth => {
  // A component should always have at least one of these positions set
  return getDentalArchFromPositions(
    item.itemComponents?.[0].teethPositions ||
      item.itemComponents?.[0].stumpPositions ||
      item.itemComponents?.[0].injectionPositions
  );
};

/**
 * Return the dental arch from the possitions
 * @param positions
 */
export const getDentalArchFromPositions = (positions: PositionKey[]): DentalArchEnumWithBoth => {
  const onLowerArch = OrderedLowerPositions.some((pos) => positions?.includes(pos));
  const onUpperArch = OrderedUpperPositions.some((pos) => positions?.includes(pos));

  if (onUpperArch && !onLowerArch) {
    return DentalArchEnumWithBoth.UPPER;
  }

  if (!onUpperArch && onLowerArch) {
    return DentalArchEnumWithBoth.LOWER;
  }

  return DentalArchEnumWithBoth.BOTH;
};

/**
 * Retrieves the mouth information from a given order.
 *
 * @param {Order} order - The order object containing the mouth information.
 * @returns {Object} - An object representing the mouth information.
 */
export const getMouth = (
  order: Order
): { [key: string]: Array<Array<PositionKey>> | Array<PositionKey> } => {
  const mouth: { [key: string]: Array<Array<PositionKey>> | Array<PositionKey> } = {};
  if (order?.items?.length) {
    order.items.forEach((item) => {
      item.itemComponents?.forEach((component) => {
        if (mouth[component.componentType]) {
          const allPositions = mouth[component.componentType] as Array<Array<PositionKey>>;
          allPositions.push(component.teethPositions || []);
          mouth[component.componentType] = allPositions;
        } else {
          mouth[component.componentType] = [component.teethPositions || []];
        }
        if (component.stumpPositions?.length) {
          if (mouth[ToolEnum.STUMP]) {
            const allPositions = mouth[ToolEnum.STUMP] as Array<Array<PositionKey>>;
            allPositions.push(component.stumpPositions || []);
            mouth[ToolEnum.STUMP] = allPositions;
          } else {
            mouth[ToolEnum.STUMP] = [component.stumpPositions || []];
          }
        }
      });
    });
    if (order?.patient?.diagnostic) {
      mouth[ToolEnum.MISSING] = order.patient.diagnostic.missingTeeth || [];
      mouth[ToolEnum.EXTRACT] = order.patient.diagnostic.teethToBeExtracted || [];
    }
    return mouth;
  }
};

/**
 * Compute teethmap line colors from items
 * @param orderItems
 */
export const computeLineColors = (
  orderItems?: Array<OrderItemLight | OrderItem>
): { [key in FamilyColorEnum]?: PositionKey[] } => {
  const lines: { [key in FamilyColorEnum]?: PositionKey[] } = {};

  orderItems?.reduce((acc, item) => {
    const family = getFamilyColor(item.product.family);
    acc[family] = Array.from(new Set([...(lines[family] || []), ...getAllItemPositions(item)]));
    return acc;
  }, lines);

  return lines;
};

/**
 * Find specific component in item & computes shade for teeth positions
 * @param orderItems
 * @param componentType
 */
export const computeComponentShades = (
  orderItems: Array<OrderItemLight | OrderItem>,
  componentType: ComponentType
) => {
  const shades: { [key in ToothShadeEnum]?: PositionKey[] } = {};

  orderItems?.reduce((acc, item) => {
    const component = item.itemComponents?.find(
      (component) => component.componentType === componentType
    );
    if (component) {
      acc[component.shade.code.toLowerCase() as ToothShadeEnum] = Array.from(
        new Set([
          ...(acc[component.shade.code.toLowerCase() as ToothShadeEnum] || []),
          ...(component.teethPositions || [])
        ])
      );
    }

    return acc;
  }, shades);

  return shades;
};

/**
 * Separate an array of positions into ordered ranges of consecutive positions
 * Each range will be ordered in position display order on teethmap
 * Ex :
 * - positions = [47, 46, 32, 45, 44, 41, 33, 43]
 * - orderedRanges = [[47, 46, 45, 44, 43], [41], [32, 33]]
 *
 * @param positions
 * @param dentalArch
 */
const getOrderedRangesFromPositions = (
  positions: PositionKey[],
  dentalArch: DentalArchEnumWithBoth
): PositionKey[][] => {
  const ranges: PositionKey[][] = [];
  const positionsToBrowse = [DentalArchEnumWithBoth.BOTH, DentalArchEnumWithBoth.UPPER].includes(
    dentalArch
  )
    ? OrderedUpperPositions
    : OrderedLowerPositions;
  let newRange: PositionKey[] = [];

  positionsToBrowse.forEach((position: PositionKey) => {
    if (positions.includes(position)) {
      // If the position is found, add it to the range
      newRange.push(position);
    } else if (newRange.length) {
      // If it's not found and a range has been created, add range to ranges
      ranges.push(newRange);
      newRange = [];
    }
  });

  // If there is an existing range that hasn't been added to ranges yet, do it
  // It happens if the last position was found and added to range
  if (newRange.length) {
    ranges.push(newRange);
  }

  return ranges;
};

/*
 * Returns the zone link prop the map needs to display a stellite (for example).
 *
 * @param {order} Order - The order.
 * @returns {ZoneLinkProp[]} The array of ZoneLinkProp matching the order.
 */
export const getZoneLinkProps = (order: Order): TeethmapZoneLinkProps[] => {
  const zoneLinkProps: TeethmapZoneLinkProps[] = [];
  order.items?.forEach((item) => {
    if (item.product.teethMode === TeethMode.MULTI_RANGE) {
      const toothComponent = getComponentInItemByType(item, ComponentType.TOOTH);
      const itemDentalArch = getItemDentalArch(item);
      const ranges = getOrderedRangesFromPositions(toothComponent.teethPositions, itemDentalArch);

      // No need to draw links if there is only one range
      if (ranges.length > 1) {
        let start: PositionKey | undefined;
        let end: PositionKey | undefined;
        ranges.forEach((range, index) => {
          // End the current link :
          // If this is NOT the first range
          // The end position of the link is always the first position of the range
          if (index > 0) {
            end = range[0];
            zoneLinkProps.push({
              startPosition: start,
              endPosition: end,
              color: ColorPropsEnum.GREY // TODO handle color according to Definitive/Provisional product
            });
            start = end = undefined;
          }

          // Start a new link:
          // If this is not the last range
          // The start position of the link is always the last position of the range
          if (index < ranges.length) {
            start = range[range.length - 1];
          }
        });
      }
    }
  });
  return zoneLinkProps;
};

/*
 * Return an object containing the parts of the given orderNumber, before and after the last dash.
 * It is useful to isolate the last part since it is what will mainly be used to identify an order.
 *
 * @param {orderNumber} string - The order number to split.
 * @returns {{ beforeLastPart: string, lastPart: string }} An object containing the parts of the order.
 */
export const splitOrderNumber = (
  orderNumber: string
): { beforeLastPart: string; lastPart: string } => {
  const lastIndex = orderNumber.lastIndexOf('-');

  if (lastIndex === -1) {
    return {
      beforeLastPart: orderNumber,
      lastPart: ''
    };
  }

  return {
    beforeLastPart: orderNumber.substring(0, lastIndex) + '-',
    lastPart: orderNumber.substring(lastIndex + 1)
  };
};

/*
 * Return a formatted and localized version of the date passed, if one.
 * If no valid format is passed, it formats as YYYY-MM-DD (en) or DD/MM/YYYY (fr).
 * If no date is passed, it returns the current date.
 *
 * @param {format} string - The format needed for the date.
 * @param {date} Date - The date to format.
 * @returns {string} The formatted date as a string.
 */
export const getFormattedLocalizedMomentDate = (format: string, date?: Date): string => {
  const validDateFormats = ['full', 'middle', 'small', 'withLitteralMonth'];
  const momentDate = date ? moment(date) : moment();
  let localizedFormat = i18next.t(`date.small`, { ns: 'common' });

  if (validDateFormats.includes(format)) {
    localizedFormat = i18next.t(`date.${format}`, { ns: 'common' });
  }

  return momentDate.format(localizedFormat);
};

/**
 *
 * @param {DentalArchEnum} arch
 * @returns {boolean}
 */
export const isUpperArch = (arch: DentalArchEnum): boolean => {
  return arch === DentalArchEnum.UPPER;
};

/**
 * Get family color depends on the family product
 * @param {Family} family
 * @returns {FamilyColorEnum}
 */
export const getFamilyColor = (family: Family): FamilyColorEnum => {
  switch (family) {
    case Family.FIXED:
      return FamilyColorEnum.FAMILY_FIXED;
    case Family.GUARDS:
      return FamilyColorEnum.FAMILY_GUARDS;
    case Family.REMOV:
      return FamilyColorEnum.FAMILY_REMOV;
    case Family.IMPLANT:
      return FamilyColorEnum.FAMILY_IMPLANT;
    case Family.OCCLUSION_RIMS:
      return FamilyColorEnum.FAMILY_OCR;
  }
};

/**
 * Compute first position displayed on teeth map
 * @param positions
 */
const computeFirstDisplayPosition = (positions: PositionKey[]): number => {
  const dentalArch = getDentalArchFromPositions(positions);
  let firstDisplayPosition;

  switch (dentalArch) {
    case DentalArchEnumWithBoth.UPPER:
    case DentalArchEnumWithBoth.BOTH:
      // Set a default position just in case
      firstDisplayPosition = 18;

      // Find the correct one
      OrderedUpperPositions.some((position) => {
        if (positions.includes(position)) {
          firstDisplayPosition = position;
          return true;
        } else {
          return false;
        }
      });
      break;
    case DentalArchEnumWithBoth.LOWER:
      // Set a default position just in case
      firstDisplayPosition = 48;

      // Find the correct one
      OrderedLowerPositions.some((position) => {
        if (positions.includes(position)) {
          firstDisplayPosition = position;
          return true;
        } else {
          return false;
        }
      });
      break;
  }

  return firstDisplayPosition;
};

/**
 * Return every busy position in all item components
 * @param item
 */
const getAllItemPositions = (item: OrderItem | OrderItemLight): PositionKey[] => {
  return (item.itemComponents || [])
    .map((itemComponent) => [
      ...(itemComponent.injectionPositions || []),
      ...(itemComponent.stumpPositions || []),
      ...(itemComponent.teethPositions || [])
    ])
    .flat();
};

/**
 * Compute teethmap bubble props for an item
 * @param item
 * @param allowBubbleClick
 */
export const computeTeethMapBubbleForItem = (
  item: OrderItem,
  allowBubbleClick = true
): { [key: string]: TeethmapBubbleFeatureProps } => {
  const allItemPositions = getAllItemPositions(item);
  const bubblePosition = computeFirstDisplayPosition(allItemPositions);

  const bubble: TeethmapBubbleFeatureProps = {
    backgroundColor: ColorPropsEnum.PURPLE_MEDIUM,
    color: item.product?.family
      ? (`family-${item.product.family.toLowerCase()}` as ColorPropsEnum)
      : ColorPropsEnum.DEFAULT,
    isClickable: true,
    size: 'small',
    tooltip: {
      children: <OrderItemTooltip item={item} />,
      isClickable: allowBubbleClick,
      position: 'right'
    },
    type: 'product',
    isDashedBorder: isCategoryProvisional(item.product.category),
    url: item.product?.imageUrl ?? defaultProductIconUrl
  };

  return { [bubblePosition.toString()]: bubble };
};

import i18next from 'i18next';
import { MenuEntry } from '../../models/datagrid';
import { OrderFile } from '../../models/order';

export const deleteFileAction = (
  file: OrderFile,
  callback: (file: OrderFile) => void
): MenuEntry => {
  return {
    id: 'delete',
    disabled: false,
    label: i18next.t('action.delete', { ns: 'common' }),
    type: 'button',
    onClick: () => {
      callback(file);
    }
  };
};

export const downloadFileAction = (
  file: OrderFile,
  callback: (file: OrderFile) => void
): MenuEntry => {
  return {
    id: 'download',
    disabled: false,
    label: i18next.t('actions.download', { ns: 'fileManager' }),
    type: 'button',
    onClick: () => {
      callback(file);
    }
  };
};

import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GingivaShadeEnum, MaterialEnum } from '../../enum/component';
import { ProductCustomizationReducer } from '../../models/product-customization';
import { ProductShadePayload } from '../../models/map';

const initialProductCustomization: ProductCustomizationReducer = {
  teethShade: undefined,
  inlayShade: undefined,
  onlayShade: undefined,
  overlayShade: undefined,
  gingivaShade: undefined,
  frameMaterial: undefined
};

export const productCustomizationSlice = createSlice({
  name: 'productCustomization',
  initialState: initialProductCustomization,
  reducers: {
    setShade: (state, action: PayloadAction<ProductShadePayload>) => {
      state[action.payload.target] = action.payload.shade;
    },
    setGingivaShade: (state, action: PayloadAction<GingivaShadeEnum>) => {
      state.gingivaShade = action.payload;
    },
    setFrameMaterial: (state, action: PayloadAction<MaterialEnum>) => {
      state.frameMaterial = action.payload;
    },
    resetProductCustomization: () => initialProductCustomization
  },
  extraReducers: (builder: ActionReducerMapBuilder<ProductCustomizationReducer>) => {
    builder.addCase('RESET_ALL', () => {
      return { ...initialProductCustomization };
    });
  }
});

export const productCustomizationActions = productCustomizationSlice.actions;

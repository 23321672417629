import React from 'react';
import styles from './production-page.module.scss';
import { Tabs } from '@anatoscope/circlestorybook';
import { useTranslation } from 'react-i18next';
import { TabsType } from '../../../models/navigation';
import { useGetOrdersIndicatorsQuery } from '../../../services/orders-api.services';
import { WorkflowStepEnum } from '../../../enum/workflow-step';

const ProductionPage = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();
  const { data: ordersIndicators } = useGetOrdersIndicatorsQuery('');
  const tabs: TabsType[] = [
    {
      label: t(`order.steps.${WorkflowStepEnum.VALIDATION}`, { ns: 'common' }),
      to: '/production/validation',
      counter: ordersIndicators?.ordersToValidate || null
    },
    {
      label: t(`order.steps.${WorkflowStepEnum.MODELING}`, { ns: 'common' }),
      to: '/production/design',
      counter: ordersIndicators?.ordersToDesign || null
    },
    {
      label: t(`order.steps.${WorkflowStepEnum.MANUFACTURING}`, { ns: 'common' }),
      to: '/production/manufacturing',
      counter: ordersIndicators?.ordersToManufacture || null
    },
    {
      label: t(`order.steps.${WorkflowStepEnum.ASSEMBLY}`, { ns: 'common' }),
      to: '/production/assembly',
      counter: ordersIndicators?.ordersToAssemble || null,
      disabled: true
    },
    {
      label: t(`order.steps.${WorkflowStepEnum.CONTROL}`, { ns: 'common' }),
      to: '/production/control',
      disabled: false,
      counter: ordersIndicators?.ordersToControl || null
    },
    {
      label: t(`order.steps.${WorkflowStepEnum.DELIVERY}`, { ns: 'common' }),
      to: '/production/shipping',
      counter: ordersIndicators?.ordersToShip || null
    }
  ];

  return (
    <>
      <div className={styles['production-page__tab']}>
        <Tabs tabs={tabs} id={'production'}></Tabs>
      </div>
      <div className={styles['production-page__content']}>{children}</div>
    </>
  );
};

export default ProductionPage;

import { NavigateFunction } from 'react-router';
import { Location } from '@remix-run/router';

export interface ActiveTab {
  page: string;
  activeTabId: number;
}

interface TabsType {
  label: string;
  disabled?: boolean;
  to: string | undefined;
  counter?: number;
}

interface WizardStep {
  id: number;
  title: string;
}

interface History {
  navigate: NavigateFunction;
  location: Location;
}

// custom history object to allow navigation outside react components
const history: History = {
  navigate: null,
  location: null
};

export { TabsType, history, WizardStep };

import React from 'react';
import { Kpi, Skeleton } from '@anatoscope/circlestorybook';
import styles from './kpi-list.module.scss';
import { useGetOrdersIndicatorsQuery } from '../../services/orders-api.services';
import { KPIData } from '../../models/order';
import { ColorPropsEnum } from '../../enum/color.enum';

interface Props {
  kpiData: KPIData[];
}

const KPIList = ({ kpiData }: Props) => {
  const { isLoading } = useGetOrdersIndicatorsQuery('');

  return (
    <div className={styles['kpi-list']}>
      {isLoading
        ? kpiData.map((circle: KPIData) => {
            return (
              <div key={circle.index} className={styles['kpi-list--wrapper']}>
                <Skeleton height="4rem" width="8rem" />
              </div>
            );
          })
        : kpiData.map((circle: KPIData) => {
            let variantKpi = '';
            switch (true) {
              case circle.kpi < circle.limit1:
                variantKpi = ColorPropsEnum.SUCCESS;
                break;
              case circle.limit1 <= circle.kpi && circle.kpi < circle.limit2:
                variantKpi = ColorPropsEnum.WARNING;
                break;
              case circle.kpi >= circle.limit2:
                variantKpi = ColorPropsEnum.DANGER;
                break;
              default:
                variantKpi = ColorPropsEnum.BLACK;
            }

            return (
              <div key={circle.index} className={styles['kpi-list--wrapper']}>
                <Kpi
                  className={styles['kpi-list--content']}
                  kpi={circle.kpi}
                  invertcolors={circle.invertColors}
                  label={circle.label}
                  link={circle?.to}
                  variant={variantKpi}
                  isCompact={true}
                  height="2rem"
                />
              </div>
            );
          })}
    </div>
  );
};

export default KPIList;

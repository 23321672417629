import React from 'react';
import { useTranslation } from 'react-i18next';
import { Chips } from '@anatoscope/circlestorybook';
import styles from './print-product-list.module.scss';
import { ColorPropsEnum } from '../../enum/color.enum';
import { ProductCategoryWithMisc, ProductLine, SortedItemsForPrint } from './print-order';
import { Family } from '../../enum/product.enum';

interface PrintProductListProps {
  sortedItems: SortedItemsForPrint;
}

const PrintProductList = ({ sortedItems }: PrintProductListProps) => {
  const { t } = useTranslation(['catalog']);

  return (
    <>
      {Object.keys(sortedItems).map((family) => {
        const categories = Object.keys(sortedItems[family as Family]).map((category) => {
          return (
            <div key={`family_content_${family}_${category}`}>
              {sortedItems[family as Family][category as ProductCategoryWithMisc].displayChip && (
                <div key={category} className={styles['print-product-list__line']}>
                  <Chips
                    firstLabel={t(`categories.${category}`)}
                    color={ColorPropsEnum.BLACK}
                    size={'s'}
                  />
                </div>
              )}
              {sortedItems[family as Family][category as ProductCategoryWithMisc].items.map(
                (item: ProductLine) => {
                  return (
                    <div key={item.label} className={styles['print-product-list__line']}>
                      {item.count > 1
                        ? item.label.replace('{{count}}', item.count.toString())
                        : item.label.replace('x{{count}} - ', '')}
                    </div>
                  );
                }
              )}
            </div>
          );
        });
        return (
          <div key={`family_container_${family}`}>
            <div key={family} className={styles['print-product-list__title--first']}>
              {t(`families.${family}`)}
            </div>
            {categories}
          </div>
        );
      })}
    </>
  );
};

export default PrintProductList;

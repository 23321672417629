import React, { useEffect } from 'react';
import styles from './ship-order-form.module.scss';
import { AttentionBox, Box, Button, Skeleton, Text, TextField } from '@anatoscope/circlestorybook';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { attentionBoxErrorSelector } from '../../../../../../store/feedback/feedback.selector';
import { capitalizeFirstLetter, getMessageError } from '../../../../../../utils/utils';
import { useGetOneUserQuery } from '../../../../../../services/users-api.services';
import useForm from '../../../../../../utils/useForm';
import { feedbackActions } from '../../../../../../store/feedback/feedback.reducer';
import { usePostShippingMutation } from '../../../../../../services/manufacturing-orders-api.services';
import { Country } from '../../../../../../enum/user';
import { ShippingCreation } from '../../../../../../models/manufacturing-order';
import { ColorPropsEnum } from '../../../../../../enum/color.enum';
import { ToastType } from '../../../../../../enum/feedback';

type props = {
  onSubmitSetShippingCallback: () => void;
  dentistEmail: string;
  orderNumber: string;
};

const ShipOrderForm = ({ onSubmitSetShippingCallback, dentistEmail, orderNumber }: props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const attentionBoxError = useAppSelector(attentionBoxErrorSelector);
  const {
    data: dentist,
    isLoading: isGetDentistLoading,
    error: getDentistError
  } = useGetOneUserQuery(dentistEmail);

  const [
    postShipping,
    { isLoading: isLoadingPostShipping, error: createShippingError, reset, isSuccess }
  ] = usePostShippingMutation();

  const mandatoryReference = i18next.t('shipOrderModal.mandatoryReference', { ns: 'production' });

  const onSubmit = async () => {
    // TODO: should not be posting a shipping object.
    // shipping object should be created when order is validated and patched here with new data
    await postShipping({
      orderNumber: orderNumber,
      shipping: {
        shippingDate: new Date(),
        // TODO: estimatedDeliveryDate should be set on order validation
        // waiting for order validation
        estimatedDeliveryDate: new Date(),
        deliveryLink: values.shippingReference as string,
        address: dentist.clinic?.address?.address,
        additionalAddress: dentist.clinic?.address?.additionalAddress,
        zipCode: dentist.clinic?.address?.zipCode,
        city: dentist.clinic?.address?.city,
        country: dentist.clinic?.address?.country,
        region: dentist.clinic?.address?.region
      } as ShippingCreation
    });
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        feedbackActions.setToast({
          message: t('shipOrderModal.success', { ns: 'production' }),
          type: ToastType.SUCCESS
        })
      );
      onSubmitSetShippingCallback();
    }
  }, [isSuccess]);

  const { values, errors, handleSubmit, handleChange } = useForm(
    {
      shippingReference: null
    },
    onSubmit
  );

  return (
    <>
      {createShippingError && attentionBoxError && (
        <AttentionBox
          mode={ColorPropsEnum.DANGER}
          text={getMessageError(createShippingError)}
          className={styles['ship-order-form__attention-box']}
          onClose={() => reset()}
        />
      )}
      <Box className={styles['ship-order-form__box']}>
        {isGetDentistLoading ? (
          <>
            <Skeleton type="text" className="skeleton" />
            <Skeleton type="text" className="skeleton" />
            <Skeleton type="text" className="skeleton" />
            <Skeleton type="text" />
          </>
        ) : (
          !getDentistError &&
          Object.keys(dentist?.clinic || {}).length > 0 && (
            <>
              <header className={styles['ship-order-form__header']}>
                <Text
                  className={styles['ship-order-form__header__title']}
                  size="s"
                  bold={true}
                  label={t('shipOrderModal.shippingAddress', { ns: 'production' })}></Text>
              </header>
              <section className={styles['ship-order-form__section']}>
                <div className={styles['ship-order-form__section__address']}>
                  <Text label={capitalizeFirstLetter(dentist.clinic.name)} size="m"></Text>
                  <Text label={dentist.clinic?.address?.address}></Text>
                  {dentist.clinic?.address?.additionalAddress && (
                    <Text label={dentist.clinic.address.additionalAddress}></Text>
                  )}

                  <Text
                    label={
                      dentist.clinic?.address?.zipCode +
                      ' ' +
                      dentist.clinic?.address?.city +
                      ' ' +
                      (dentist.clinic?.address?.region ? dentist.clinic?.address?.region : '')
                    }></Text>
                  <Text
                    label={
                      Object.values(Country).includes(dentist.clinic?.address.country as Country)
                        ? t(`countries.${dentist.clinic.address.country.toLowerCase()}`, {
                            ns: 'common'
                          })
                        : ''
                    }></Text>
                </div>
              </section>
            </>
          )
        )}
      </Box>
      <Box color={ColorPropsEnum.WHITE} className={styles['ship-order-form__form']}>
        <form onSubmit={handleSubmit}>
          <TextField
            id={'shippingReference'}
            name={'shippingReference'}
            label={t('shipOrderModal.form.label', { ns: 'production' })}
            size="m"
            placeholder={t('shipOrderModal.form.placeholder', { ns: 'production' })}
            onChange={handleChange}
            helperText={errors.shippingReference && mandatoryReference}
            variant={errors?.shippingReference ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
          />

          <div className="form__submit-button form__submit-button--right">
            <Button
              label={t('shipOrderModal.action', { ns: 'production' })}
              isLoading={isLoadingPostShipping}
              type="submit"
            />
          </div>
        </form>
      </Box>
    </>
  );
};

export default ShipOrderForm;

import React, { useEffect, useState } from 'react';
import { Box, Button, Dropdown, Text } from '@anatoscope/circlestorybook';
import { Establishment } from '../../../../../models/establishment';
import { DentistInfos } from '../../../../../models/user';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { dentistSelector } from '../../../../../store/users/users.selectors';
import styles from '../dentist-detail-page.module.scss';
import { useGetAllClinicsQuery } from '../../../../../services/establishments-api.services';
import { usersActions } from '../../../../../store/users/users.reducer';
import { useTranslation } from 'react-i18next';
import { clinicListForDropdownSelector } from '../../../../../store/establishment/establishment.selectors';
import { ColorPropsEnum } from '../../../../../enum/color.enum';

type Props = {
  establishment: Establishment;
  saveCallback: (user: Partial<DentistInfos>, email: string) => void;
  closeSideNavCallback: (opened: boolean) => void;
};

const EstablishmentForm = ({ establishment, saveCallback, closeSideNavCallback }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['dentist']);
  const user = useAppSelector(dentistSelector);
  const clinicListForDropdown = useAppSelector(clinicListForDropdownSelector);

  const { data: clinicList, isLoading: isLoadingGetAllClinics } = useGetAllClinicsQuery();

  const [name, setName] = useState<string>(establishment?.name || '');
  const [address, setAddress] = useState<string>(establishment?.address?.address || '');
  const [additionalAddress, setAdditionalAddress] = useState<string>(
    establishment?.address?.additionalAddress || ''
  );
  const [zipCode, setZipCode] = useState<string>(establishment?.address?.zipCode || '');
  const [city, setCity] = useState<string>(establishment?.address?.city || '');
  const [country, setCountry] = useState<string>(establishment?.address?.country || '');
  const [region, setRegion] = useState<string>(establishment?.address?.region || '');
  const [selectedClinicId, setSelectedClinicId] = useState(undefined);
  const [isShowClinicMandatoryMessage, setIsShowClinicMandatoryMessage] = useState(false);

  useEffect(() => {
    if (user.clinic && clinicList?.data?.length) {
      setSelectedClinicId(establishment.id);
    }
  }, [clinicList]);

  const onSelectClinic = (id: number): void => {
    setSelectedClinicId(id || null);
    updateEstablishment(clinicList.data.find((ets) => ets.id === id));
  };

  const updateEstablishment = (establishment: Establishment): void => {
    setName(establishment?.name || '');
    setAddress(establishment?.address?.address || '');
    setAdditionalAddress(establishment?.address?.additionalAddress || '');
    setZipCode(establishment?.address?.zipCode || '');
    setCity(establishment?.address?.city || '');
    setRegion(establishment?.address?.region || '');
    setAddress(establishment?.address?.address || '');
    setCountry(establishment?.address?.country || undefined);
  };

  const showMandatoryMessages = () => {
    setIsShowClinicMandatoryMessage(!selectedClinicId);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    if (!selectedClinicId) {
      showMandatoryMessages();
      return;
    }

    establishment = {
      ...establishment,
      id: selectedClinicId,
      name: name,
      address: address
        ? {
            address: address || undefined,
            additionalAddress: additionalAddress || undefined,
            zipCode: zipCode || undefined,
            city: city || undefined,
            country: country || undefined,
            region: region || undefined
          }
        : undefined
    };

    const userData: DentistInfos = {
      ...user,
      clinic: establishment
    };
    dispatch(usersActions.setDentist(userData));

    const paramsToUpdate: Partial<DentistInfos> = {
      clinic: {
        id: establishment.id,
        name: undefined,
        address: undefined
      }
    };
    saveCallback(paramsToUpdate, user?.email);
    closeSideNavCallback(false);
  };

  return (
    <Box color={ColorPropsEnum.WHITE}>
      <form
        onSubmit={handleSubmit}
        className={styles['dentist-detail-page__content__sidebar__form']}>
        <Dropdown
          label={t('createDentist.establishment.clinic')}
          data={isLoadingGetAllClinics ? [] : [clinicListForDropdown]}
          value={selectedClinicId}
          placeholder={t('createDentist.choose')}
          onChange={(newValue: number) => onSelectClinic(+newValue)}
          helperText={
            isShowClinicMandatoryMessage
              ? t('createDentist.establishment.clinicMandatory')
              : undefined
          }
          variant={isShowClinicMandatoryMessage ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
          className={styles['establishment-form__dropdown-address']}
        />
        {name && (
          <div>
            <Text label={name}></Text>
            {address && (
              <>
                <Text label={address}></Text>
                {additionalAddress && <Text label={additionalAddress}></Text>}

                <Text label={zipCode + ' ' + city + ' ' + (region || '')}></Text>
                {country && (
                  <Text label={t(`countries.${country.toLowerCase()}`, { ns: 'common' })} />
                )}
              </>
            )}
          </div>
        )}
        <div className="form__submit-button form__submit-button--right">
          <Button label={t('action.update', { ns: 'common' })} type="submit" />
        </div>
      </form>
    </Box>
  );
};

export default EstablishmentForm;

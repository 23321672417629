import { api } from './api';
import { FullProduct, ProductListResponse } from '../models/product';
import {
  PatchManufacturingComponentToothBody,
  PatchManufacturingStepBody
} from '../enum/manufacturing-step.enum';

export const productsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    setComponentManufactured: builder.mutation({
      query: ({ productId, componentId }) => ({
        url: `/manufacturing/products/${productId}/components/${componentId}/manufactured`,
        method: 'POST'
      })
    }),
    getEstimatedDeliveryDate: builder.mutation({
      query: (productIds) => ({
        url: '/products/estimateDeliveryDate',
        method: 'POST',
        body: { productIds }
      })
    }),
    patchManufacturingStepComponent: builder.mutation({
      query: ({ productId, componentId, manufacturingStep }: PatchManufacturingStepBody) => ({
        url: `/manufacturing/products/${productId}/components/${componentId}`,
        method: 'PATCH',
        body: { manufacturingStep }
      })
    }),
    patchManufacturingComponentProductionType: builder.mutation({
      query: ({ productId, componentId, tooth }: PatchManufacturingComponentToothBody) => ({
        url: `/manufacturing/products/${productId}/components/${componentId}`,
        method: 'PATCH',
        body: { tooth }
      })
    }),
    getAllProducts: builder.query<ProductListResponse, void>({
      query: () => ({
        url: '/products?limit=0&filter.deletedAt=$null',
        method: 'GET'
      })
    }),
    getOneProduct: builder.query<FullProduct, number>({
      query: (productId) => ({
        url: `/products/${productId}`,
        method: 'GET'
      })
    })
  })
});

export const {
  useSetComponentManufacturedMutation,
  usePatchManufacturingStepComponentMutation,
  usePatchManufacturingComponentProductionTypeMutation,
  useGetAllProductsQuery,
  useLazyGetOneProductQuery,
  useGetEstimatedDeliveryDateMutation
} = productsApi;

import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EstablishmentPayload } from '../../enum/establishment';
import { DatagridsSettingsReducer, FilterReducer, SortDirection } from '../../models/datagrid';
import { TypeSingleSortInfo } from '@inovua/reactdatagrid-community/types/TypeSortInfo';
import { ManufacturingProductionTypeEnum } from '../../enum/component';

const initialSettings: DatagridsSettingsReducer = {
  users: {
    filters: [
      { name: 'deletedAt', operator: 'inlist', type: 'select', value: 'active' },
      { name: 'email', operator: 'startsWith', type: 'string', value: '' },
      { name: 'firstName', operator: 'startsWith', type: 'string', value: '' },
      { name: 'lastName', operator: 'startsWith', type: 'string', value: '' },
      { name: EstablishmentPayload.CLINIC, operator: 'startsWith', type: 'string', value: '' },
      { name: 'role', operator: 'inlist', type: 'select', value: null }
    ],
    sort: {
      dir: SortDirection.ASC_AS_NB,
      name: 'email'
    }
  },
  supervision: {
    filters: [
      { name: 'orderNumber', operator: 'contains', type: 'string', value: '' },
      { name: 'dentistName', operator: 'startsWith', type: 'string', value: '' },
      { name: 'products', operator: 'contains', type: 'string', value: '' },
      { name: 'assigneeName', operator: 'startsWith', type: 'string', value: '' },
      { name: 'validationDate', operator: 'eq', type: 'date', value: '' },
      { name: 'currentStep', operator: 'inlist', type: 'select', value: null },
      { name: 'families', operator: 'inlist', type: 'select', value: null },
      { name: 'limitShippingDate', operator: 'eq', type: 'date', value: '' },
      { name: 'expectedDate', operator: 'eq', type: 'date', value: '' }
    ],
    sort: [
      {
        dir: SortDirection.DESC_AS_NB,
        name: 'submissionDate'
      }
    ]
  },
  validation: {
    filters: [
      { name: 'orderNumber', operator: 'contains', type: 'string', value: '' },
      { name: 'dentistName', operator: 'startsWith', type: 'string', value: '' },
      { name: 'assigneeName', operator: 'startsWith', type: 'string', value: '' },
      { name: 'families', operator: 'inlist', type: 'select', value: null }
    ],
    sort: {
      name: 'limitShippingDate',
      dir: SortDirection.ASC_AS_NB
    }
  },
  design: {
    filters: [
      { name: 'orderNumber', operator: 'contains', type: 'string', value: '' },
      { name: 'families', operator: 'inlist', type: 'select', value: null },
      { name: 'assigneeName', operator: 'startsWith', type: 'string', value: '' },
      { name: 'dentistName', operator: 'startsWith', type: 'string', value: '' },
      { name: 'circleCadVersion', operator: 'inlist', type: 'select', value: null }
    ],
    sort: {
      name: 'limitShippingDate',
      dir: SortDirection.ASC_AS_NB
    }
  },
  manufacturing: {
    filters: [
      { name: 'orderNumber', operator: 'contains', type: 'string', value: '' },
      { name: 'componentType', operator: 'inlist', type: 'select', value: null },
      { name: 'assigneeName', operator: 'startsWith', type: 'string', value: '' },
      { name: 'family', operator: 'inlist', type: 'select', value: null },
      { name: 'manufacturingDate', operator: 'eq', type: 'date', value: '' },
      { name: 'shade', operator: 'inlist', type: 'select', value: null },
      { name: 'material', operator: 'inlist', type: 'select', value: null },
      { name: 'tooth', operator: 'inlist', type: 'select', value: null }
    ],
    sort: {
      name: 'limitShippingDate',
      dir: SortDirection.ASC_AS_NB
    }
  },
  control: {
    filters: [
      { name: 'orderNumber', operator: 'contains', type: 'string', value: '' },
      { name: 'control', operator: 'eq', type: 'date', value: '' },
      { name: 'families', operator: 'inlist', type: 'select', value: null }
    ],
    sort: {
      name: 'limitShippingDate',
      dir: SortDirection.ASC_AS_NB
    }
  },
  shipping: {
    filters: [
      { name: 'orderNumber', operator: 'contains', type: 'string', value: '' },
      { name: 'shipping', operator: 'eq', type: 'date', value: '' },
      { name: 'dentistName', operator: 'startsWith', type: 'string', value: '' },
      { name: 'families', operator: 'inlist', type: 'select', value: null },
      { name: 'limitShippingDate', operator: 'eq', type: 'date', value: '' },
      { name: 'expectedDate', operator: 'eq', type: 'date', value: '' }
    ],
    sort: [
      {
        name: 'shipping',
        dir: SortDirection.ASC_AS_NB
      },
      {
        name: 'limitShippingDate',
        dir: SortDirection.ASC_AS_NB
      }
    ]
  }
};

const quickFilters: { manufacturing: { machining: FilterReducer[]; print: FilterReducer[] } } = {
  manufacturing: {
    machining: [
      { name: 'orderNumber', operator: 'contains', type: 'string', value: '' },
      { name: 'componentType', operator: 'inlist', type: 'select', value: null },
      { name: 'family', operator: 'inlist', type: 'select', value: null },
      { name: 'manufacturingDate', operator: 'eq', type: 'date', value: null },
      { name: 'shade', operator: 'inlist', type: 'select', value: null },
      { name: 'material', operator: 'inlist', type: 'select', value: null },
      {
        name: 'tooth',
        operator: 'inlist',
        type: 'select',
        value: [ManufacturingProductionTypeEnum.MACHINING]
      }
    ],
    print: [
      { name: 'orderNumber', operator: 'contains', type: 'string', value: '' },
      { name: 'componentType', operator: 'inlist', type: 'select', value: null },
      { name: 'family', operator: 'inlist', type: 'select', value: null },
      { name: 'manufacturingDate', operator: 'eq', type: 'date', value: null },
      { name: 'shade', operator: 'inlist', type: 'select', value: null },
      { name: 'material', operator: 'inlist', type: 'select', value: null },
      {
        name: 'tooth',
        operator: 'inlist',
        type: 'select',
        value: [ManufacturingProductionTypeEnum.PRINT]
      }
    ]
  }
};

interface DatagridSettingSetter {
  datagrid: string;
  filters?: FilterReducer[];
  sort?: TypeSingleSortInfo | TypeSingleSortInfo[];
}

const datagridsSettingsSlice = createSlice({
  name: 'dgSettings',
  initialState: initialSettings,
  reducers: {
    setDatagridFilters: (state, action: PayloadAction<DatagridSettingSetter>): void => {
      state[action.payload.datagrid as keyof DatagridsSettingsReducer].filters =
        action.payload.filters;
    },
    setDatagridSort: (
      state: DatagridsSettingsReducer,
      action: PayloadAction<DatagridSettingSetter>
    ): void => {
      state[action.payload.datagrid as keyof DatagridsSettingsReducer].sort = action.payload.sort;
    },
    resetDatagridFilters: (state, action: PayloadAction<DatagridSettingSetter>): void => {
      state[action.payload.datagrid as keyof DatagridsSettingsReducer].filters =
        initialSettings[action.payload.datagrid as keyof DatagridsSettingsReducer].filters;
      state[action.payload.datagrid as keyof DatagridsSettingsReducer].sort =
        initialSettings[action.payload.datagrid as keyof DatagridsSettingsReducer].sort;
    },
    setManufacturingDatagridQuickFilters: (state, action: PayloadAction<string>): void => {
      switch (action.payload) {
        case ManufacturingProductionTypeEnum.MACHINING:
          state.manufacturing = {
            ...state.manufacturing,
            filters: quickFilters.manufacturing.machining
          };
          break;
        case ManufacturingProductionTypeEnum.PRINT:
          state.manufacturing = {
            ...state.manufacturing,
            filters: quickFilters.manufacturing.print
          };
          break;
        default:
          break;
      }
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<DatagridsSettingsReducer>) => {
    builder.addCase('RESET_ALL', () => {
      return { ...initialSettings };
    });
  }
});

const datagridSettingsActions = datagridsSettingsSlice.actions;

export { datagridsSettingsSlice, datagridSettingsActions };

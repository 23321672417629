import React from 'react';
import { Skeleton } from '@anatoscope/circlestorybook';

interface SkeletonListProps {
  count: number;
  heightItem: string;
  widthItem: string;
  className: string;
}

const SkeletonList = ({ count, heightItem, widthItem, className }: SkeletonListProps) => {
  const skeleton = [];
  for (let i = 0; i < count; i++) {
    skeleton.push(
      <Skeleton key={i} height={heightItem} width={widthItem} className={className}></Skeleton>
    );
  }
  return <>{skeleton}</>;
};

export default SkeletonList;

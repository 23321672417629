// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,400;0,700;1,100;1,400;1,700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Kz2NUgO0yESOLnaG0_R5 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.PGaHjLde2JZ0Jib6ObCT {
  margin-top: 3rem;
}
.xSM0VMvlhnSdoqwG_Eqf {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100dvh;
  background: linear-gradient(180deg, #6200ee 0%, #2a0066 100%);
}
.UrZ6ADErnViy33eMbuJ1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4rem;
  height: 50%;
}
.CChtlvvnDiiPYrKMI1qQ {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 66.666667%;
  height: 25%;
  color: rgb(255, 255, 255);
  line-height: 1.5;
}
.I1Yo2xRAQPQVebxSa38i {
  background: linear-gradient(270.01deg, rgba(217, 217, 217, 0) 0.01%, rgba(217, 217, 217, 0.3) 50.52%, rgba(217, 217, 217, 0) 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem;
}
.QPnwA2S3OVfbQeQO8Rk4 {
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/pages/private/streaming-page/streaming-page.module.scss"],"names":[],"mappings":"AAGE;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,MAAA;AADJ;AAIE;EACE,gBAAA;AAFJ;AAKE;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,WAAA;EACA,cAAA;EACA,6DAAA;AAHJ;AAMI;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,iBAAA;EACA,kBAAA;EACA,gBAAA;EACA,WAAA;AAJN;AAQE;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,iBAAA;EACA,kBAAA;EACA,iBAAA;EACA,WAAA;EACA,yBAAA;EACA,gBAAA;AANJ;AASE;EACE,kIAAA;EAMA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AAZJ;AAeE;EACE,iBAAA;AAbJ","sourcesContent":["@use '@anatoscope/circlestorybook/dist/vars';\n\n.streaming-page {\n  &__container {\n    width: 100%;\n    height: 100%;\n    position: absolute;\n    top: 0;\n  }\n\n  &__spacer {\n    margin-top: 3rem;\n  }\n\n  &__wait-screen {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    width: 100%;\n    height: 100dvh;\n    background: linear-gradient(180deg, #6200ee 0%, #2a0066 100%);\n    // font-family: Montserrat;\n\n    &--logo-wrapper {\n      display: flex;\n      flex-direction: column;\n      justify-content: center;\n      align-items: center;\n      margin-left: auto;\n      margin-right: auto;\n      margin-top: 4rem;\n      height: 50%;\n    }\n  }\n\n  &__text-container {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    margin-left: auto;\n    margin-right: auto;\n    width: 66.666667%;\n    height: 25%;\n    color: rgb(255 255 255);\n    line-height: 1.5;\n  }\n\n  &__text-aligner {\n    background: linear-gradient(\n      270.01deg,\n      rgba(217, 217, 217, 0) 0.01%,\n      rgba(217, 217, 217, 0.3) 50.52%,\n      rgba(217, 217, 217, 0) 100%\n    );\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    padding: 3rem;\n  }\n\n  &__text-wrapper {\n    font-weight: bold;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"streaming-page__container": `Kz2NUgO0yESOLnaG0_R5`,
	"streaming-page__spacer": `PGaHjLde2JZ0Jib6ObCT`,
	"streaming-page__wait-screen": `xSM0VMvlhnSdoqwG_Eqf`,
	"streaming-page__wait-screen--logo-wrapper": `UrZ6ADErnViy33eMbuJ1`,
	"streaming-page__text-container": `CChtlvvnDiiPYrKMI1qQ`,
	"streaming-page__text-aligner": `I1Yo2xRAQPQVebxSa38i`,
	"streaming-page__text-wrapper": `QPnwA2S3OVfbQeQO8Rk4`
};
export default ___CSS_LOADER_EXPORT___;

import moment from 'moment';
import { Order, OrderForCreation, OrderForUpdate } from '../../../models/order';

type FinalisationFormInfo = {
  dentistEmail: string;
  expectedDate: string | Date;
  instructions: string;
};

export const getEditFormInitValues = (initialOrderInfo: Order): FinalisationFormInfo => {
  return {
    dentistEmail: initialOrderInfo?.dentistEmail || '',
    expectedDate: moment(initialOrderInfo?.expectedDate).format('YYYY-MM-DD') || '',
    instructions: initialOrderInfo?.instructions || ''
  };
};

export const getCreateFormInitValues = (
  orderToCreate: OrderForCreation | OrderForUpdate
): FinalisationFormInfo => {
  return {
    dentistEmail: orderToCreate.dentistEmail || '',
    expectedDate: orderToCreate.expectedDate || '',
    instructions: orderToCreate.instructions || ''
  };
};

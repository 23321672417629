import React, { useEffect, useState } from 'react';
import styles from './validate-order-form.module.scss';
import { AttentionBox, Box, Button, RadioList, Skeleton, Text } from '@anatoscope/circlestorybook';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { useTranslation } from 'react-i18next';
import { attentionBoxErrorSelector } from '../../../../store/feedback/feedback.selector';
import { getMessageError } from '../../../../utils/utils';
import useForm from '../../../../utils/useForm';
import { feedbackActions } from '../../../../store/feedback/feedback.reducer';
import {
  useGetOneOrderQuery,
  useValidateOrderMutation
} from '../../../../services/orders-api.services';
import { useGetCircleCadVersionsQuery } from '../../../../services/circle-cad-version-api.services';
import semver from 'semver/preload';
import { CircleCadVersion } from '../../../../models/circle-cad-version';
import { CircleCadVersionEnum } from '../../../../enum/circle-cad-version';
import { RadioListOptions } from '../../../../models/form';
import { useGetOneUserQuery } from '../../../../services/users-api.services';
import { connectedUserSelector } from '../../../../store/auth/auth.selectors';
import { UserInfo } from '../../../../models/user';
import { ToastType } from '../../../../enum/feedback';
import { ColorPropsEnum } from '../../../../enum/color.enum';
import {
  WorkflowModelingStepEnum,
  WorkflowPostModelingStepEnum,
  WorkflowStepEnum
} from '../../../../enum/workflow-step';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ValidateOrder } from '../../../../models/order';

type props = {
  onValidateOrderSubmit: (nextWorkflowStep?: WorkflowStepEnum) => void;
  orderNumber: string;
  isMultiFamilyOrder: boolean;
};

const ValidateOrderForm = ({ onValidateOrderSubmit, orderNumber, isMultiFamilyOrder }: props) => {
  const { t } = useTranslation(['orderDetail']);
  const [filteredCircleCadVersions, setFilteredCircleCadVersions] =
    useState<RadioListOptions[]>(undefined);
  // stable versions are accessible to all lab users
  const stableCircleCadVersions: CircleCadVersionEnum[] = [
    CircleCadVersionEnum.RC,
    CircleCadVersionEnum.PROD,
    CircleCadVersionEnum.FAILSAFE
  ];

  const dispatch = useAppDispatch();
  const {
    data: order,
    isLoading: isLoadingOrder,
    isSuccess: isSuccessOrder
  } = useGetOneOrderQuery(orderNumber);
  const connectedUser = useAppSelector(connectedUserSelector);
  const {
    data: user,
    isLoading: isLoadingUser,
    isSuccess: isSuccessUser
  } = useGetOneUserQuery(connectedUser.email);

  const {
    data: circleCadVersions,
    isLoading: isLoadingCircleCadVersions,
    isSuccess: isSuccessCircleCadVersions
  } = useGetCircleCadVersionsQuery('');
  const attentionBoxError = useAppSelector(attentionBoxErrorSelector);

  const [
    validateOrder,
    {
      isLoading: isLoadingValidateOrder,
      error: errorValidateOrder,
      reset,
      isSuccess: isSuccessValidateOrder
    }
  ] = useValidateOrderMutation();

  const onSubmit = async () => {
    const selectedVersion = circleCadVersions.find((v) => v.id == values.circleCadVersionId);

    const circleCadVersionData: Partial<ValidateOrder> = selectedVersion
      ? {
          circleCadVersionId: +values.circleCadVersionId,
          circleCadVersion: selectedVersion.version,
          circleCadVersionLabel: selectedVersion.label
        }
      : {};

    await validateOrder({
      orderNumber: orderNumber,
      isApproved: true,
      ...circleCadVersionData
    });
  };

  useEffect(() => {
    if (isSuccessValidateOrder) {
      dispatch(
        feedbackActions.setToast({
          message: t('validateOrderForm.success'),
          type: ToastType.SUCCESS
        })
      );
      onValidateOrderSubmit(
        values.circleCadVersionId
          ? WorkflowModelingStepEnum.MODELING
          : WorkflowPostModelingStepEnum.MANUFACTURING
      );
    }
  }, [isSuccessValidateOrder]);

  useEffect(() => {
    if (
      circleCadVersions &&
      isSuccessCircleCadVersions &&
      isSuccessOrder &&
      isSuccessUser &&
      order?.items?.length
    ) {
      const compatibleVersions = getCompatibleCircleCadVersions(user);

      if (compatibleVersions?.length > 0) {
        // Select first CircleCAD version in the list
        handleSelect(compatibleVersions[0].id.toString(), 'circleCadVersionId');
      }
      setFilteredCircleCadVersions(
        compatibleVersions?.map((v) => {
          return {
            label: v.label,
            value: v.id.toString(),
            helperText: t('validateOrderForm.form.helper', { version: v.version })
          };
        })
      );
    }
  }, [isSuccessCircleCadVersions, isSuccessOrder, isSuccessUser]);

  const getCompatibleCircleCadVersions = (user: UserInfo): CircleCadVersion[] => {
    let compatibleVersions: CircleCadVersion[];
    if (isMultiFamilyOrder) {
      // Specific functionality
      // If order has more than 1 family => always display stableCircleCadVersions
      compatibleVersions = circleCadVersions.filter((circleCadVersion) =>
        stableCircleCadVersions.includes(circleCadVersion.label)
      );
    } else if (order.items?.length) {
      // We get all catalog product CircleCAD versions linked to the order
      const versionsInOrder = order.items
        .map((item) => item.product?.circleCadVersion)
        .filter((versionInOrder) => versionInOrder);
      if (versionsInOrder?.length) {
        // We need the smallest version to only display compatible CircleCAD versions
        const smallestVersionInProduct = versionsInOrder.reduce((previous, current) =>
          semver.lte(previous, current) ? previous : current
        );
        if (smallestVersionInProduct) {
          // Only keep compatible CircleCAD versions > the smallest version in product
          // Eg : if product version is 2.3.2 => compatible versions are 2.3.2, 2.4.0, 3.0.0 ... (versions > 2.3.2)
          compatibleVersions = circleCadVersions.filter((circleCadVersion) =>
            semver.gte(circleCadVersion.version, smallestVersionInProduct)
          );
        }
      }
    }
    // filter according to user preferences
    if (!user?.isAuthorizedCircleCADBeta) {
      compatibleVersions = compatibleVersions?.filter(
        (version) =>
          ![CircleCadVersionEnum.ALPHA, CircleCadVersionEnum.BETA].includes(version.label)
      );
    }
    return compatibleVersions;
  };

  const { values, handleSubmit, handleSelect } = useForm({}, onSubmit);

  return (
    <>
      {errorValidateOrder && attentionBoxError && (
        <AttentionBox
          mode={ColorPropsEnum.DANGER}
          text={getMessageError(errorValidateOrder)}
          className={styles['validate-order-form__attention-box']}
          onClose={() => reset()}
        />
      )}
      <Box color={ColorPropsEnum.WHITE} className={styles['validate-order-form__form']}>
        <form onSubmit={handleSubmit}>
          <div className={styles['validate-order-form__form__radio']}>
            {isSuccessOrder &&
              isSuccessCircleCadVersions &&
              isSuccessUser &&
              filteredCircleCadVersions?.length > 0 && (
                <RadioList
                  title={t('validateOrderForm.form.label')}
                  options={filteredCircleCadVersions}
                  selectedValue={values?.circleCadVersionId}
                  onClick={(newValue: string) => handleSelect(newValue, 'circleCadVersionId')}
                />
              )}
            {(isLoadingOrder || isLoadingCircleCadVersions || isLoadingUser) && (
              <Skeleton type="text" className="skeleton" width="100%" />
            )}
          </div>
          <div className={styles['validate-order-form__info']}>
            {(isLoadingOrder || isLoadingCircleCadVersions || isLoadingUser) && (
              <Skeleton type="text" className="skeleton" width="100%" />
            )}
            {isSuccessOrder && isSuccessCircleCadVersions && isSuccessUser && (
              <>
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  color={ColorPropsEnum.GREY}
                  className={styles['validate-order-form__info__icon']}
                />
                <Text
                  color={ColorPropsEnum.GREY}
                  label={t(
                    `validateOrderForm.info.${
                      filteredCircleCadVersions?.length > 0 ? 'goToModeling' : 'goToManufacturing'
                    }`
                  )}
                />
              </>
            )}
          </div>
          <div className="form__submit-button form__submit-button--right">
            <Button
              label={t('validateOrderForm.action')}
              isDisabled={isLoadingOrder || isLoadingCircleCadVersions || isLoadingUser}
              isLoading={isLoadingValidateOrder}
              type="submit"
            />
          </div>
        </form>
      </Box>
    </>
  );
};

export default ValidateOrderForm;

import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DentistInfos, NewDentist, UsersReducer } from '../../models/user';

const initialUsers: UsersReducer = {
  newDentist: {
    firstName: null,
    lastName: null,
    phoneNumber: null,
    language: null,
    dentalNotation: null,
    role: null,
    email: null,
    laboratory: null,
    clinic: null,
    isAllowedToOrder: false
  },
  dentist: {
    id: null,
    email: null,
    firstName: null,
    lastName: null,
    language: null,
    dentalNotation: null,
    phoneNumber: null,
    role: null,
    laboratory: null,
    clinic: null,
    isAllowedToOrder: false
  }
};

const usersSlice = createSlice({
  name: 'users',
  initialState: initialUsers,
  reducers: {
    setNewDentist: (state, action: PayloadAction<NewDentist>) => {
      state.newDentist = action.payload;
    },
    setDentist: (state, action: PayloadAction<DentistInfos>) => {
      state.dentist = action.payload;
    },
    resetDentist: (state) => {
      state.newDentist = initialUsers.newDentist;
      state.dentist = initialUsers.dentist;
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<UsersReducer>) => {
    builder.addCase('RESET_ALL', () => {
      return { ...initialUsers };
    });
  }
});

const usersActions = usersSlice.actions;

export { usersSlice, usersActions };

import React from 'react';
import { useParams } from 'react-router-dom';
import UserDetails, { UserDetailsRefererEnum } from '../../../../features/user-details/UserDetails';

const DentistDetailPage = () => {
  const { email } = useParams();

  return <UserDetails email={email} referer={UserDetailsRefererEnum.USER_DETAILS} />;
};

export default DentistDetailPage;

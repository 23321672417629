// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,400;0,700;1,100;1,400;1,700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CXPPv41_YosfyMUA0y_A {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
}
.u8qngYn2UKBwNa6iLYgD {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  height: calc(100dvh - 20rem);
  overflow-y: auto;
  width: 80%;
}`, "",{"version":3,"sources":["webpack://./src/features/order-form/patient-files-form/patient-files-form.module.scss"],"names":[],"mappings":"AAGE;EACE,YAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,UAAA;AADJ;AAGI;EACE,aAAA;EACA,sBAAA;EACA,oBAAA;EACA,uBAAA;EACA,4BAAA;EACA,gBAAA;EACA,UAAA;AADN","sourcesContent":["@use '@anatoscope/circlestorybook/dist/vars';\n\n.patient-files-form {\n  &__form {\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 70%;\n\n    &__files {\n      display: flex;\n      flex-direction: column;\n      align-items: stretch;\n      justify-content: center;\n      height: calc(100dvh - 20rem);\n      overflow-y: auto;\n      width: 80%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"patient-files-form__form": `CXPPv41_YosfyMUA0y_A`,
	"patient-files-form__form__files": `u8qngYn2UKBwNa6iLYgD`
};
export default ___CSS_LOADER_EXPORT___;

export enum EstablishmentType {
  LABORATORY = 'LABORATORY',
  CLINIC = 'CLINIC',
  DESIGN_CENTER = 'DESIGN_CENTER'
}

export enum EstablishmentPayload {
  DESIGN_CENTER = 'designCenter',
  LABORATORY = 'laboratory',
  CLINIC = 'clinic'
}

// We need an id for the new clinic, to display it in the dropdown component (user creation form)
export const NEW_CLINIC_ID = 90000000000;

import React, { useEffect, useState } from 'react';
import '@inovua/reactdatagrid-community/index.css';
import {
  Avatar,
  BodyContainer,
  DropdownMenu,
  Header,
  IconButton,
  Toast
} from '@anatoscope/circlestorybook';
import { Outlet } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { authActions } from '../../store/auth/auth.reducers';
import { useTranslation } from 'react-i18next';
import './private-app.scss';
import { connectedUserSelector } from '../../store/auth/auth.selectors';
import { toastSelector } from '../../store/feedback/feedback.selector';
import { feedbackActions } from '../../store/feedback/feedback.reducer';
import { api } from '../../services/api';
import { resetAll } from '../../store';
import { ColorPropsEnum } from '../../enum/color.enum';
import { ToastType } from '../../enum/feedback';
import { forceRefreshToken } from '../../services/firebase.services';

type props = {
  displayLayout: boolean;
};
const PrivateApp = ({ displayLayout = true }: props) => {
  const { t } = useTranslation(['common']);
  const dispatch = useAppDispatch();

  const connectedUser = useAppSelector(connectedUserSelector);
  const toast = useAppSelector(toastSelector);

  const [isResetStore, setIsResetStore] = useState<boolean>(false);

  useEffect(() => {
    dispatch(resetAll());
    dispatch(api.util.resetApiState());
    setIsResetStore(true);
  }, []);

  const getAvatarLetter = () => {
    return connectedUser?.email?.substring(0, 1).toUpperCase();
  };

  const contentAvatarMenu = [
    [
      { label: t('link.myProfile'), type: 'nav', link: '/profile' },
      { label: t('link.myLaboratory'), type: 'nav', link: '/preferences' }
    ],
    [
      {
        label: t('link.logout'),
        type: 'button',
        onClick: () => {
          dispatch(authActions.logout());
        }
      }
    ]
  ];

  const contentCandyMenu = [
    [
      {
        label: t('appMenu.circleAppDentist'),
        type: 'nav',
        link: 'https://alpha.circle.dental',
        target: '_blank'
      },
      {
        label: t('appMenu.circleAppAdmin'),
        type: 'nav',
        link: 'https://dev.admin.circle.dental',
        target: '_blank'
      }
    ]
  ];

  const contentMainMenu = [
    [
      {
        label: t('link.dashboard'),
        type: 'nav',
        link: '/dashboard',
        iconClass: 'fa-gauge'
      },
      {
        label: t('link.production'),
        type: 'nav',
        link: '/production',
        iconClass: 'table-list'
      },
      {
        label: t('link.dentists'),
        type: 'nav',
        link: '/dentist',
        iconClass: 'fa-users'
      },
      {
        label: t('link.billing'),
        type: 'nav',
        link: '/billing',
        iconClass: 'fa-receipt',
        disabled: true
      }
    ]
  ];

  /**
   * Check if the current token is currently valid.
   * Otherwise, redirect the user to the login page.
   */
  forceRefreshToken().catch((err) => {
    err?.message.includes('auth/user-token-expired')
      ? dispatch(
          feedbackActions.setToast({
            message: t('auth/user-token-expired', { ns: 'error' }),
            type: ToastType.DANGER
          })
        )
      : dispatch(feedbackActions.setToast({ message: err.message, type: ToastType.DANGER }));
  });

  return (
    isResetStore && (
      <>
        {toast && (
          <Toast
            data-cy={`toast-${toast.type}`}
            message={'message' in toast ? toast.message : t('generic', { ns: 'error' })}
            onClose={() => {
              dispatch(feedbackActions.resetToast());
            }}
            autoHideDuration={
              toast.autoHideDuration ?? (toast.type === ToastType.DANGER ? 8000 : 3000)
            }
            type={toast.type}
          />
        )}
        {displayLayout && (
          <Header logoClass="logo--circle" title={`Circle Lab ${ENVIRONMENT_NAME}`}>
            <nav className="private-app__header__nav">
              <DropdownMenu
                renderTargetButton={(props: { active: boolean }) => (
                  <IconButton
                    className="private-app__header__nav__icon-btn"
                    faIconClass="ellipsis-vertical"
                    mode="candy-menu"
                    isActive={props.active}
                    radius="full"
                    color={ColorPropsEnum.WHITE}
                  />
                )}
                data={contentCandyMenu}
              />
              <DropdownMenu
                renderTargetButton={(props: { active: boolean }) => (
                  <Avatar
                    label={getAvatarLetter() || ''}
                    isActive={props.active}
                    cursorPointer={true}
                    className="private-app__header__nav__avatar"
                  />
                )}
                data={contentAvatarMenu}
              />
            </nav>
          </Header>
        )}
        {displayLayout && (
          <BodyContainer
            menuData={contentMainMenu}
            isMenuOpenedInitially={false}
            className="private-app__body">
            <Outlet />
          </BodyContainer>
        )}

        {!displayLayout && (
          <div className="private-app__viewer__body">
            <Outlet />
          </div>
        )}
      </>
    )
  );
};
export default PrivateApp;

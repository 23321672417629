import React, { useEffect } from 'react';
import { Text } from '@anatoscope/circlestorybook';
import { OrderFile } from '../../../../../../models/order';
import { useTranslation } from 'react-i18next';
import {
  areSameFiles,
  displayFileInViewer,
  downloadFile
} from '../../../../../../features/file-manager/file.utils';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import FileManagerContainer from '../../../../../../features/file-manager/FileManagerContainer';
import {
  isLoadingFilesSelector,
  orderFile3dToDisplaySelector,
  orderFileImageToDisplaySelector,
  orderFilesSelector,
  orderFileTextureToDisplaySelector
} from '../../../../../../store/orders/orders.selectors';
import { downloadFileAction } from '../../../../../../features/file-manager/menu-entry';
import { feedbackActions } from '../../../../../../store/feedback/feedback.reducer';
import { ToastType } from '../../../../../../enum/feedback';
import { FileLabelEnum } from '../../../../../../enum/file-label';
import { ordersActions } from '../../../../../../store/orders/orders.reducer';
import { useLabelOrderPatientFileMutation } from '../../../../../../services/orders-api.services';

type FileManagerProps = {
  isReadOnly: boolean;
  orderNumber: string;
};

const OrderDetailFile = ({ isReadOnly, orderNumber }: FileManagerProps) => {
  const { t } = useTranslation(['fileManager']);
  const dispatch = useAppDispatch();

  // RTK Query
  const [patchFileLabel] = useLabelOrderPatientFileMutation();
  // UseState
  const file3dToDisplay = useAppSelector(orderFile3dToDisplaySelector);
  const fileTextureToDisplay = useAppSelector(orderFileTextureToDisplaySelector);
  const fileImageToDisplay = useAppSelector(orderFileImageToDisplaySelector);
  const isLoadingFiles = useAppSelector(isLoadingFilesSelector);
  const orderFiles = useAppSelector(orderFilesSelector);

  useEffect(() => {
    displayFileInViewer(orderFiles, orderFiles[orderFiles.length - 1], dispatch);
  }, [orderFiles]);

  const handleDownloadFileClick = (file?: OrderFile): void => {
    dispatch(
      feedbackActions.setToast({
        message: t('actions.downloadFile.pending'),
        type: ToastType.INFO,
        autoHideDuration: 0
      })
    );
    const fileInState = orderFiles.find((thisFile) => file.id === thisFile.id);

    downloadFile(fileInState);
    dispatch(feedbackActions.resetToast());
  };

  const handleLabelChangeValue = (file: OrderFile, newValue: FileLabelEnum): void => {
    // Immediately display new label
    const updatedOrderFiles = orderFiles.map((orderFile) =>
      areSameFiles(orderFile, file) ? { ...orderFile, fileLabel: newValue } : orderFile
    );
    dispatch(ordersActions.setFiles(updatedOrderFiles));
    // Patch order
    if (newValue) {
      patchFileLabel({
        orderNumber: orderNumber,
        file: { ...file, fileLabel: newValue }
      });
    }
  };

  const fileActions = (file: OrderFile) => [[downloadFileAction(file, handleDownloadFileClick)]];

  return (
    <>
      {orderFiles?.length > 0 && (
        <FileManagerContainer
          filesList={orderFiles}
          isViewerLoading={isLoadingFiles}
          isReadOnly={isReadOnly}
          onLabelChangeCallback={(file: OrderFile, label: FileLabelEnum) =>
            handleLabelChangeValue(file, label)
          }
          height={'245px'}
          file3DToDisplay={file3dToDisplay}
          fileTextureToDisplay={fileTextureToDisplay}
          fileImageToDisplay={fileImageToDisplay}
          fileActions={fileActions}
          onClickFileCallback={(selectedFile: OrderFile) =>
            displayFileInViewer(orderFiles, selectedFile, dispatch)
          }
        />
      )}
      {!orderFiles?.length && <Text label={t('noFile')} />}
    </>
  );
};
export default OrderDetailFile;

import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  MapClickPayload,
  MapContext,
  MapReducer,
  Position,
  ProductAttributesForPosition,
  ProductShadePayload,
  sortedLowerPositionsArray,
  sortedPositionsArray,
  sortedUpperPositionsArray
} from '../../models/map';
import { computeRangeKeys, isUpperArch } from '../../features/order-manager/teeth-map/utils';
import {
  ComponentType,
  DentalArchEnum,
  GingivaShadeEnum,
  MaterialEnum,
  PositionKey,
  ToothShadeEnum,
  ToothShapeEnum
} from '../../enum/component';
import {
  PositionKeyString,
  SelectionContextEnum,
  ToolEnum,
  ToothSelectionEnum,
  ZoneLinkEnum
} from '../../enum/map.enum';
import { StumpMode, TeethMode } from '../../enum/product.enum';
import {
  computeInitForbiddenPositions,
  computeSingleRangeForbiddenPositions,
  getTeethBetweenZoneMultirange
} from '../../features/order-manager/teeth-map/cursors.utils';
import { OrderItem } from '../../models/order';
import { FamilyColorEnum } from '../../enum/color.enum';
import { getItemComponentAttribute } from '../../utils/order.utils';

const initialPosition: Position = {
  teethShade: undefined, // not defining a default teethShade, stb handles it
  inlayShade: undefined, // not defining a default inlayShade, stb handles it
  onlayShade: undefined, // not defining a default onlayShade, stb handles it
  overlayShade: undefined, // not defining a default overlayShade, stb handles it
  gingivaShade: undefined,
  shape: undefined,
  frame: undefined,
  missing: false,
  extract: false,
  selection: ToothSelectionEnum.UNSELECTABLE,
  notation: undefined,
  arch: undefined,
  zone_link: undefined,
  productIds: [],
  tooth: undefined,
  inlay: undefined,
  onlay: undefined,
  overlay: undefined,
  stump: undefined,
  gingiva: undefined,
  familyColor: undefined // for color line/number of the map
};

const initialMap: MapReducer = {
  positions: {
    '18': {
      ...initialPosition,
      notation: '18',
      arch: DentalArchEnum.UPPER
    },
    '17': {
      ...initialPosition,
      notation: '17',
      arch: DentalArchEnum.UPPER
    },
    '16': {
      ...initialPosition,
      notation: '16',
      arch: DentalArchEnum.UPPER
    },
    '15': {
      ...initialPosition,
      notation: '15',
      arch: DentalArchEnum.UPPER
    },
    '14': {
      ...initialPosition,
      notation: '14',
      arch: DentalArchEnum.UPPER
    },
    '13': {
      ...initialPosition,
      notation: '13',
      arch: DentalArchEnum.UPPER
    },
    '12': {
      ...initialPosition,
      notation: '12',
      arch: DentalArchEnum.UPPER
    },
    '11': {
      ...initialPosition,
      notation: '11',
      arch: DentalArchEnum.UPPER
    },
    '21': {
      ...initialPosition,
      notation: '21',
      arch: DentalArchEnum.UPPER
    },
    '22': {
      ...initialPosition,
      notation: '22',
      arch: DentalArchEnum.UPPER
    },
    '23': {
      ...initialPosition,
      notation: '23',
      arch: DentalArchEnum.UPPER
    },
    '24': {
      ...initialPosition,
      notation: '24',
      arch: DentalArchEnum.UPPER
    },
    '25': {
      ...initialPosition,
      notation: '25',
      arch: DentalArchEnum.UPPER
    },
    '26': {
      ...initialPosition,
      notation: '26',
      arch: DentalArchEnum.UPPER
    },
    '27': {
      ...initialPosition,
      notation: '27',
      arch: DentalArchEnum.UPPER
    },
    '28': {
      ...initialPosition,
      notation: '28',
      arch: DentalArchEnum.UPPER
    },
    '48': {
      ...initialPosition,
      notation: '48',
      arch: DentalArchEnum.LOWER
    },
    '47': {
      ...initialPosition,
      notation: '47',
      arch: DentalArchEnum.LOWER
    },
    '46': {
      ...initialPosition,
      notation: '46',
      arch: DentalArchEnum.LOWER
    },
    '45': {
      ...initialPosition,
      notation: '45',
      arch: DentalArchEnum.LOWER
    },
    '44': {
      ...initialPosition,
      notation: '44',
      arch: DentalArchEnum.LOWER
    },
    '43': {
      ...initialPosition,
      notation: '43',
      arch: DentalArchEnum.LOWER
    },
    '42': {
      ...initialPosition,
      notation: '42',
      arch: DentalArchEnum.LOWER
    },
    '41': {
      ...initialPosition,
      notation: '41',
      arch: DentalArchEnum.LOWER
    },
    '31': {
      ...initialPosition,
      notation: '31',
      arch: DentalArchEnum.LOWER
    },
    '32': {
      ...initialPosition,
      notation: '32',
      arch: DentalArchEnum.LOWER
    },
    '33': {
      ...initialPosition,
      notation: '33',
      arch: DentalArchEnum.LOWER
    },
    '34': {
      ...initialPosition,
      notation: '34',
      arch: DentalArchEnum.LOWER
    },
    '35': {
      ...initialPosition,
      notation: '35',
      arch: DentalArchEnum.LOWER
    },
    '36': {
      ...initialPosition,
      notation: '36',
      arch: DentalArchEnum.LOWER
    },
    '37': {
      ...initialPosition,
      notation: '37',
      arch: DentalArchEnum.LOWER
    },
    '38': {
      ...initialPosition,
      notation: '38',
      arch: DentalArchEnum.LOWER
    }
  },
  mapContext: undefined
};

export const mapSlice = createSlice({
  name: 'map',
  initialState: initialMap,
  reducers: {
    initMapContext: (state, action: PayloadAction<Partial<MapContext>>) => {
      const { teethMode, teethComponentRule, productRule, productId } = action.payload;
      state.mapContext = {
        ...state.mapContext,
        productId,
        teethMode,
        teethComponentRule,
        productRule
      } as MapContext;
    },
    resetMapContext: (state) => {
      state.mapContext = undefined;
    },
    resetSelectionTeeth: (state) => {
      Object.keys(state.positions).forEach((positionKey) => {
        state.positions[positionKey].selection = ToothSelectionEnum.UNSELECTABLE;
      });
    },
    setTeethMode: (state, action: PayloadAction<TeethMode>) => {
      state.mapContext = {
        ...state.mapContext,
        teethMode: action.payload
      } as MapContext;
    },
    toggleToothExtract: (state, action: PayloadAction<string>) => {
      state.positions[action.payload] = {
        ...state.positions[action.payload],
        extract: !state.positions[action.payload].extract,
        missing: false
      };
    },
    toggleToothMissing: (state, action: PayloadAction<PositionKeyString>) => {
      state.positions[action.payload] = {
        ...state.positions[action.payload],
        missing: !state.positions[action.payload].missing,
        extract: false
      };
    },
    addMissingTeethToMap: (state, action: PayloadAction<PositionKey[]>) => {
      const newPositions = { ...state.positions };
      action.payload.forEach((missingToothPosition) => {
        newPositions[missingToothPosition] = {
          ...newPositions[missingToothPosition],
          missing: true,
          extract: false
        };
      });
      state.positions = { ...newPositions };
    },
    addExtractedTeethToMap: (state, action: PayloadAction<PositionKey[]>) => {
      const newPositions = { ...state.positions };
      action.payload.forEach((missingToothPosition) => {
        newPositions[missingToothPosition] = {
          ...newPositions[missingToothPosition],
          missing: false,
          extract: true
        };
      });
      state.positions = { ...newPositions };
    },
    initSelectionTooth: (state) => {
      let forbiddenPositions: Array<PositionKeyString> = [];
      switch (state.mapContext?.teethMode) {
        case TeethMode.SINGLE_RANGE: {
          break;
        }
        case TeethMode.MULTI_RANGE: {
          if (
            Object.values(state.positions).find((pos) => pos.zone_link && pos.productIds.length > 0)
          ) {
            // It is not possible to add a multirange inside another multirange
            forbiddenPositions.push(
              ...getTeethBetweenZoneMultirange(state.positions, DentalArchEnum.UPPER)
            );
            forbiddenPositions.push(
              ...getTeethBetweenZoneMultirange(state.positions, DentalArchEnum.LOWER)
            );
          }
          break;
        }
      }

      const lowerForbiddenPositions = computeInitForbiddenPositions(
        state.mapContext?.teethComponentRule?.min || 0,
        state.positions,
        DentalArchEnum.LOWER
      );
      const upperForbiddenPositions = computeInitForbiddenPositions(
        state.mapContext?.teethComponentRule?.min || 0,
        state.positions,
        DentalArchEnum.UPPER
      );
      forbiddenPositions = [
        ...forbiddenPositions,
        ...lowerForbiddenPositions,
        ...upperForbiddenPositions
      ];

      Object.keys(state.positions).forEach((positionKey: string) => {
        if (state.positions[positionKey].selection !== ToothSelectionEnum.SELECTED) {
          // Not possible to select a tooth where there is missing
          // Not possible to select a tooth where there is a product -> TODO should be updated with compatibility product rules
          if (
            state.positions[positionKey].missing ||
            state.positions[positionKey].productIds.length > 0 ||
            [...new Set(forbiddenPositions)].includes(positionKey as PositionKeyString)
          ) {
            state.positions[positionKey] = {
              ...state.positions[positionKey],
              selection: ToothSelectionEnum.UNSELECTABLE
            };
          } else {
            state.positions[positionKey] = {
              ...state.positions[positionKey],
              selection: ToothSelectionEnum.SELECTABLE
            };
          }
        }
      });
    },
    computeProductSelectionTooth: (state) => {
      let forbiddenPositions: Array<PositionKeyString> = [];
      switch (state.mapContext?.teethMode) {
        case TeethMode.SINGLE_RANGE: {
          // 1. click occurred on tooth map && context is range-started => first click occurred
          if (
            state.mapContext?.userAction === SelectionContextEnum.RANGE_STARTED &&
            state.mapContext?.start &&
            state.mapContext?.activeArch
          ) {
            forbiddenPositions = computeSingleRangeForbiddenPositions(
              state.mapContext.start,
              state.mapContext?.teethComponentRule?.min || 0,
              state.mapContext?.teethComponentRule?.max || 10,
              state.mapContext.activeArch
            );

            // not possible to click on the opposite arch
            if (state.mapContext?.activeArch) {
              forbiddenPositions = isUpperArch(state.mapContext.activeArch)
                ? [...forbiddenPositions, ...sortedLowerPositionsArray]
                : [...forbiddenPositions, ...sortedUpperPositionsArray];
            }
          }

          // 2. context is range-end => second click occurred, product end
          if (state.mapContext?.userAction === SelectionContextEnum.RANGE_ENDED) {
            forbiddenPositions = sortedPositionsArray;
          }
          break;
        }
        case TeethMode.MULTI_RANGE: {
          if (state.mapContext.userAction) {
            // not possible to click on the opposite arch
            if (state.mapContext?.activeArch) {
              forbiddenPositions = isUpperArch(state.mapContext.activeArch)
                ? [...forbiddenPositions, ...sortedLowerPositionsArray]
                : [...forbiddenPositions, ...sortedUpperPositionsArray];
            }
          }

          break;
        }
      }

      Object.keys(state.positions).forEach((positionKey: string) => {
        if (state.positions[positionKey].selection !== ToothSelectionEnum.SELECTED) {
          // Not possible to select a tooth where there is missing
          // Not possible to select a tooth where there is a product -> TODO should be updated with compatibility product rules
          if (
            state.positions[positionKey].missing ||
            state.positions[positionKey].productIds.length > 0 ||
            [...new Set(forbiddenPositions)].includes(positionKey as PositionKeyString)
          ) {
            state.positions[positionKey] = {
              ...state.positions[positionKey],
              selection: ToothSelectionEnum.UNSELECTABLE
            };
          } else {
            state.positions[positionKey] = {
              ...state.positions[positionKey],
              selection: ToothSelectionEnum.SELECTABLE
            };
          }
        }
      });
    },
    computeMouthSelectionTooth: (state, action: PayloadAction<ToolEnum>) => {
      Object.keys(state.positions).forEach((positionKey: string) => {
        if (
          action.payload === ToolEnum.MISSING &&
          state.positions[positionKey].productIds.length > 0
        ) {
          state.positions[positionKey] = {
            ...state.positions[positionKey],
            selection: ToothSelectionEnum.UNSELECTABLE
          };
        } else {
          state.positions[positionKey] = {
            ...state.positions[positionKey],
            selection: ToothSelectionEnum.SELECTABLE
          };
        }
      });
    },
    selectSinglePosition: (state, action: PayloadAction<MapClickPayload>): void => {
      const isAlreadySelected =
        state.positions[action.payload.notation].selection === ToothSelectionEnum.SELECTED;
      if (!isAlreadySelected) {
        // Reset other selected tooth (selected tooth has empty productIds) => only one tooth per product
        Object.entries(state.positions)
          .filter(([, position]) => !position.productIds?.length)
          .forEach(([positionKey]) => {
            state.positions[positionKey].selection = ToothSelectionEnum.UNSELECTABLE;
            state.positions[positionKey].stump = undefined;
            state.positions[positionKey].teethShade = undefined;
            state.positions[positionKey].familyColor = undefined;
          });
      }

      state.positions[action.payload.notation] = {
        ...state.positions[action.payload.notation],
        selection: !isAlreadySelected ? ToothSelectionEnum.SELECTED : undefined,
        tooth: !isAlreadySelected
          ? action.payload.componentTypes.includes(ComponentType.TOOTH)
          : undefined,
        stump: !isAlreadySelected ? action.payload.stumpMode === StumpMode.ALWAYS : undefined,
        inlay: !isAlreadySelected
          ? action.payload.componentTypes.includes(ComponentType.INLAY)
          : undefined,
        onlay: !isAlreadySelected
          ? action.payload.componentTypes.includes(ComponentType.ONLAY)
          : undefined,
        overlay: !isAlreadySelected
          ? action.payload.componentTypes.includes(ComponentType.OVERLAY)
          : undefined,
        teethShade: !isAlreadySelected ? action.payload.teethShade : undefined,
        familyColor: !isAlreadySelected ? action.payload.familyColor : undefined
      };
      state.mapContext = {
        ...state.mapContext,
        userAction: SelectionContextEnum.SINGLE_TOOTH,
        activeArch: state.mapContext?.activeArch ?? state.positions[action.payload.notation].arch
      } as MapContext;
    },
    startSingleRange: (state, action: PayloadAction<MapClickPayload>) => {
      state.positions[action.payload.notation] = {
        ...state.positions[action.payload.notation],
        selection: ToothSelectionEnum.SELECTED,
        tooth: action.payload.componentTypes.includes(ComponentType.TOOTH),
        gingivaShade: action.payload.gingivaShade,
        teethShade: action.payload.teethShade,
        shape: action.payload.shape,
        frame: action.payload.frameMaterial,
        gingiva: action.payload.componentTypes.includes(ComponentType.GINGIVA),
        familyColor: action.payload.familyColor
      };
      state.mapContext = {
        ...state.mapContext,
        userAction: SelectionContextEnum.RANGE_STARTED, // context: user just opened the range
        start: action.payload.notation,
        activeArch: state.mapContext?.activeArch ?? state.positions[action.payload.notation].arch
      } as MapContext;
    },
    closeSingleRange: (state, action: PayloadAction<MapClickPayload>) => {
      const rangeKeys = computeRangeKeys(state.mapContext?.start || '', action.payload.notation);

      Object.keys(state.positions).forEach((positionKey) => {
        if (rangeKeys.includes(positionKey)) {
          state.positions[positionKey] = {
            ...state.positions[positionKey],
            selection: ToothSelectionEnum.SELECTED,
            tooth: action.payload.componentTypes.includes(ComponentType.TOOTH),
            gingivaShade: action.payload.gingivaShade,
            teethShade: action.payload.teethShade,
            shape: action.payload.shape,
            frame: action.payload.frameMaterial,
            gingiva: action.payload.componentTypes.includes(ComponentType.GINGIVA),
            missing: false,
            familyColor: action.payload.familyColor
          };
        }
      });

      state.mapContext = {
        ...state.mapContext,
        userAction: SelectionContextEnum.RANGE_ENDED, // context: user just closed the range
        end: action.payload.notation
      } as MapContext;
    },
    startZone: (state, action: PayloadAction<MapClickPayload>) => {
      state.positions[action.payload.notation] = {
        ...state.positions[action.payload.notation],
        selection: ToothSelectionEnum.SELECTED,
        gingivaShade: action.payload.gingivaShade,
        teethShade: action.payload.teethShade,
        tooth: action.payload.componentTypes.includes(ComponentType.TOOTH),
        frame: action.payload.frameMaterial,
        shape: action.payload.shape,
        gingiva: action.payload.componentTypes.includes(ComponentType.GINGIVA),
        familyColor: action.payload.familyColor
      };
      state.mapContext = {
        ...state.mapContext,
        userAction: SelectionContextEnum.ZONE_STARTED, // context: user just opened the zone of multi range
        start: action.payload.notation,
        activeArch: state.mapContext?.activeArch ?? state.positions[action.payload.notation].arch
      } as MapContext;
    },
    endZone: (state, action: PayloadAction<MapClickPayload>) => {
      const rangeKeys = computeRangeKeys(state.mapContext?.start || '', action.payload.notation);
      Object.keys(state.positions).forEach((positionKey) => {
        if (rangeKeys.includes(positionKey)) {
          state.positions[positionKey] = {
            ...state.positions[positionKey],
            selection: ToothSelectionEnum.SELECTED,
            gingivaShade: action.payload.gingivaShade,
            teethShade: action.payload.teethShade,
            tooth: action.payload.componentTypes.includes(ComponentType.TOOTH),
            shape: action.payload.shape,
            frame: action.payload.frameMaterial,
            gingiva: action.payload.componentTypes.includes(ComponentType.GINGIVA),
            missing: false,
            familyColor: action.payload.familyColor
          };
        }
      });
      state.mapContext = {
        ...state.mapContext,
        userAction: SelectionContextEnum.ZONE_ENDED, // context: user just closed the zone of multi range
        end: action.payload.notation
      } as MapContext;
    },
    computeZoneLinkPositions: (state) => {
      // Add zone_link to positions

      // Reset zone link positions
      Object.keys(state.positions).forEach((positionKey) => {
        if (state.positions[positionKey].selection === ToothSelectionEnum.SELECTED) {
          state.positions[positionKey] = {
            ...state.positions[positionKey],
            zone_link: undefined
          };
        }
      });

      let startArc: PositionKeyString | null = null;
      let currentArch: DentalArchEnum | null = null;

      sortedPositionsArray.forEach((position: PositionKeyString, index) => {
        const currentPosition = state.positions[position];
        const prevPosition = state.positions[sortedPositionsArray[index - 1]];
        const nextPosition = state.positions[sortedPositionsArray[index + 1]];

        // If we are not on the same arch anymore, reset startArc
        if (currentPosition.arch !== currentArch) {
          startArc = null;
        }
        currentArch = currentPosition.arch;

        const isCurrentActive = currentPosition.selection === ToothSelectionEnum.SELECTED;
        const isPrevSelectable = prevPosition?.selection === ToothSelectionEnum.SELECTABLE;
        const isNextSelectable = nextPosition?.selection === ToothSelectionEnum.SELECTABLE;

        const currentPrdIds = currentPosition.productIds.filter((productIdPosition) => {
          return productIdPosition.teethMode === TeethMode.MULTI_RANGE;
        });
        const prevPrdIds = prevPosition?.productIds || [];
        const nextPrdIds = nextPosition?.productIds || [];

        if (!currentPosition) return;

        const hasMatchingUniqueProductId = (
          array1: ProductAttributesForPosition[],
          array2: ProductAttributesForPosition[]
        ) => {
          return array1.some((entry1) =>
            array2.find((entry2) => entry2.uniqueProductId === entry1.uniqueProductId)
          );
        };

        if (
          // link zone has been started, the current position is Active, the previous and next position is Selectable
          // OR an existing product starts and ends at this position
          // => single tooth where two links zone ends and begins
          (startArc && isCurrentActive && isPrevSelectable && isNextSelectable) ||
          (startArc &&
            currentPrdIds.length &&
            !hasMatchingUniqueProductId(currentPrdIds, nextPrdIds) &&
            !hasMatchingUniqueProductId(currentPrdIds, prevPrdIds))
        ) {
          currentPosition.zone_link = ZoneLinkEnum.END_START;
        } else if (
          // no link zone has been started, the current position is Active and the next position is Selectable
          // OR an existing product ends at this position
          // => begin the link zone
          (startArc === null && isCurrentActive && isNextSelectable) ||
          (startArc === null &&
            currentPrdIds.length &&
            !hasMatchingUniqueProductId(currentPrdIds, nextPrdIds))
        ) {
          startArc = position;
          currentPosition.zone_link = ZoneLinkEnum.START;
        } else if (
          // link zone has been started, the current position is Active, the previous position is Selectable
          // OR an existing product starts at this position
          // => end the link zone
          (startArc && isCurrentActive && isPrevSelectable) ||
          (startArc &&
            currentPrdIds.length &&
            !hasMatchingUniqueProductId(currentPrdIds, prevPrdIds))
        ) {
          currentPosition.zone_link = ZoneLinkEnum.END;
          startArc = null;
        }
      });
    },
    removeSaneTeeth: (state, action: PayloadAction<string>) => {
      const arch = state.positions[action.payload].arch;
      Object.keys(state.positions).forEach((positionKey) => {
        if (
          state.positions[positionKey].arch === arch &&
          state.positions[positionKey].selection !== ToothSelectionEnum.SELECTED &&
          state.positions[positionKey].productIds.length === 0 &&
          !state.positions[positionKey].extract
        ) {
          state.positions[positionKey].missing = true;
        }
      });
    },
    setActiveProductShade: (state, action: PayloadAction<ProductShadePayload>) => {
      Object.keys(state.positions).forEach((positionKey) => {
        if (state.positions[positionKey].selection === ToothSelectionEnum.SELECTED) {
          state.positions[positionKey][action.payload.target] = action.payload.shade;
        }
      });
    },
    setActiveProductShape: (state, action: PayloadAction<ToothShapeEnum>) => {
      Object.keys(state.positions).forEach((positionKey) => {
        if (state.positions[positionKey].selection === ToothSelectionEnum.SELECTED) {
          state.positions[positionKey].shape = action.payload;
        }
      });
    },
    setActiveProductGingiva: (state, action: PayloadAction<GingivaShadeEnum>) => {
      Object.keys(state.positions).forEach((positionKey) => {
        if (state.positions[positionKey].selection === ToothSelectionEnum.SELECTED) {
          state.positions[positionKey].gingivaShade = action.payload;
        }
      });
    },
    setActiveProductFrameMaterial: (state, action: PayloadAction<MaterialEnum>) => {
      Object.keys(state.positions).forEach((positionKey) => {
        if (state.positions[positionKey].selection === ToothSelectionEnum.SELECTED) {
          state.positions[positionKey].frame = action.payload;
        }
      });
    },
    commitProductToMap: (state, action: PayloadAction<ProductAttributesForPosition>) => {
      Object.keys(state.positions).forEach((positionKey) => {
        if (state.positions[positionKey].selection === ToothSelectionEnum.SELECTED) {
          state.positions[positionKey].productIds = [
            ...state.positions[positionKey].productIds,
            action.payload
          ];
          state.positions[positionKey].selection = ToothSelectionEnum.UNSELECTABLE;
        }
      });
    },
    addItemsToMap: (state, action: PayloadAction<OrderItem[]>) => {
      const newPositions = { ...state.positions };
      action.payload.forEach((item) => {
        item.itemComponents?.forEach((itemComponent) => {
          const positions = [
            ...(itemComponent?.teethPositions ?? []),
            ...(itemComponent?.stumpPositions ?? []),
            ...(itemComponent?.injectionPositions ?? [])
          ];
          const productUpdate = {
            productId: item.product.id,
            uniqueProductId: `${item.product.id}_${item.id}`,
            teethMode: item.product.teethMode
          };

          const familyColor = FamilyColorEnum[`FAMILY_${item.product.family}`];

          positions.forEach((position) => {
            const posStr = position.toString();
            const statePosition = newPositions[posStr];

            // ProductId
            const idAlreadyExistsAtPosition = statePosition.productIds.some(
              (product) => product.productId === productUpdate.productId
            );
            if (!idAlreadyExistsAtPosition) {
              statePosition.productIds.push(productUpdate);
            }

            // Shade
            if (itemComponent.shade) {
              switch (itemComponent.componentType) {
                case ComponentType.TOOTH:
                  statePosition.teethShade = getItemComponentAttribute(item, ComponentType.TOOTH)
                    ?.shade?.code as ToothShadeEnum;
                  break;
                case ComponentType.GINGIVA:
                  statePosition.gingiva = true;
                  statePosition.gingivaShade = getItemComponentAttribute(
                    item,
                    ComponentType.GINGIVA
                  )?.shade?.code as GingivaShadeEnum;
                  break;
                case ComponentType.INLAY:
                  statePosition.inlayShade = getItemComponentAttribute(item, ComponentType.INLAY)
                    ?.shade?.code as ToothShadeEnum;
                  break;
                case ComponentType.ONLAY:
                  statePosition.onlayShade = getItemComponentAttribute(item, ComponentType.ONLAY)
                    ?.shade?.code as ToothShadeEnum;
                  break;
                case ComponentType.OVERLAY:
                  statePosition.overlayShade = getItemComponentAttribute(
                    item,
                    ComponentType.OVERLAY
                  )?.shade?.code as ToothShadeEnum;
                  break;
              }
            }

            // Shape
            if (itemComponent.shape) {
              statePosition.shape = getItemComponentAttribute(item, ComponentType.TOOTH)?.shape
                ?.code as ToothShapeEnum;
            }

            // Frame
            if (itemComponent.material && itemComponent.componentType === ComponentType.FRAME) {
              statePosition.frame = getItemComponentAttribute(item, ComponentType.FRAME)?.material
                ?.code as MaterialEnum;
            }

            // Family color
            statePosition.familyColor = familyColor;

            // Stump
            statePosition.stump = (itemComponent?.stumpPositions ?? []).includes(position);

            // Tooth
            // TeethPositions can hold positions of other components
            // Only display teeth when it's actually the teeth component, otherwise leave it as it is
            if (itemComponent.componentType === ComponentType.TOOTH) {
              statePosition.tooth = (itemComponent?.teethPositions ?? []).includes(position);
            }

            // Parts of tooth
            if (itemComponent.componentType === ComponentType.INLAY) {
              statePosition.inlay = (itemComponent?.teethPositions ?? []).includes(position);
            }
            if (itemComponent.componentType === ComponentType.ONLAY) {
              statePosition.onlay = (itemComponent?.teethPositions ?? []).includes(position);
            }
            if (itemComponent.componentType === ComponentType.OVERLAY) {
              statePosition.overlay = (itemComponent?.teethPositions ?? []).includes(position);
            }
          });
        });
      });

      state.positions = { ...newPositions };
    },
    removeActiveProduct: (state) => {
      Object.keys(state.positions).forEach((positionKey) => {
        if (state.positions[positionKey].selection === ToothSelectionEnum.SELECTED) {
          state.positions[positionKey] = {
            ...initialPosition,
            notation: state.positions[positionKey].notation,
            arch: state.positions[positionKey].arch,
            productIds: state.positions[positionKey].productIds
          };
        }
      });
    },
    resetMap: (state) => {
      state.positions = initialMap.positions;
    },
    removeProduct: (state, action: PayloadAction<{ item: OrderItem }>) => {
      const teethPositions = action.payload.item.itemComponents.find((component) =>
        [
          ComponentType.TOOTH,
          ComponentType.INLAY,
          ComponentType.ONLAY,
          ComponentType.OVERLAY
        ].includes(component.componentType)
      )?.teethPositions;

      let propertiesToReset: Array<string> = [];
      action.payload.item.itemComponents?.forEach((itemComponent) => {
        switch (itemComponent.componentType) {
          case ComponentType.TOOTH: {
            propertiesToReset = [
              ...propertiesToReset,
              'teethShade',
              'shape',
              'tooth',
              'zone_link',
              'productIds',
              'familyColor'
            ];
            break;
          }
          case ComponentType.FRAME: {
            propertiesToReset = [...propertiesToReset, 'frame'];
            break;
          }
          case ComponentType.GINGIVA: {
            propertiesToReset = [...propertiesToReset, 'gingiva', 'gingivaShade'];
            break;
          }
          case ComponentType.INLAY: {
            propertiesToReset = [
              ...propertiesToReset,
              'inlay',
              'inlayShade',
              'productIds',
              'familyColor'
            ];
            break;
          }
          case ComponentType.ONLAY: {
            propertiesToReset = [
              ...propertiesToReset,
              'onlay',
              'onlayShade',
              'productIds',
              'familyColor'
            ];
            break;
          }
          case ComponentType.OVERLAY: {
            propertiesToReset = [
              ...propertiesToReset,
              'overlay',
              'overlayShade',
              'productIds',
              'familyColor'
            ];
            break;
          }
        }
      });
      action.payload.item.product.stumpMode === StumpMode.ALWAYS &&
        (propertiesToReset = [...propertiesToReset, 'stump']);

      teethPositions
        .map((position) => state.positions[`${position}`] as Position)
        .forEach((positionState: Position) =>
          propertiesToReset.forEach((prop: keyof Position) => {
            if (prop === 'productIds') {
              positionState['productIds'] = positionState['productIds'].filter(
                (product) => product.productId !== action.payload.item.product.id
              );
            } else {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              positionState[prop] = undefined;
            }
          })
        );
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<MapReducer>) => {
    builder.addCase('RESET_ALL', () => {
      return { ...initialMap };
    });
  }
});

export const mapActions = mapSlice.actions;

import React from 'react';
import UserDetails, { UserDetailsRefererEnum } from '../../../features/user-details/UserDetails';
import { useAppSelector } from '../../../store/hooks';
import { connectedUserSelector } from '../../../store/auth/auth.selectors';

const ProfilePage = () => {
  const connectedUser = useAppSelector(connectedUserSelector);

  return <UserDetails email={connectedUser.email} referer={UserDetailsRefererEnum.PROFILE_PAGE} />;
};

export default ProfilePage;

import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

interface ProtectedRouteObj {
  isAllowed: boolean;
  redirectPath: string;
  children: JSX.Element;
}

const ProtectedRoute = ({
  isAllowed,
  redirectPath = '/',
  children
}: ProtectedRouteObj): JSX.Element => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;

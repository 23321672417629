import React from 'react';
import { getColumnOptions } from '../../../../../features/datagrid/DatagridFeature';
import {
  commentsOrderAction,
  deleteAction,
  editAction,
  toggleManufactureAction
} from '../../../../../features/datagrid/menu-entry';
import { TypeColWithNamePropertyPlatform } from '../../../../../models/datagrid';
import { Button, DropdownMenu, IconButton } from '@anatoscope/circlestorybook';
import { ActionBtn } from '../../../../../features/datagrid/columns';
import i18next from 'i18next';
import { Order } from '../../../../../models/order';
import { ManufacturingOrder } from '../../../../../models/manufacturing-order';

export const colValidationMenu = (
  handleToggleManufactureCallback: (data: Order | ManufacturingOrder) => Promise<void>,
  handleCommentCallback: (data: Order) => void
): TypeColWithNamePropertyPlatform => {
  return {
    ...getColumnOptions('menu', '', 1, false),
    showColumnMenuTool: false,
    maxWidth: 60,
    render: ({ data }) => {
      return (
        <DropdownMenu
          renderTargetButton={({ active }: { active: boolean }) => (
            <IconButton
              data-cy="datagrid-menu"
              faIconClass="ellipsis-vertical"
              isActive={active}
              radius="full"
            />
          )}
          data={[
            [
              editAction,
              data && toggleManufactureAction(data, handleToggleManufactureCallback),
              commentsOrderAction(data, handleCommentCallback)
            ],
            [deleteAction]
          ]}
        />
      );
    }
  };
};

export const colReviewAction = (actionBtn: ActionBtn): TypeColWithNamePropertyPlatform => {
  return {
    ...getColumnOptions(
      'validationAction',
      i18next.t('datagrid.columns.validation', { ns: 'production' }),
      2,
      false
    ),
    showColumnMenuTool: false,
    render: ({ data }) => {
      return <Button label={actionBtn.label} size="s" onClick={() => actionBtn.onClick(data)} />;
    }
  };
};

import React from 'react';
import {
  ComponentType,
  OrderedLowerPositions,
  OrderedUpperPositions,
  PositionKey
} from '../../enum/component';
import { Box, BubblePicto, Chips, Text } from '@anatoscope/circlestorybook';
import { ColorPropsEnum } from '../../enum/color.enum';
import { getLocalizedProperty } from '../../utils/utils';
import styles from './order-item-card.module.scss';
import { useTranslation } from 'react-i18next';
import { Product } from '../../models/product';
import i18next from '../../i18n';
import { isCategoryProvisional } from '../order-form/utils';
import { getComponentInItemByType, getItemDentalArch } from '../order-manager/teeth-map/utils';
import { CardDisplayMode, Family, ProductCategory } from '../../enum/product.enum';
import { getMaterialColor } from '../../pages/private/dashboard-page/supervision';
import { OrderItem, OrderItemComponent } from '../../models/order';

type OrderItemCardProps = {
  item: OrderItem;
  displayMode?: CardDisplayMode;
};

export const OrderItemCard = ({
  item,
  displayMode = CardDisplayMode.DEFAULT
}: OrderItemCardProps) => {
  const { t } = useTranslation(['orderDetail']);
  const toothComponent = getComponentInItemByType(item, ComponentType.TOOTH);
  const gingivaComponent = getComponentInItemByType(item, ComponentType.GINGIVA);
  const inlayComponent = getComponentInItemByType(item, ComponentType.INLAY);
  const onlayComponent = getComponentInItemByType(item, ComponentType.ONLAY);
  const overlayComponent = getComponentInItemByType(item, ComponentType.OVERLAY);

  const getOrderItemComponentMaterialLabel = (component: OrderItemComponent) => {
    return component?.material?.stratification
      ? t(`material.${component?.material?.code}`, { ns: 'component' }) +
          ' - ' +
          t(`material.stratification.${component?.material?.stratification}`, {
            ns: 'component'
          })
      : t(`material.${component?.material?.code}`, { ns: 'component' });
  };

  const isPrintDisplayMode = displayMode === CardDisplayMode.PRINT;

  const sortTeethPosition = (teethPosition: PositionKey[]) => {
    const sortedTeethPosition = [...teethPosition];
    const orderedPositions = [...OrderedUpperPositions, ...OrderedLowerPositions];
    return sortedTeethPosition.sort(
      (a, b) => orderedPositions.indexOf(a) - orderedPositions.indexOf(b)
    );
  };

  // Display provisional or definitive category (no need to display unit, bridge etc category)
  const finalCategory = (category: ProductCategory) => {
    return isCategoryProvisional(category)
      ? t(`categories.${ProductCategory.PROVISIONAL}`, { ns: 'catalog' })
      : t(`categories.${ProductCategory.DEFINITIVE}`, { ns: 'catalog' });
  };

  const displayMaterialChips = (component: OrderItemComponent) => {
    return (
      <Chips
        firstLabel={getOrderItemComponentMaterialLabel(component)}
        color={
          isPrintDisplayMode ? ColorPropsEnum.WHITE : getMaterialColor(component?.material?.code)
        }
      />
    );
  };

  const displayShadeChips = (component: OrderItemComponent) => {
    return (
      <Chips
        color={
          isPrintDisplayMode
            ? ColorPropsEnum.WHITE
            : ColorPropsEnum[component.shade.code as keyof typeof ColorPropsEnum]
        }
        firstLabel={i18next.t(`shade.${component.shade.code.toUpperCase()}`, {
          ns: 'component'
        })}
      />
    );
  };

  const displayTeethPositionText = (component: OrderItemComponent) => {
    return <Text label={sortTeethPosition(component.teethPositions).join(', ')} />;
  };

  return (
    <Box
      color={isPrintDisplayMode ? 'white' : 'grey'}
      padding={isPrintDisplayMode ? 'spacing-1' : 'spacing-5'}
      key={item.id}
      className={[styles[`order-item-card--${displayMode}`], styles['order-item-card']].join(' ')}
      data-cy="order-detail-item">
      <div className={styles[`order-item-card__header`]}>
        <div className={styles[`order-item-card__header__product`]}>
          <BubblePicto
            size="small"
            color={
              ColorPropsEnum[
                `FAMILY_${item.product.family.toUpperCase()}` as keyof typeof ColorPropsEnum
              ]
            }
            backgroundColor={isPrintDisplayMode ? ColorPropsEnum.WHITE : ColorPropsEnum.PRIMARY}
            url={item.product.imageUrl}
            isDashedBorder={isCategoryProvisional(item.product.category)}
            className={styles[`order-item-card__header__product__img`]}
            data-cy="order-detail-item-bubble"
          />
          <div className={styles[`order-item-card__header__product__label`]}>
            <Text
              label={item.product[getLocalizedProperty('label') as keyof Product] as string}
              bold={true}
              data-cy="order-detail-item-product"
            />
            <div className={styles[`order-item-card__header__product__attributes`]}>
              {toothComponent?.toothStratificationTechnique && (
                <Text
                  label={t(
                    `toothStratificationTechnique.${toothComponent?.toothStratificationTechnique}`,
                    {
                      ns: 'component'
                    }
                  )}
                />
              )}
              {item.product.family === Family.REMOV && (
                <Text
                  label={t(`arch.${getItemDentalArch(item)}`, {
                    ns: 'component'
                  })}
                />
              )}
            </div>
          </div>
        </div>
        <div className={styles[`order-item-card__header__category`]}>
          {item.product.category && (
            <Chips
              firstLabel={finalCategory(item.product.category)}
              color={ColorPropsEnum.BLACK}
              size={'m'}
            />
          )}
        </div>
      </div>
      {(toothComponent || inlayComponent || onlayComponent || overlayComponent) && (
        <>
          <div className={styles[`order-item-card__content__line`]}>
            <Text
              className={styles[`order-item-card__content__line__title`]}
              label={t('orderItemCard.positions')}
              bold={true}
            />
            <div className={styles[`order-item-card__content__line__data`]}>
              {toothComponent?.teethPositions && displayTeethPositionText(toothComponent)}
              {inlayComponent?.teethPositions && displayTeethPositionText(inlayComponent)}
              {overlayComponent?.teethPositions && displayTeethPositionText(overlayComponent)}
              {onlayComponent?.teethPositions && displayTeethPositionText(onlayComponent)}
            </div>
          </div>
          <div className={styles[`order-item-card__content__line`]}>
            <Text
              className={styles[`order-item-card__content__line__title`]}
              label={
                toothComponent?.teethPositions?.length > 1
                  ? t('orderItemCard.teeth') + ` (x${toothComponent?.teethPositions?.length})`
                  : t('orderItemCard.teeth')
              }
              bold={true}
            />
            <div className={styles[`order-item-card__content__line__data`]}>
              {toothComponent?.material && displayMaterialChips(toothComponent)}
              {inlayComponent?.material && displayMaterialChips(inlayComponent)}
              {onlayComponent?.material && displayMaterialChips(onlayComponent)}
              {overlayComponent?.material && displayMaterialChips(overlayComponent)}
              {toothComponent?.shade && displayShadeChips(toothComponent)}
              {inlayComponent?.shade && displayShadeChips(inlayComponent)}
              {onlayComponent?.shade && displayShadeChips(onlayComponent)}
              {overlayComponent?.shade && displayShadeChips(overlayComponent)}
              {toothComponent?.shape && (
                <Text
                  label={i18next.t(`shape.${toothComponent.shape.code.toUpperCase()}`, {
                    ns: 'component'
                  })}
                />
              )}
            </div>
          </div>
        </>
      )}
      {gingivaComponent && (
        <div className={styles[`order-item-card__content__line`]}>
          <Text
            className={styles[`order-item-card__content__line__title`]}
            label={t('orderItemCard.gingiva')}
            bold={true}
          />
          <div className={styles[`order-item-card__content__line__data`]}>
            {gingivaComponent?.material && displayMaterialChips(gingivaComponent)}
            {gingivaComponent?.shade && displayShadeChips(gingivaComponent)}
            {gingivaComponent?.aspect && (
              <Text label={t(`aspect.${gingivaComponent?.aspect.code}`, { ns: 'component' })} />
            )}
          </div>
        </div>
      )}
    </Box>
  );
};

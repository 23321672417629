import React from 'react';
import LabDetails from '../../../features/lab-details/LabDetails';
import { useAppSelector } from '../../../store/hooks';
import { connectedUserSelector } from '../../../store/auth/auth.selectors';

const PreferencesPage = () => {
  const connectedUser = useAppSelector(connectedUserSelector);

  return <LabDetails email={connectedUser.email} />;
};

export default PreferencesPage;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Importing translation files
import commonFR from './translations/fr/common.json';
import commonEN from './translations/en/common.json';
import authEN from './translations/en/auth.json';
import authFR from './translations/fr/auth.json';
import dentistFR from './translations/fr/page_dentist.json';
import dentistEN from './translations/en/page_dentist.json';
import productionFR from './translations/fr/page_production.json';
import productionEN from './translations/en/page_production.json';
import dashboardFR from './translations/fr/page_dashboard.json';
import dashboardEN from './translations/en/page_dashboard.json';
import notFoundFR from './translations/fr/page_not-found.json';
import notFoundEN from './translations/en/page_not-found.json';
import componentFR from './translations/fr/component.json';
import componentEN from './translations/en/component.json';
import errorFR from './translations/fr/error.json';
import errorEN from './translations/en/error.json';
import catalogFR from './translations/fr/catalog.json';
import catalogEN from './translations/en/catalog.json';
import circleCadStreamerFR from './translations/fr/page_streamer.json';
import circleCadStreamerEN from './translations/en/page_streamer.json';
import orderEN from './translations/en/page-order.json';
import orderFR from './translations/fr/page-order.json';
import pageResetPasswordEN from './translations/en/page_reset-password.json';
import pageResetPasswordFR from './translations/fr/page_reset-password.json';
import printEN from './translations/en/page-print.json';
import printFR from './translations/fr/page-print.json';
import orderDetailFR from './translations/fr/page-order-detail.json';
import orderDetailEN from './translations/en/page-order-detail.json';
import fileManagerFR from './translations/fr/feature-file-manager.json';
import fileManagerEN from './translations/en/feature-file-manager.json';
import commentFR from './translations/fr/comment.json';
import commentEN from './translations/en/comment.json';
import historyFR from './translations/fr/history.json';
import historyEN from './translations/en/history.json';
import preferenceFR from './translations/fr/page_preference.json';
import preferenceEN from './translations/en/page_preference.json';

//Creating object with the variables of imported translation files
const resources = {
  fr: {
    common: commonFR,
    production: productionFR,
    dashboard: dashboardFR,
    auth: authFR,
    notFound: notFoundFR,
    dentist: dentistFR,
    component: componentFR,
    error: errorFR,
    catalog: catalogFR,
    circleCadStreamer: circleCadStreamerFR,
    order: orderFR,
    resetPassword: pageResetPasswordFR,
    print: printFR,
    orderDetail: orderDetailFR,
    fileManager: fileManagerFR,
    comment: commentFR,
    history: historyFR,
    preference: preferenceFR
  },
  en: {
    common: commonEN,
    production: productionEN,
    dashboard: dashboardEN,
    auth: authEN,
    notFound: notFoundEN,
    dentist: dentistEN,
    component: componentEN,
    error: errorEN,
    catalog: catalogEN,
    circleCadStreamer: circleCadStreamerEN,
    order: orderEN,
    resetPassword: pageResetPasswordEN,
    print: printEN,
    orderDetail: orderDetailEN,
    fileManager: fileManagerEN,
    comment: commentEN,
    history: historyEN,
    preference: preferenceEN
  }
};

//i18N Initialization

i18n.use(initReactI18next).init({
  resources,
  lng: 'fr', //default language
  interpolation: {
    escapeValue: false
  }
});

export default i18n;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import orderStyles from '../order-form.module.scss';
import useForm from '../../../utils/useForm';
import { Box, Button, SideBarModal, TeethMap, Text } from '@anatoscope/circlestorybook';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  currentItemSelector,
  orderErrorSelector,
  orderItemsSelector
} from '../../../store/orders/orders.selectors';
import { ordersActions } from '../../../store/orders/orders.reducer';
import { useGetConnectedUserQuery } from '../../../services/users-api.services';
import { TeethMode } from '../../../enum/product.enum';
import { DentalNotation } from '../../../enum/user';
import { ColorPropsEnum } from '../../../enum/color.enum';
import {
  cursorsSelector,
  lineAndNumberColorsSelector,
  mapComponentsSelector,
  mapContextSelector,
  zoneLinkPropsSelector
} from '../../../store/map/map.selectors';
import { mapActions } from '../../../store/map/map.reducer';
import { PositionKeyString, SelectionContextEnum } from '../../../enum/map.enum';
import {
  getFamilyColor,
  getRelevantComponentsForDisplay
} from '../../order-manager/teeth-map/utils';
import { NotAllowedCursors } from '../../order-manager/teeth-map/cursors.utils';
import AddProductModal from './add-product-modal/AddProductModal';
import { MapClickPayload } from '../../../models/map';
import { productCustomizationSelector } from '../../../store/product-customization/productCustomization.selectors';

type Props = {
  nextCallback: () => void;
  previousCallback: () => void;
};

const ProsthesisForm = ({ nextCallback, previousCallback }: Props) => {
  const { t } = useTranslation(['order']);
  const dispatch = useAppDispatch();

  const { data: connectedUser } = useGetConnectedUserQuery();

  const orderItems = useAppSelector(orderItemsSelector);
  const currentItem = useAppSelector(currentItemSelector);
  const error = useAppSelector(orderErrorSelector);
  const zoneLinkProps = useAppSelector(zoneLinkPropsSelector);
  // Teeth, shades, gingiva, ...
  const mapComponents = useAppSelector(mapComponentsSelector);
  const mapContext = useAppSelector(mapContextSelector);
  const cursors = useAppSelector(cursorsSelector);
  const lineAndNumberColors = useAppSelector(lineAndNumberColorsSelector);

  const activeCustomizations = useAppSelector(productCustomizationSelector);

  const [sideBarOpened, setSideBarOpened] = useState(false);

  const submit = (): void => {
    if (currentItem) {
      dispatch(ordersActions.setError(t('createOrder.error.endCurrentProduct')));
      return;
    }
    if (!orderItems.length) {
      dispatch(ordersActions.setError(t('createOrder.error.emptyItem')));
      return;
    }
    dispatch(ordersActions.setError(undefined));
    nextCallback();
  };

  const handlePrevious = (): void => {
    if (currentItem) {
      dispatch(ordersActions.setError(t('createOrder.error.endCurrentProduct')));
      return;
    }
    dispatch(ordersActions.setError(undefined));
    previousCallback();
  };

  const { handleSubmit } = useForm({}, submit);

  /**
   * Function to select a tooth.
   *
   * @param {PositionKeyString} selectedTooth - The tooth to be selected.
   */
  const handleSelectPosition = (selectedTooth: PositionKeyString): void => {
    if (!currentItem) {
      return;
    }

    const mapClick: MapClickPayload = {
      notation: selectedTooth,
      teethShade: activeCustomizations.teethShade,
      gingivaShade: activeCustomizations.gingivaShade,
      inlayShade: activeCustomizations.inlayShade,
      onlayShade: activeCustomizations.onlayShade,
      overlayShade: activeCustomizations.overlayShade,
      frameMaterial: activeCustomizations.frameMaterial,
      familyColor: getFamilyColor(currentItem.product.family),
      stumpMode: currentItem.product?.stumpMode,
      componentTypes: currentItem.itemComponents.map((item) => item.componentType)
    };

    dispatch(ordersActions.setError(undefined));
    switch (currentItem?.product?.teethMode) {
      case TeethMode.SINGLE_TOOTH:
        dispatch(mapActions.selectSinglePosition(mapClick));
        break;
      case TeethMode.SINGLE_RANGE:
        // 1. click occurred on tooth map && context is not defined => it's the first click
        if (!mapContext.userAction) {
          dispatch(mapActions.startSingleRange(mapClick));
        }
        // 2. click occurred on tooth map && context is range-started => it's the second click
        if (mapContext?.userAction === SelectionContextEnum.RANGE_STARTED) {
          dispatch(mapActions.closeSingleRange(mapClick));
          if (!currentItem?.product?.productRule?.allowSaneTeethInArch) {
            dispatch(mapActions.removeSaneTeeth(selectedTooth));
          }
        }
        break;
      case TeethMode.MULTI_RANGE:
        // 1. click occurred on tooth map && context is not defined (first click) or context is zone-ended => it's the first click - start new zone
        if (!mapContext.userAction || mapContext?.userAction === SelectionContextEnum.ZONE_ENDED) {
          dispatch(mapActions.startZone(mapClick));
        }

        // 2. click occurred on tooth map && context is zone-started => it's the second click - end zone
        if (mapContext?.userAction === SelectionContextEnum.ZONE_STARTED) {
          dispatch(mapActions.endZone(mapClick));
        }
        break;
    }

    // Compute zone link positions to draw them
    dispatch(mapActions.computeZoneLinkPositions());

    // update selection teeth for cursors
    dispatch(mapActions.computeProductSelectionTooth());
  };

  const handleCloseSidebar = (): void => {
    setSideBarOpened(false);
  };

  const handleCancelSidebar = (): void => {
    setSideBarOpened(false);
    dispatch(ordersActions.setCurrentItem(undefined));
  };

  return (
    <Box color={ColorPropsEnum.WHITE} className={orderStyles['order-form__box']}>
      <SideBarModal
        title={t('createOrder.summary.addProduct')}
        isOpened={sideBarOpened}
        closeOnOutsideClick={false}
        onClose={() => setSideBarOpened(false)}>
        <AddProductModal
          onCloseCallback={handleCloseSidebar}
          onCancelCallback={handleCancelSidebar}
        />
      </SideBarModal>
      <form onSubmit={handleSubmit} className={orderStyles['order-form__box__form']}>
        <div className={orderStyles['order-form__box__form__content']}>
          <div className={orderStyles['order-form__box__form__content__mouth']}>
            <div className={orderStyles['order-form__box__form__content__mouth__content']}>
              <Box className={orderStyles['order-form__box__intro']}>
                {!error && currentItem?.product?.teethMode && (
                  <Text label={t(`createOrder.prosthesis.${currentItem?.product?.teethMode}`)} />
                )}
                {error && <Text label={error} color={ColorPropsEnum.DANGER} />}
                {!error && !currentItem && orderItems?.length !== 0 && (
                  <Text label={t('createOrder.prosthesis.nextStep')} />
                )}
                {!error && !currentItem && orderItems?.length === 0 && (
                  <Text label={t('createOrder.prosthesis.emptyItem')} />
                )}
              </Box>
              <TeethMap
                hiddenBubbles={true}
                className={orderStyles['order-form__box__form__content__mouth__content__teeth-map']}
                onClick={(tooth: PositionKeyString) => handleSelectPosition(tooth)}
                patientMouth={getRelevantComponentsForDisplay(mapComponents)}
                cursors={currentItem ? cursors : NotAllowedCursors}
                notation={connectedUser ? connectedUser?.dentalNotation : DentalNotation.ISO}
                teethShades={mapComponents['TEETH_SHADES']}
                inlayShades={mapComponents['INLAY_SHADES']}
                onlayShades={mapComponents['ONLAY_SHADES']}
                overlayShades={mapComponents['OVERLAY_SHADES']}
                zoneLinkProps={zoneLinkProps}
                lineAndNumberColors={lineAndNumberColors}
              />
            </div>
          </div>
        </div>
        <div className="form__submit-button form__submit-button--double">
          <Button
            label={t('action.previous', { ns: 'common' })}
            category="tertiary"
            onClick={handlePrevious}
            iconLeft="fa-chevron-left"
            data-cy="previousButton"
          />
          <Button
            label={t('action.next', { ns: 'common' })}
            type="submit"
            category="secondary"
            iconRight="fa-chevron-right"
            data-cy="nextButton"
          />
        </div>
      </form>
    </Box>
  );
};
export default ProsthesisForm;

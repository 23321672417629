import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './reset-password-page.module.scss';
import { Button, Divider, Link, Text, TextField } from '@anatoscope/circlestorybook';
import { useAppDispatch } from '../../../store/hooks';
import { useTranslation } from 'react-i18next';
import { useResetPasswordMutation } from '../../../services/users-api.services';
import { feedbackActions } from '../../../store/feedback/feedback.reducer';
import useForm from '../../../utils/useForm';
import { StringObject } from '../../../models/common';

const ResetPasswordPage = () => {
  const { t } = useTranslation(['resetPassword']);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [resetPassword, { isLoading, isSuccess, isError }] = useResetPasswordMutation();

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        feedbackActions.setToast({
          message: t('feedback'),
          type: 'success'
        })
      );
      navigate('/login');
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      navigate('/login');
    }
  }, [isError]);

  const onSubmit = async () => {
    if (values.email) {
      resetPassword(values.email as string);
    }
  };

  const isValidEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i;

  const validateEmail = (): StringObject => {
    const newErrors: StringObject = {};
    if (!values.email) {
      newErrors.email = 'empty';
    }
    if (!newErrors.email) {
      if (!(values.email as string).match(isValidEmail)) {
        newErrors.email = 'format';
      }
    }

    return newErrors;
  };

  const { values, errors, handleSubmit, handleChange, handleBlur } = useForm(
    {
      email: ''
    },
    onSubmit,
    validateEmail
  );

  return (
    <>
      <header className={styles['reset-password-page__header']}>
        <div className="logo--circle"></div>
        <Text
          label={`Circle Lab ${ENVIRONMENT_NAME}`}
          type="title"
          size="m"
          className={styles['reset-password-page__header__title']}
        />
      </header>
      <section className={styles['reset-password-page__section']}>
        <Text
          label={t('title')}
          type="title"
          size="s"
          className={styles['reset-password-page__section__title']}
          bold={true}
        />

        <form
          noValidate
          id="reset-password-page__section__form"
          className={styles['reset-password-page__section__form']}
          data-cy={'reset-password__form'}
          onSubmit={handleSubmit}>
          <TextField
            label={t('email')}
            id="email"
            name="email"
            value={values.email}
            placeholder="email@circle.com"
            type="email"
            variant={errors.email ? 'danger' : 'default'}
            helperText={errors?.email ? t(`error.${errors.email}`) : undefined}
            wrapperClassName={styles['reset-password-page__section__form__input']}
            onChange={handleChange}
            onBlur={handleBlur}
            data-cy={'reset-password__email'}
          />

          <div className={styles['reset-password-page__section__form__button']}>
            <Button
              label={t('buttonSubmit')}
              isLoading={isLoading}
              type="submit"
              className={styles['reset-password-page__section__form__button__submit']}
              data-cy={'reset-password__submit'}
            />
          </div>
        </form>
        <Divider />
        <div className={styles['reset-password-page__section__existing-account']}>
          <Text
            className={styles['reset-password-page__section__existing-account__text']}
            label={t('existingAccount')}
            size="s"
          />
          <Link href="#" label={t('signIn')} size="s" data-cy={'reset-password__sign-in'} />
        </div>
      </section>
    </>
  );
};

export default ResetPasswordPage;

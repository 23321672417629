import { AuthenticationReducer } from '../../models/user';

interface ReducerState {
  authenticationState: AuthenticationReducer;
}

const connectedUserSelector = (state: ReducerState) => state.authenticationState.connectedUser;

const isAuthenticatedSelector = (state: ReducerState) => state.authenticationState.isAuthenticated;

export { connectedUserSelector, isAuthenticatedSelector };

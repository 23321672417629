import React, { useEffect } from 'react';
import { RadioList } from '@anatoscope/circlestorybook';
import { useTranslation } from 'react-i18next';
import { ColorPropsEnum } from '../../../../../enum/color.enum';
import { RadioListOptions } from '../../../../../models/form';
import {
  ComponentType,
  ComponentTypeProperties,
  ToothStratificationTechniqueEnum
} from '../../../../../enum/component';
import { Component } from '../../../../../models/component';
import { Aspect, Material } from '../../../../../models/common-types';

type CustomizationRadioListProps = {
  selectedValue?: string;
  error?: string;
  customizationKey: string;
  onClick: (id: string) => void;
  component: Component;
  customizationTarget?: ComponentTypeProperties;
  className?: string;
  handleSelect: (id: string, customizationKey: string) => void;
};

const CustomizationRadioList = ({
  component,
  customizationTarget,
  selectedValue,
  error,
  customizationKey,
  onClick,
  className,
  handleSelect
}: CustomizationRadioListProps) => {
  const { t } = useTranslation(['order']);
  let radioListValues: RadioListOptions[] = [];
  const key = customizationTarget as keyof typeof component; // customizationTarget could be 'shades' , 'shapes', ..

  if (
    component &&
    Array.isArray(component[key]) &&
    (component[key] as Array<Material | Aspect | ToothStratificationTechniqueEnum>)?.length
  ) {
    const customizationProperty = component[key] as Array<
      Material | Aspect | ToothStratificationTechniqueEnum
    >;
    const isDisabled = customizationProperty?.length === 1;
    radioListValues = customizationProperty?.map((value) => {
      return {
        label: t(
          `${customizationTarget.substring(0, customizationTarget.length - 1)}.${
            typeof value === 'string' ? value : value.code //stratification techniques is an enum and have not 'code' attribute
          }`,
          {
            ns: 'component'
          }
        ),
        value: (typeof value === 'string' ? value : value.id).toString(), //stratification techniques is an enum and have not 'id' attribute
        isDisabled: isDisabled
      };
    });

    if (
      radioListValues?.length &&
      component?.rule?.min === 0 &&
      component.componentType === ComponentType.FRAME // figure a generic rule and remove this line
    ) {
      // example if frame material is optional, add a NO FRAME option
      radioListValues = [
        component?.rule?.min === 0 && {
          label: t(`createOrder.addProductModal.customization.${customizationKey}.optional`),
          value: '-1'
        },
        ...radioListValues
      ];
    }
  }

  useEffect(() => {
    if (!selectedValue) {
      handleSelect(radioListValues[0].value as string, customizationKey);
    }
  }, []);

  return (
    radioListValues && (
      <RadioList
        data-cy={customizationKey}
        id={customizationKey}
        selectedValue={selectedValue}
        variant={error ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
        displayedInRow={true}
        title={t(`createOrder.addProductModal.customization.${customizationKey}.label`)}
        onClick={(id: string) => {
          onClick(id);
        }}
        options={radioListValues}
        className={className}
      />
    )
  );
};

export default CustomizationRadioList;

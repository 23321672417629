import { TypeColWithNamePropertyPlatform } from '../../../../../models/datagrid';
import { getColumnOptions } from '../../../../../features/datagrid/DatagridFeature';
import i18next from 'i18next';
import { ActionBtn } from '../../../../../features/datagrid/columns';
import React from 'react';
import {
  Button,
  DropdownMenu,
  IconButton,
  Timeline,
  TimelineItem,
  Tooltip,
  TooltipContent
} from '@anatoscope/circlestorybook';
import productionStyles from '../../production-page.module.scss';
import designStyles from './design-page.module.scss';
import { ManufacturingOrder } from '../../../../../models/manufacturing-order';
import { WorkflowStepEnum } from '../../../../../enum/workflow-step';
import {
  assignManufacturingOrderAction,
  commentsOrderAction,
  deleteAction,
  editAction,
  toggleManufactureAction,
  unAssignManufacturingOrderAction
} from '../../../../../features/datagrid/menu-entry';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import { CircleCadVersionEnum } from '../../../../../enum/circle-cad-version';
import { useAppSelector } from '../../../../../store/hooks';
import { connectedUserSelector } from '../../../../../store/auth/auth.selectors';
import { useGetOneUserQuery } from '../../../../../services/users-api.services';
import { capitalizeFirstLetter } from '../../../../../utils/utils';
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons';
import { ColorPropsEnum } from '../../../../../enum/color.enum';
import { ManufacturingComponentView } from '../../../../../models/component';
import { isAutomaticModelisationStep } from '../../../../../utils/order.utils';

type TooltippedIconButton = {
  tooltip: string;
  faIconClass: string;
  onClick: (order: ManufacturingOrder) => void;
  radius?: string;
};

export const colCircleCadVersion: TypeColWithNamePropertyPlatform = {
  ...getColumnOptions(
    'circleCadVersion',
    i18next.t('datagrid.columns.circleCadVersion', { ns: 'production' })
  ),
  showColumnMenuTool: false,
  minWidth: 87,
  maxWidth: 87,
  filterEditor: SelectFilter,
  filterEditorProps: {
    dataSource: Object.values(CircleCadVersionEnum).map((version) => {
      return {
        id: version,
        label: version
      };
    })
  },
  cellDOMProps: () => ({
    style: {
      textAlign: 'left'
    }
  }),
  render: ({ value }) => {
    if (value) {
      return value.label;
    } else {
      return '-';
    }
  }
};

export const colAssignee = (
  handleClickAssign: (data: ManufacturingOrder | ManufacturingComponentView) => void
): TypeColWithNamePropertyPlatform => {
  return {
    ...getColumnOptions(
      'assigneeName',
      i18next.t('datagrid.columns.assignee', { ns: 'production' })
    ),
    showColumnMenuTool: false,
    defaultFlex: 1,
    cellDOMProps: () => ({
      style: {
        textAlign: 'left',
        textTransform: 'capitalize'
      }
    }),
    render: ({ data, value }: { data: ManufacturingOrder; value: string }) => {
      if (value) {
        return value;
      } else {
        return (
          <Button
            data-cy="datagrid-assign-button"
            label={i18next.t('datagrid.action.assign', { ns: 'production' })}
            onClick={() => handleClickAssign(data)}
            size="s"
            category="secondary"
            isDisabled={isAutomaticModelisationStep(data.currentStep)}
          />
        );
      }
    }
  };
};

const getCircleCadCompliantProducts = (order: ManufacturingOrder) => {
  return order.products?.filter((product) => product.isCompliantCircleCad);
};

export const colActions = (
  handleCircleCadAnalyze: (manufacturingOrder: ManufacturingOrder) => void,
  handleCircleCadLaunch: (manufacturingOrder: ManufacturingOrder) => void
): TypeColWithNamePropertyPlatform => {
  return {
    ...getColumnOptions(
      'modelingAction',
      i18next.t(`datagrid.columns.${WorkflowStepEnum.MODELING}`, { ns: 'production' }),
      3
    ),
    showColumnMenuTool: false,
    minWidth: 670,
    render: ({ data }: { data: ManufacturingOrder }) => {
      const connectedUser = useAppSelector(connectedUserSelector);
      const { data: user } = useGetOneUserQuery(connectedUser.email);
      const areActionsDisabled =
        [CircleCadVersionEnum.BETA, CircleCadVersionEnum.ALPHA].includes(
          data.circleCadVersion?.label
        ) && !user?.isAuthorizedCircleCADBeta;

      const button = getCircleCadActionButton(
        data,
        handleCircleCadAnalyze,
        handleCircleCadLaunch,
        areActionsDisabled
      );

      const steps: WorkflowStepEnum[] = [
        WorkflowStepEnum.MODELING_ANALYZE,
        WorkflowStepEnum.MODELING_PREPARE,
        WorkflowStepEnum.MODELING_REGISTER,
        WorkflowStepEnum.MODELING_DESIGN,
        WorkflowStepEnum.MODELING_EXPORT
      ];
      const stepsAuto: WorkflowStepEnum[] = [
        WorkflowStepEnum.MODELING_ANALYZE,
        WorkflowStepEnum.MODELING_REGISTER,
        WorkflowStepEnum.MODELING_EXPORT
      ];
      const isInProgress = stepsAuto.includes(data.currentStep);
      const stepIndex = steps.findIndex((step) => step === data.currentStep);
      return (
        <Timeline
          className={designStyles['design-page__timeline-design']}
          alignOtherChildrenLeft={true}>
          {steps.map((step: string, i: number) => {
            return (
              <TimelineItem
                key={`${data.id}-${step}`}
                data-key={`comp-${data.id}-${step}`}
                isInProgress={data.currentStep === step && isInProgress}
                isSuccess={data.currentStep !== step && i < stepIndex}
                isPending={data.currentStep === step && !isInProgress && i >= stepIndex}
                isFirstItem={i === 0}
                customDividerWidth={16}
                customLabelWidth={38}
                isInProgressIcon={faArrowsRotate}
                label={i18next.t(`datagrid.designStatus.${step.toLowerCase()}`, {
                  ns: 'production'
                })}
                data={data}
              />
            );
          })}
          <div className={designStyles['design-page__timeline-buttons']}>
            {user && button && (
              <Tooltip key={`tooltip`}>
                <TooltipContent>{button.tooltip}</TooltipContent>
                <Button
                  key={`btn__${button.label.replaceAll(' ', '_')}`}
                  label={button.label}
                  isDisabled={areActionsDisabled}
                  onClick={() => button.onClick(data)}
                  size="s"
                  data-cy={button['data-cy'] || ''}
                  iconLeft={button.iconLeft}
                />
              </Tooltip>
            )}
            {!button && (
              <Tooltip>
                <TooltipContent>
                  {i18next.t(`tooltip.steps.${data.currentStep}`, {
                    ns: 'production'
                  })}
                </TooltipContent>
                <p>{i18next.t(`order.steps.${data.currentStep}`, { ns: 'common' })}</p>
              </Tooltip>
            )}
          </div>
        </Timeline>
      );
    }
  };
};

export const colFileActions = (
  handleUploadCallback: (data: ManufacturingOrder) => void,
  handleDownloadCallback: (data: ManufacturingOrder) => void,
  isDownloadingFiles: boolean
): TypeColWithNamePropertyPlatform => {
  return {
    ...getColumnOptions(
      'fileAction',
      i18next.t('datagrid.columns.files', { ns: 'production' }),
      2,
      false
    ),
    showColumnMenuTool: false,
    minWidth: 100,
    maxWidth: 100,
    render: ({ data }: { data: ManufacturingOrder }) => {
      const iconButtons: TooltippedIconButton[] = getFileButtons(
        data,
        handleUploadCallback,
        handleDownloadCallback
      );
      return (
        <div className={designStyles['design-page__datagrid__column__status']}>
          {iconButtons?.map((iconButton) => {
            return (
              <Tooltip key={`tooltip__${iconButton.faIconClass}`}>
                <TooltipContent>{iconButton.tooltip}</TooltipContent>
                <IconButton
                  faIconClass={iconButton.faIconClass}
                  data-cy={iconButton.faIconClass}
                  onClick={() => iconButton.onClick(data)}
                  iconSize="xl"
                  radius="full"
                  color={ColorPropsEnum.PRIMARY}
                  isDisabled={isDownloadingFiles}
                  className={productionStyles['production-page__datagrid__icon-button']}
                />
              </Tooltip>
            );
          })}
        </div>
      );
    }
  };
};

export const colDesignMenu = (
  handleToggleManufactureCallback: (data: ManufacturingOrder) => Promise<void>,
  handleClickAssign: (data: ManufacturingOrder) => void,
  handleClickUnassign: (data: ManufacturingOrder) => Promise<void>,
  handleCommentCallback: (data: ManufacturingOrder) => void
): TypeColWithNamePropertyPlatform => {
  return {
    ...getColumnOptions('menu', '', 1, false),
    showColumnMenuTool: false,
    maxWidth: 60,
    render: ({ data }) => {
      return (
        <DropdownMenu
          renderTargetButton={({ active }: { active: boolean }) => (
            <IconButton
              data-cy="datagrid-menu"
              faIconClass="ellipsis-vertical"
              isActive={active}
              radius="full"
            />
          )}
          data={[
            [
              editAction,
              data && toggleManufactureAction(data, handleToggleManufactureCallback),
              !data.assigneeEmail && !data.assigneeName
                ? assignManufacturingOrderAction(data, handleClickAssign)
                : unAssignManufacturingOrderAction(data, handleClickUnassign),
              commentsOrderAction(data, handleCommentCallback)
            ],
            [deleteAction]
          ]}
        />
      );
    }
  };
};

const getFileButtons = (
  manufacturingOrder: ManufacturingOrder,
  handleUploadCallback: (data: ManufacturingOrder) => void,
  handleDownloadCallback: (data: ManufacturingOrder) => void
): TooltippedIconButton[] => {
  const circleCadCompliantProducts = getCircleCadCompliantProducts(manufacturingOrder);
  const areSomeProductsNonCircleCadCompliant =
    circleCadCompliantProducts?.length < manufacturingOrder.products?.length;
  const uploadIconBtn: TooltippedIconButton = {
    tooltip: i18next.t('tabs.design.upload_tooltip', { ns: 'production' }),
    faIconClass: 'upload',
    onClick: (manufacturingOrder: ManufacturingOrder) => {
      handleUploadCallback(manufacturingOrder);
    }
  };
  const downloadIconBtn: TooltippedIconButton = {
    tooltip: i18next.t('tabs.design.download_tooltip', { ns: 'production' }),
    faIconClass: 'download',
    onClick: (manufacturingOrder: ManufacturingOrder) => {
      handleDownloadCallback(manufacturingOrder);
    }
  };

  let iconButtons: TooltippedIconButton[] = [];
  if (
    areSomeProductsNonCircleCadCompliant &&
    ![
      WorkflowStepEnum.MODELING_ANALYZE as string,
      WorkflowStepEnum.MODELING_EXPORT as string
    ].includes(manufacturingOrder.currentStep)
  ) {
    // If there are some products in order that are CircleCAD compliant
    // User should be able to download patient files to design outside CircleCAD
    // But also to upload updated patient files to take other prostheses into account while designing with Circle CAD
    iconButtons = [downloadIconBtn, uploadIconBtn];
  }
  return iconButtons;
};

const getCircleCadActionButton = (
  manufacturingOrder: ManufacturingOrder,
  onAnalyzeButtonClick: (manufacturingOrder: ManufacturingOrder) => void,
  handleCircleCadLaunchBtnClick: (manufacturingOrder: ManufacturingOrder) => void,
  areActionsDisabled: boolean
): ActionBtn => {
  const prepareLabel = manufacturingOrder.streamingParameters
    ? 'tabs.design.circleCad_running'
    : 'tabs.design.prepare';
  const prepareBtn: ActionBtn = {
    label: i18next.t(prepareLabel, { ns: 'production' }),
    iconLeft: manufacturingOrder.streamingParameters ? 'fa-eye' : undefined,
    tooltip: areActionsDisabled
      ? i18next.t('tabs.design.disabled_actions.prepare_with_circleCad', {
          ns: 'production',
          version: capitalizeFirstLetter(manufacturingOrder.circleCadVersion?.label)
        })
      : i18next.t('tabs.design.actions.prepare_with_circleCad', { ns: 'production' }),
    onClick: (manufacturingOrder: ManufacturingOrder) => {
      handleCircleCadLaunchBtnClick(manufacturingOrder);
    },
    'data-cy': 'circleCadPrepare'
  };
  const designLabel = manufacturingOrder.streamingParameters
    ? 'tabs.design.circleCad_running'
    : 'tabs.design.design';
  const designBtn: ActionBtn = {
    label: i18next.t(designLabel, { ns: 'production' }),
    iconLeft: manufacturingOrder.streamingParameters ? 'fa-eye' : undefined,
    isLoading: false,
    tooltip: areActionsDisabled
      ? i18next.t('tabs.design.disabled_actions.design_with_circleCad', {
          ns: 'production',
          version: capitalizeFirstLetter(manufacturingOrder.circleCadVersion?.label)
        })
      : i18next.t('tabs.design.actions.design_with_circleCad', { ns: 'production' }),
    onClick: (manufacturingOrder: ManufacturingOrder) => {
      handleCircleCadLaunchBtnClick(manufacturingOrder);
    },
    'data-cy': 'circleCadDesign'
  };

  const analyzeBtn: ActionBtn = {
    label: i18next.t('datagrid.action.analyze', { ns: 'production' }),
    isLoading: false,
    tooltip: areActionsDisabled
      ? i18next.t('tabs.design.disabled_actions.analyze_with_circleCad', {
          ns: 'production',
          version: capitalizeFirstLetter(manufacturingOrder.circleCadVersion?.label)
        })
      : i18next.t('tabs.design.actions.analyze_with_circleCad', { ns: 'production' }),
    onClick: () => {
      onAnalyzeButtonClick(manufacturingOrder);
    },
    'data-cy': 'circleCadAnalyze'
  };

  const circleCadCompliantProducts = getCircleCadCompliantProducts(manufacturingOrder);

  // This will be reevaluated, because we don't know yet exactly which statuses were are going to receive
  switch (true) {
    // Current step is explicitly DESIGN
    case manufacturingOrder.currentStep === WorkflowStepEnum.MODELING_DESIGN:
      return designBtn;
    // Current step is explicitly PREPARE or we have no explicit substep but all products are CircleCAD compliant
    case manufacturingOrder.currentStep === WorkflowStepEnum.MODELING_PREPARE ||
      (manufacturingOrder.currentStep === WorkflowStepEnum.MODELING &&
        circleCadCompliantProducts?.length === manufacturingOrder.products?.length):
      return prepareBtn;
    case manufacturingOrder.currentStep === WorkflowStepEnum.MODELING &&
      circleCadCompliantProducts?.length < manufacturingOrder.products?.length:
      return analyzeBtn;
    default:
      return;
  }
};

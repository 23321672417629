import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NEW_CLINIC_ID } from '../../enum/establishment';
import { Establishment, EstablishmentReducer } from '../../models/establishment';

const initialEstablishment: EstablishmentReducer = {
  clinicList: []
};

const establishmentSlice = createSlice({
  name: 'establishment',
  initialState: initialEstablishment,
  reducers: {
    setClinicList: (state, action: PayloadAction<Array<Establishment>>) => {
      state.clinicList = action.payload;
    },
    addClinicToList: (state, action: PayloadAction<Establishment>) => {
      state.clinicList = [...state.clinicList, action.payload];
    },
    removeNewClinicInList: (state) => {
      state.clinicList = state.clinicList.filter((clinic) => clinic.id !== NEW_CLINIC_ID);
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<EstablishmentReducer>) => {
    builder.addCase('RESET_ALL', () => {
      return { ...initialEstablishment };
    });
  }
});

const establishmentActions = establishmentSlice.actions;

export { establishmentSlice, establishmentActions };

import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { TeethMap } from '@anatoscope/circlestorybook';
import styles from './print-order.module.scss';
import { Order, OrderItem } from '../../models/order';
import { DentalNotation } from '../../enum/user';
import { SortedItemsForPrint } from './print-order';
import {
  getFormattedLocalizedMomentDate,
  splitOrderNumber
} from '../order-manager/teeth-map/utils';
import { UserInfo } from '../../models/user';
import PrintProductList from './PrintProductList';
import { OrderItemCard } from '../order-item-card/OrderItemCard';
import { CardDisplayMode } from '../../enum/product.enum';

interface PrintOrderProps {
  order: Order;
  sortedItems: SortedItemsForPrint;
  connectedUser?: UserInfo;
  patientMouth?: { [key: string]: Array<number> | Array<Array<number>> };
  teethShades?: { [key: string]: Array<number> };
  existingTeethmap?: ReactElement;
}

const ComponentToPrint = React.forwardRef<HTMLDivElement, PrintOrderProps>((props, ref) => {
  const { t } = useTranslation(['print']);
  const { order, sortedItems, connectedUser, patientMouth, teethShades, existingTeethmap } = props;
  const splittedOrderNumber = order
    ? splitOrderNumber(order.orderNumber)
    : { beforeLastPart: '', lastPart: '' };

  return (
    <div ref={ref}>
      <div className={styles['print-order']}>
        <div className={styles['print-order__left']}>
          <div className={styles['print-order__left__topHalf']}>
            <div className={styles['print-order__header']}>
              <div className={styles['print-order__header__dentist']}>
                <div>{order?.dentistName}</div>
                <div>{order?.patient?.reference}</div>
              </div>
              <div className={styles['print-order__header__orderNumber']}>
                {splittedOrderNumber.beforeLastPart}
                <span className={styles['print-order__header__orderNumber__id']}>
                  {splittedOrderNumber.lastPart}
                </span>
              </div>
              <div className={styles['print-order__header__dates']}>
                {order?.expectedDate && (
                  <div className={styles['print-order__header__dates__expected']}>
                    {t('dates.expected', {
                      expectedDate: getFormattedLocalizedMomentDate('short', order.expectedDate)
                    })}
                  </div>
                )}
                <div className={styles['print-order__header__dates__departure']}>
                  {t('dates.departure')}
                  <span className={styles['print-order__header__dates__departure--bold']}>
                    {getFormattedLocalizedMomentDate('small', order?.limitShippingDate)}
                  </span>
                </div>
              </div>
            </div>
            <div className={styles['print-order__teethMapSection']}>
              <div className={styles['print-order__teethMapSection__products']}>
                <div className={styles['print-order__teethMapSection__products__section']}>
                  {order && sortedItems && <PrintProductList sortedItems={sortedItems} />}
                </div>
              </div>
              <div className={styles['print-order__teethMapSection__teethMap']}>
                {order?.items && (
                  <div className={styles['print-order__productCount']}>
                    {t('productCount.counter', { count: order?.items.length })}
                  </div>
                )}
                {!existingTeethmap && (
                  <TeethMap
                    patientMouth={patientMouth}
                    notation={connectedUser ? connectedUser?.dentalNotation : DentalNotation.ISO}
                    teethShades={teethShades}
                    displayShadows={false}
                  />
                )}
                {existingTeethmap}
              </div>
            </div>
          </div>

          <div className={styles['print-order__left__bottomHalf']}>
            <div className={styles['print-order__instructionSection']}>
              <div className={styles['print-order__instructionSection__title']}>
                {t('instructions')}
              </div>
              {order?.instructions ? (
                <div className={styles['print-order__instructionSection__content']}>
                  {order.instructions}
                </div>
              ) : (
                '-'
              )}
            </div>
          </div>
        </div>
        <div className={styles['print-order__right']}>
          <div className={styles['print-order__productsDetailsSection']}>
            {order?.items?.map((item: OrderItem) => {
              return (
                <OrderItemCard key={item.id} item={item} displayMode={CardDisplayMode.PRINT} />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
});
ComponentToPrint.displayName = 'ComponentToPrint';

const PrintOrder = React.forwardRef((props: PrintOrderProps, ref) => {
  return <ComponentToPrint ref={ref as React.Ref<HTMLDivElement>} {...props} />;
});
PrintOrder.displayName = 'PrintOrder';

export default PrintOrder;

import React, { useEffect, useState } from 'react';
import styles from './change-step-form.module.scss';
import { AttentionBox, Box, Button, RadioList, TextareaField } from '@anatoscope/circlestorybook';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { useTranslation } from 'react-i18next';
import { attentionBoxErrorSelector } from '../../../../store/feedback/feedback.selector';
import { getMessageError } from '../../../../utils/utils';
import {
  useAddCommentToManufacturingOrderMutation,
  usePatchManufacturingOrderMutation
} from '../../../../services/manufacturing-orders-api.services';
import { feedbackActions } from '../../../../store/feedback/feedback.reducer';
import i18next from '../../../../i18n';
import useForm from '../../../../utils/useForm';
import {
  useAddCommentToOrderMutation,
  usePatchOrderMutation
} from '../../../../services/orders-api.services';
import { Order } from '../../../../models/order';
import { WorkflowModelingStepEnum, WorkflowStepEnum } from '../../../../enum/workflow-step';
import { RadioListOptions } from '../../../../models/form';
import { ColorPropsEnum } from '../../../../enum/color.enum';
import { ToastType } from '../../../../enum/feedback';
import { ManufacturingOrder } from '../../../../models/manufacturing-order';

type props = {
  onSubmitChangeStepOrderCallback: () => void;
  orderNumber: string;
  currentStep: WorkflowStepEnum;
};

const invalidSteps: Array<WorkflowStepEnum> = [
  WorkflowModelingStepEnum.MODELING_ANALYZE,
  WorkflowModelingStepEnum.MODELING_REGISTER,
  WorkflowModelingStepEnum.MODELING_EXPORT
];

const ChangeStepForm = ({ onSubmitChangeStepOrderCallback, orderNumber, currentStep }: props) => {
  const { t } = useTranslation(['dashboard']);
  const dispatch = useAppDispatch();

  // Selectors
  const attentionBoxError = useAppSelector(attentionBoxErrorSelector);

  const getAllPreviousValidSteps = (): RadioListOptions[] => {
    const enumValues: WorkflowStepEnum[] = Object.values(WorkflowStepEnum);
    const availableOptions: RadioListOptions[] = [];
    const availableSteps = currentStep
      ? enumValues.filter(
          (enumValue: WorkflowStepEnum) =>
            enumValues.indexOf(enumValue) < enumValues.indexOf(currentStep) &&
            !invalidSteps.includes(enumValue)
        )
      : [];
    availableSteps.forEach((step) => {
      availableOptions.push({
        label:
          step === WorkflowStepEnum.SUBMITTING
            ? i18next.t(`order.steps.edition`, {
                ns: 'common'
              })
            : i18next.t(`order.steps.${step}`, {
                ns: 'common'
              }),
        value: `${step}`,
        isDisabled: false,
        helperText: i18next.t(`changeStepModal.helperText.${step}`, {
          ns: 'dashboard'
        })
      });
    });
    return availableOptions;
  };

  const availableSteps = getAllPreviousValidSteps();

  const [patchManufacturingOrder, patchManufacturingOrderState] =
    usePatchManufacturingOrderMutation();
  const [addCommentToOrder] = useAddCommentToOrderMutation();
  const [addCommentToManufacturingOrder] = useAddCommentToManufacturingOrderMutation();
  const [patchOrder, patchOrderState] = usePatchOrderMutation();
  const [currentPatchingData, setCurrentPatchingData] =
    useState<Partial<Order | ManufacturingOrder>>();

  useEffect(() => {
    // When patching data is updated, it means form was submitted
    // So we first patch the related manufacturingOrder
    if (currentPatchingData) {
      patchManufacturingOrder(currentPatchingData as unknown as Partial<ManufacturingOrder>);
      addCommentToManufacturingOrder({ orderNumber, comment: values.comment as string });
    }
  }, [currentPatchingData]);

  useEffect(() => {
    // And when manufacturingOrder is patched,
    // we then patch the related order
    if (patchManufacturingOrderState.isSuccess) {
      patchOrder(currentPatchingData as Order);
      addCommentToOrder({ orderNumber, comment: values.comment as string });
    }
  }, [patchManufacturingOrderState.isSuccess]);

  useEffect(() => {
    if (patchOrderState.isSuccess) {
      dispatch(
        feedbackActions.setToast({
          message: t('changeStepModal.success', { ns: 'dashboard' }),
          type: ToastType.SUCCESS
        })
      );
      onSubmitChangeStepOrderCallback();
    }
  }, [patchOrderState.isSuccess]);

  const onSubmit = () => {
    // When submitting, we put the patching data in a state, so we can use it to patch order & manu
    setCurrentPatchingData({
      orderNumber: orderNumber,
      currentStep: values.previousStep as WorkflowStepEnum
    });
  };

  const { values, errors, handleSubmit, handleSelect, handleBlur, handleChange } = useForm(
    {
      previousStep: availableSteps?.slice(-1)[0]?.value,
      comment: undefined
    },
    onSubmit
  );

  return (
    <>
      {patchManufacturingOrderState.error && attentionBoxError && (
        <AttentionBox
          mode={ColorPropsEnum.DANGER}
          text={getMessageError(patchManufacturingOrderState.error)}
          className={styles['change-step-form__attention-box']}
        />
      )}
      {patchOrderState.error && attentionBoxError && (
        <AttentionBox
          mode={ColorPropsEnum.DANGER}
          text={getMessageError(patchOrderState.error)}
          className={styles['change-step-form__attention-box']}
        />
      )}
      {availableSteps && (
        <Box color={ColorPropsEnum.WHITE}>
          <form onSubmit={handleSubmit} className={styles['change-step-form__form']}>
            <RadioList
              title={t('changeStepModal.label', { ns: 'dashboard' })}
              options={availableSteps}
              selectedValue={values?.previousStep}
              onClick={(newValue: string) => handleSelect(newValue, 'previousStep')}
            />
            <TextareaField
              data-cy="changeStepModalComment"
              id={'comment'}
              name="comment"
              label={t('changeStepModal.comment')}
              value={values.comment}
              onBlur={handleBlur}
              onChange={handleChange}
              helperText={errors?.comment ? t('changeStepModal.commentMandatory') : undefined}
              variant={errors.comment ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
            />
            <div className="form__submit-button form__submit-button--right">
              <Button
                label={t('changeStepModal.action', { ns: 'dashboard' })}
                isLoading={patchManufacturingOrderState.isLoading || patchOrderState.isLoading}
                type="submit"
              />
            </div>
          </form>
        </Box>
      )}
    </>
  );
};

export default ChangeStepForm;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import OrderForm from '../../../features/order-form/OrderForm';
import { ordersActions } from '../../../store/orders/orders.reducer';
import { useAppDispatch } from '../../../store/hooks';
import { Order, OrderItem } from '../../../models/order';
import { useGetOneOrderQuery } from '../../../services/orders-api.services';
import { mapActions } from '../../../store/map/map.reducer';

const EditOrderPage = () => {
  const { orderNumber } = useParams();
  const dispatch = useAppDispatch();

  const [isInitialisationDone, setIsInitialisationDone] = useState<boolean>(false);
  const { data: orderInfo, isLoading: isOrderLoading } = orderNumber
    ? useGetOneOrderQuery(orderNumber)
    : { data: undefined, isLoading: undefined };

  const [initialOrderInfo, setInitialOrderInfo] = useState<Order>();

  useEffect(() => {
    if (orderInfo && !isInitialisationDone) {
      setInitialOrderInfo(orderInfo);

      dispatch(mapActions.resetMap());
      // Initialize missing/toBeExtracted
      if (orderInfo?.patient?.diagnostic?.missingTeeth?.length > 0) {
        dispatch(mapActions.addMissingTeethToMap(orderInfo?.patient?.diagnostic?.missingTeeth));
      }

      if (orderInfo?.patient?.diagnostic?.teethToBeExtracted?.length > 0) {
        dispatch(
          mapActions.addExtractedTeethToMap(orderInfo?.patient?.diagnostic?.teethToBeExtracted)
        );
      }

      if (orderInfo?.items?.length > 0) {
        dispatch(mapActions.addItemsToMap(orderInfo.items as OrderItem[]));
      }

      // Initialize items
      dispatch(ordersActions.setItems(orderInfo?.items));
      dispatch(mapActions.computeZoneLinkPositions());

      // Initialize orderState
      dispatch(
        ordersActions.setOrder({
          dentistName: orderInfo.dentistName,
          dentistEmail: orderInfo.dentistEmail,
          clinicName: orderInfo.clinicName,
          clinicId: orderInfo.clinicId,
          toManufacture: orderInfo.toManufacture,
          expectedDate: orderInfo.expectedDate,
          instructions: orderInfo.instructions,
          labId: orderInfo.labId,
          labName: orderInfo.labName,
          patient: {
            diagnostic: orderInfo.patient.diagnostic,
            reference: orderInfo.patient.reference
          }
        })
      );
      setIsInitialisationDone(true);
    }
  }, [orderInfo]);

  return (
    <OrderForm
      initialOrderInfo={{ orderNumber, ...initialOrderInfo }}
      isOrderLoading={isOrderLoading}
    />
  );
};

export default EditOrderPage;

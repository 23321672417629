import React, { useEffect, useState } from 'react';
import { Box, BubblePicto, Button, RadioList, Text } from '@anatoscope/circlestorybook';
import { useTranslation } from 'react-i18next';
import styles from './add-product-modal.module.scss';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { ordersActions } from '../../../../store/orders/orders.reducer';
import {
  useGetAllProductsQuery,
  useLazyGetOneProductQuery
} from '../../../../services/products-api.services';
import { useGetCommonTypesQuery } from '../../../../services/common-types-api.services';
import { FullProduct, Product } from '../../../../models/product';
import { Family, ProductCategory, StumpMode } from '../../../../enum/product.enum';
import {
  addProductStepIndexSelector,
  currentItemSelector,
  orderItemsSelector,
  selectedCategoryValueSelector,
  selectedFamilyValueSelector
} from '../../../../store/orders/orders.selectors';
import { getLocalizedProperty, getMessageError } from '../../../../utils/utils';
import { feedbackActions } from '../../../../store/feedback/feedback.reducer';
import SkeletonList from '../../../skeleton-list/SkeletonList';
import ProductsManager, { MappedProducts } from '../../../order-manager/products-manager';
import { ToastType } from '../../../../enum/feedback';
import { ColorPropsEnum } from '../../../../enum/color.enum';
import useForm from '../../../../utils/useForm';
import {
  areMiddleMaxillaryTeethInCurrentItem,
  buildItemComponentCustomizationValue,
  getCustomizationAttribute,
  getMappings,
  getStratificationTechniqueAttribute
} from './utils';
import {
  ComponentType,
  ComponentTypeProperties,
  GingivaShadeEnum,
  MaterialEnum,
  PositionKey,
  ToothShadeEnum,
  ToothShapeEnum
} from '../../../../enum/component';
import { activeItemSelector, mapComponentsSelector } from '../../../../store/map/map.selectors';
import { OrderItemComponentLight } from '../../../../models/order';
import { mapActions } from '../../../../store/map/map.reducer';
import { RadioListOptions } from '../../../../models/form';
import { StringObject, UnknownObject } from '../../../../models/common';
import CustomizationDropdown from './customization-dropdown.tsx/CustomizationDropdown';
import { isCategoryProvisional } from '../../utils';
import { productCustomizationActions } from '../../../../store/product-customization/productCustomization.reducer';
import CustomizationRadioList from './customization-radiolist.tsx/CustomizationRadioList';
import {
  Angulation,
  Aspect,
  Material,
  Shade,
  Shape,
  Structure
} from '../../../../models/common-types';

type Props = {
  onCancelCallback: () => void;
  onCloseCallback: () => void;
};

const AddProductModal = ({ onCloseCallback, onCancelCallback }: Props) => {
  const { t } = useTranslation(['order']);
  const dispatch = useAppDispatch();

  const orderItems = useAppSelector(orderItemsSelector);

  const {
    data: products,
    isSuccess: isSuccessGetProducts,
    isLoading: isLoadingGetProducts
  } = useGetAllProductsQuery();
  const {
    data: commonTypes,
    isLoading: areCommonTypesLoading,
    isSuccess: isSuccessGetCommonTypes
  } = useGetCommonTypesQuery();
  const [getOneProduct] = useLazyGetOneProductQuery();

  const currentItem = useAppSelector(currentItemSelector);
  const currentProduct = currentItem?.product;
  const addProductStepIndex = useAppSelector(addProductStepIndexSelector);
  const selectedFamilyValue = useAppSelector(selectedFamilyValueSelector);
  const selectedCategoryValue = useAppSelector(selectedCategoryValueSelector);
  const mapComponents = useAppSelector(mapComponentsSelector);
  const activeMapItem = useAppSelector(activeItemSelector);

  const [mappedProducts, setMappedProducts] = useState<MappedProducts>({});
  const [families, setFamilies] = useState<Family[]>([]);
  const [categories, setCategories] = useState<ProductCategory[]>([]);
  const [productsManager] = useState<ProductsManager>(() => new ProductsManager());

  const familiesObjectEnum: { [key in keyof typeof Family]: Family } = commonTypes?.families;
  const categoriesObjectEnum: { [key in keyof typeof ProductCategory]: ProductCategory } =
    commonTypes?.productCategories;

  const handleClickFamilyRadio = (value: Family): void => {
    dispatch(ordersActions.setSelectedFamilyValue(value));
    dispatch(
      ordersActions.setSelectedCategoryValue(
        productsManager.getFirstCategoryFromFamilyKey(value) as ProductCategory
      )
    );
  };

  const handleClickCategoryRadio = (value: ProductCategory): void => {
    dispatch(ordersActions.setSelectedCategoryValue(value));
  };

  if (isSuccessGetProducts && isSuccessGetCommonTypes && !families.length && !categories.length) {
    setFamilies(familiesObjectEnum ? Object.values(familiesObjectEnum) : []);
    setCategories(categoriesObjectEnum ? Object.values(categoriesObjectEnum) : []);
  }

  useEffect((): void => {
    if (families.length && categories.length && products?.data.length > 0) {
      productsManager.init(families, categories, products.data);
      setMappedProducts(productsManager.mappedProducts);
      if (!selectedFamilyValue) {
        dispatch(ordersActions.setSelectedFamilyValue(productsManager.firstFamilyKey as Family));
      }

      if (!selectedCategoryValue) {
        dispatch(ordersActions.setSelectedCategoryValue(productsManager.firstCategoryKey));
      }
    }
  }, [categories, families]);

  const optionsFamilyRadioButton = (): Array<RadioListOptions> => {
    if (familiesObjectEnum && Object.values(familiesObjectEnum).length) {
      return Object.values(familiesObjectEnum)
        .map((family) => {
          if (Object.keys(mappedProducts).includes(family)) {
            return {
              label: t(`families.${family}`, { ns: 'catalog' }),
              value: family
            };
          } else {
            return null;
          }
        })
        .filter((item) => item !== null);
    } else {
      return [];
    }
  };

  const optionsCategoryRadioButton = (): Array<RadioListOptions> => {
    if (
      mappedProducts &&
      selectedFamilyValue in mappedProducts &&
      Object.values(mappedProducts[selectedFamilyValue]).length &&
      categoriesObjectEnum
    ) {
      return Object.values(categoriesObjectEnum)
        ?.map((category) => {
          if (Object.keys(mappedProducts[selectedFamilyValue]).includes(category)) {
            return {
              label: t(`categories.${category}`, { ns: 'catalog' }),
              value: category
            };
          } else {
            return null;
          }
        })
        .filter((item) => item !== null);
    }
    return [];
  };

  const resetStates = (): void => {
    resetValues();
    dispatch(mapActions.removeActiveProduct());
    dispatch(mapActions.resetSelectionTeeth());
    dispatch(productCustomizationActions.resetProductCustomization());
    dispatch(mapActions.resetMapContext());
    dispatch(ordersActions.setCurrentItem(undefined));
    dispatch(ordersActions.setAddProductStepIndex(1));
    dispatch(ordersActions.setSelectedFamilyValue(productsManager.firstFamilyKey as Family));
    dispatch(ordersActions.setSelectedCategoryValue(productsManager.firstCategoryKey));
    dispatch(ordersActions.setError(undefined));
  };

  const onProductBubbleClick = (product: Product): void => {
    resetStates();
    dispatch(ordersActions.setAddProductStepIndex(2));
    getOneProduct(product.id)
      .unwrap()
      .then((response) => {
        const itemComponents = response?.components
          ?.map((component) => component.componentType)
          .map((componentType) => ({ componentType }));
        dispatch(ordersActions.setCurrentItem({ product: response, itemComponents }));
      })
      .catch((error) => {
        dispatch(
          feedbackActions.setToast({
            message: getMessageError(error),
            type: ToastType.DANGER
          })
        );
      });
  };

  const canBeSubmitted = () => {
    // todo : this won't work with Inlay-Core but it will be handled when it's implemented
    if (activeMapItem.TOOTH.length === 0) {
      dispatch(
        ordersActions.setError(
          t(`createOrder.prosthesis.${currentItem.product.teethMode.toLowerCase()}`)
        )
      );
      return false;
    }

    // 1 - check min teeth
    const minTeeth = currentItem.product.components.find(
      (component) => component.componentType === ComponentType.TOOTH
    )?.rule.min;
    if (activeMapItem.TOOTH.length < minTeeth) {
      dispatch(ordersActions.setError(t('createOrder.error.minTeeth', { count: minTeeth })));
      return false;
    }

    if (currentItem?.itemComponents?.length === 0) {
      return false;
    }

    return true;
  };

  const buildItemComponentsToSave = () => {
    const itemComponents: OrderItemComponentLight[] = [...currentItem.itemComponents];

    const stumpPositions =
      currentItem?.product?.stumpMode === StumpMode.ALWAYS
        ? (activeMapItem.TOOTH as PositionKey[])
        : undefined;

    Object.keys(values).forEach((customizationKey) => {
      if (values[customizationKey]) {
        const mappings = getMappings(customizationKey); // components types, key name in product items, and key name to write
        const customizationAttribute =
          mappings.componentTypeProperty === ComponentTypeProperties.TOOTH_STRATIFICATION_TECHNIQUES
            ? getStratificationTechniqueAttribute(
                currentItem.product,
                mappings.componentType,
                values[customizationKey].toString()
              )
            : getCustomizationAttribute(
                currentItem.product,
                mappings.componentType,
                mappings.componentTypeProperty,
                +values[customizationKey]
              ); // Shade attribute value, material attribute value
        itemComponents.forEach((component, index) => {
          if (component.componentType === mappings.componentType) {
            if (
              mappings.componentTypeProperty ===
              ComponentTypeProperties.TOOTH_STRATIFICATION_TECHNIQUES
            ) {
              itemComponents[index] = {
                ...component,
                ...{ [mappings.itemComponentProperty]: values[customizationKey].toString() }
              };
            } else {
              itemComponents[index] = {
                ...component,
                ...buildItemComponentCustomizationValue(
                  mappings.itemComponentProperty,
                  +values[customizationKey],
                  (
                    customizationAttribute as
                      | Shade
                      | Shape
                      | Structure
                      | Angulation
                      | Aspect
                      | Material
                  )?.code
                )
              };
            }
          }
        });
      }
    });

    return itemComponents
      ?.filter(
        (component) =>
          // Do not keep optional frame => frame with material === -1
          !(component.componentType === ComponentType.FRAME && component.material?.id === -1)
      )
      .map((component) => {
        return {
          ...component,
          teethPositions: activeMapItem.TOOTH as PositionKey[],
          stumpPositions
        };
      });
  };

  const isValueEmpty = (values: UnknownObject, valueKey: string, product: FullProduct): boolean => {
    const componentProperties = getMappings(valueKey);

    const component = product.components.find(
      (component) => component.componentType === componentProperties.componentType
    );
    if (valueKey === 'teethShape') {
      return (
        areMiddleMaxillaryTeethInCurrentItem(activeMapItem.TOOTH) &&
        component &&
        component[componentProperties.componentTypeProperty as ComponentTypeProperties]?.length &&
        !values[valueKey]
      );
    }

    return (
      component &&
      component[componentProperties.componentTypeProperty as ComponentTypeProperties]?.length &&
      !values[valueKey]
    );
  };

  const onSubmitCallback = (): void => {
    // 1 - some checks
    if (!canBeSubmitted()) {
      return;
    }

    // 2 - save data in map reducer
    const counter = orderItems.filter((orderItem) => {
      return orderItem?.product?.id === currentItem.product.id;
    }).length;
    dispatch(
      mapActions.commitProductToMap({
        productId: currentItem.product.id,
        uniqueProductId: currentItem.product.id + '_' + counter,
        teethMode: currentItem.product.teethMode
      })
    );

    // 3 - save items and diagnostic in order reducer
    const itemToSave = { ...currentItem, itemComponents: buildItemComponentsToSave() };
    dispatch(ordersActions.pushItem(itemToSave));
    dispatch(
      ordersActions.setDiagnostic({
        teethToBeExtracted: mapComponents.EXTRACT,
        missingTeeth: mapComponents.MISSING,
        naturalStumps: mapComponents.STUMP.flat()
      })
    );

    resetStates();
    onCloseCallback();
  };

  const handleCancelClick = (): void => {
    resetStates();
    onCancelCallback();
  };

  const handleShadeDropdownChange = (
    id: number,
    target: 'teethShade' | 'inlayShade' | 'onlayShade' | 'overlayShade'
  ): void => {
    handleSelect(id, target);
    // dispatch shade on map
    const shade = commonTypes.shades.find((shade) => shade.id === id)?.code;

    // TODO: link the map action to the customization state with a useEffect
    dispatch(
      mapActions.setActiveProductShade({
        shade: shade as ToothShadeEnum,
        target
      })
    );
    dispatch(
      productCustomizationActions.setShade({
        shade: shade as ToothShadeEnum,
        target
      })
    );
  };

  useEffect((): void => {
    if (currentProduct) {
      const componentTooth = currentProduct.components.find(
        (component) => component.componentType === ComponentType.TOOTH
      );
      dispatch(
        mapActions.initMapContext({
          productId: currentProduct.id,
          teethMode: currentProduct.teethMode,
          teethComponentRule: componentTooth?.rule,
          productRule: currentProduct.productRule
        })
      );
      dispatch(mapActions.initSelectionTooth());
    }
  }, [currentProduct]);

  const toothComponent = currentItem?.product?.components.find(
    (component) => component.componentType === ComponentType.TOOTH
  );

  const inlayComponent = currentItem?.product?.components.find(
    (component) => component.componentType === ComponentType.INLAY
  );

  const onlayComponent = currentItem?.product?.components.find(
    (component) => component.componentType === ComponentType.ONLAY
  );

  const overlayComponent = currentItem?.product?.components.find(
    (component) => component.componentType === ComponentType.OVERLAY
  );

  const frameComponent = currentItem?.product?.components.find(
    (component) => component.componentType === ComponentType.FRAME
  );

  const gingivaComponent = currentItem?.product?.components.find(
    (component) => component.componentType === ComponentType.GINGIVA
  );

  const { values, errors, handleSelect, handleSubmit, resetValues } = useForm(
    {
      teethShade: undefined,
      teethShape: undefined,
      teethMaterial: undefined,
      gingivaShade: undefined,
      gingivaMaterial: undefined,
      gingivaAspect: undefined,
      frameMaterial: undefined,
      inlayShade: undefined,
      onlayShade: undefined,
      overlayShade: undefined,
      inlayMaterial: undefined,
      onlayMaterial: undefined,
      overlayMaterial: undefined,
      toothStratificationTechnique: undefined
    },
    onSubmitCallback,
    () => {
      const newErrors: StringObject = {};

      // teethShape is mandatory only if areMiddleMaxillaryTeethInCurrentItem
      // otherwise, all customization data is mandatory
      Object.keys(values).forEach((key) => {
        if (isValueEmpty(values, key, currentItem.product)) {
          newErrors[key] = 'empty';
        }
      });
      return newErrors;
    }
  );

  return (
    <>
      {addProductStepIndex === 2 && (
        <Button
          label={t('createOrder.addProductModal.customization.back')}
          category="tertiary"
          iconLeft="fa-chevron-left"
          size="s"
          onClick={() => dispatch(ordersActions.setAddProductStepIndex(1))}
        />
      )}
      <Box color={ColorPropsEnum.WHITE} className={styles['add-product-modal']}>
        <form onSubmit={handleSubmit} className={styles['add-product-modal__form']}>
          {addProductStepIndex === 1 && (
            <div className={styles['add-product-modal__form__step1']}>
              {isLoadingGetProducts ? (
                <SkeletonList
                  className={styles['add-product-modal__form__step1__skeleton']}
                  heightItem="30px"
                  widthItem="300px"
                  count={6}></SkeletonList>
              ) : (
                <>
                  {products.data.length === 0 && (
                    <Text label={t('createOrder.addProductModal.product.empty')}></Text>
                  )}
                  {products.data.length > 0 && (
                    <>
                      <Text
                        label={t('createOrder.addProductModal.product.title')}
                        size="s"
                        type="title"
                        className={styles['add-product-modal__form__step1__title']}></Text>
                      <div>
                        {!areCommonTypesLoading && (
                          <>
                            <RadioList
                              data-cy="radio-family"
                              title={t('createOrder.addProductModal.product.family')}
                              onClick={handleClickFamilyRadio}
                              options={optionsFamilyRadioButton()}
                              displayedInRow={true}
                              selectedValue={selectedFamilyValue}
                              className={styles['add-product-modal__form__step1__radio']}
                            />
                            {optionsCategoryRadioButton().length > 0 && (
                              <RadioList
                                data-cy="radio-category"
                                title={t('createOrder.addProductModal.product.category')}
                                onClick={handleClickCategoryRadio}
                                options={optionsCategoryRadioButton()}
                                displayedInRow={true}
                                selectedValue={selectedCategoryValue}
                                className={styles['add-product-modal__form__step1__radio']}
                              />
                            )}
                          </>
                        )}
                        <div className={styles['add-product-modal__form__step1__products']}>
                          <Text
                            label={t('createOrder.addProductModal.product.products')}
                            className={styles['add-product-modal__form__step1__products__label']}
                            size="s"></Text>
                          <div className={styles['add-product-modal__form__step1__products__list']}>
                            {Boolean(Object.values(mappedProducts).length) &&
                              selectedCategoryValue &&
                              mappedProducts[selectedFamilyValue][selectedCategoryValue].map(
                                (product) => (
                                  <div
                                    className={
                                      styles[
                                        'add-product-modal__form__step1__products__list__product'
                                      ]
                                    }
                                    key={product.id}>
                                    <BubblePicto
                                      isClickable
                                      isDashedBorder={isCategoryProvisional(product.category)}
                                      onClick={() => onProductBubbleClick(product)}
                                      size="large"
                                      color={
                                        ColorPropsEnum[
                                          `FAMILY_${selectedFamilyValue.toUpperCase()}` as keyof typeof ColorPropsEnum
                                        ]
                                      }
                                      mainTitle={
                                        product[getLocalizedProperty('label') as keyof Product]
                                      }
                                      mainTitleSize="s"
                                      displaysBorder={product.id === currentItem?.product?.id}
                                      url={product.imageUrl}
                                      data-cy="product-bubble"
                                    />
                                  </div>
                                )
                              )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          )}
          {addProductStepIndex === 2 && (
            <div className={styles['add-product-modal__form__step2']}>
              <Text
                label={t('createOrder.addProductModal.customization.title')}
                size="s"
                type="title"
                className={styles['add-product-modal__form__step2__title']}></Text>

              <Box
                color={ColorPropsEnum.GREY}
                className={styles['add-product-modal__form__step2__product']}
                padding="spacing-2">
                {currentItem && (
                  <>
                    <BubblePicto
                      size="small"
                      isDashedBorder={isCategoryProvisional(currentItem.product.category)}
                      color={
                        ColorPropsEnum[
                          `FAMILY_${currentItem.product.family.toUpperCase()}` as keyof typeof ColorPropsEnum
                        ]
                      }
                      url={currentItem.product.imageUrl}
                    />
                    <Text
                      label={currentItem.product[getLocalizedProperty('label') as keyof Product]}
                    />
                  </>
                )}
              </Box>
              {currentItem?.product && !areCommonTypesLoading && (
                <div className={styles['add-product-modal__form__step2__product__customization']}>
                  {frameComponent?.materials?.length > 0 && (
                    <CustomizationRadioList
                      data-cy="frameMaterial"
                      component={frameComponent}
                      customizationKey="frameMaterial"
                      customizationTarget={ComponentTypeProperties.MATERIALS}
                      onClick={(id: string) => {
                        handleSelect(id, 'frameMaterial');
                        // dispatch frame material on map
                        const material = commonTypes.materials.find(
                          (material) => material.id.toString() === id
                        )?.code;
                        dispatch(
                          mapActions.setActiveProductFrameMaterial(material as MaterialEnum)
                        );
                        dispatch(
                          productCustomizationActions.setFrameMaterial(material as MaterialEnum)
                        );
                      }}
                      selectedValue={values.frameMaterial as string | undefined}
                      className={styles['add-product-modal__form__step2__radio']}
                      handleSelect={handleSelect}
                    />
                  )}
                  {toothComponent?.shades?.length > 0 && (
                    <CustomizationDropdown
                      component={currentItem.product?.components.find(
                        (component) => component.componentType === ComponentType.TOOTH
                      )}
                      customizationTarget={ComponentTypeProperties.SHADES}
                      value={values.teethShade as string | undefined}
                      error={errors?.teethShade}
                      onChange={(id: number) => {
                        handleShadeDropdownChange(id, 'teethShade');
                      }}
                      customizationKey="teethShade"
                      handleSelect={handleSelect}
                    />
                  )}
                  {inlayComponent?.shades?.length > 0 && (
                    <CustomizationDropdown
                      component={currentItem.product?.components.find(
                        (component) => component.componentType === ComponentType.INLAY
                      )}
                      customizationTarget={ComponentTypeProperties.SHADES}
                      value={values.inlayShade as string | undefined}
                      error={errors?.inlayShade}
                      onChange={(id: number) => {
                        handleShadeDropdownChange(id, 'inlayShade');
                      }}
                      customizationKey="inlayShade"
                      handleSelect={handleSelect}
                    />
                  )}
                  {onlayComponent?.shades?.length > 0 && (
                    <CustomizationDropdown
                      component={currentItem.product?.components.find(
                        (component) => component.componentType === ComponentType.ONLAY
                      )}
                      customizationTarget={ComponentTypeProperties.SHADES}
                      value={values.onlayShade as string | undefined}
                      error={errors?.onlayShade}
                      onChange={(id: number) => {
                        handleShadeDropdownChange(id, 'onlayShade');
                      }}
                      customizationKey="onlayShade"
                      handleSelect={handleSelect}
                    />
                  )}
                  {overlayComponent?.shades?.length > 0 && (
                    <CustomizationDropdown
                      component={currentItem.product?.components.find(
                        (component) => component.componentType === ComponentType.OVERLAY
                      )}
                      customizationTarget={ComponentTypeProperties.SHADES}
                      value={values.overlayShade as string | undefined}
                      error={errors?.overlayShade}
                      onChange={(id: number) => {
                        handleShadeDropdownChange(id, 'overlayShade');
                      }}
                      customizationKey="overlayShade"
                      handleSelect={handleSelect}
                    />
                  )}
                  {(toothComponent?.shapes?.length > 0 ||
                    onlayComponent?.shapes?.length > 0 ||
                    overlayComponent?.shapes?.length > 0 ||
                    inlayComponent?.shapes?.length > 0) &&
                    areMiddleMaxillaryTeethInCurrentItem(activeMapItem.TOOTH) && (
                      <CustomizationDropdown
                        component={currentItem.product?.components.find((component) =>
                          [
                            ComponentType.OVERLAY,
                            ComponentType.ONLAY,
                            ComponentType.INLAY,
                            ComponentType.TOOTH
                          ].includes(component.componentType)
                        )}
                        customizationTarget={ComponentTypeProperties.SHAPES}
                        value={values.teethShape as string | undefined}
                        error={errors?.teethShape}
                        onChange={(id: number) => {
                          handleSelect(id, 'teethShape');
                          const shape = commonTypes.shapes.find((shape) => shape.id === id)?.code;
                          dispatch(mapActions.setActiveProductShape(shape as ToothShapeEnum));
                        }}
                        customizationKey="teethShape"
                        handleSelect={handleSelect}
                      />
                    )}
                  {toothComponent?.materials?.length > 0 && (
                    <CustomizationDropdown
                      component={currentItem.product?.components.find(
                        (component) => component.componentType === ComponentType.TOOTH
                      )}
                      customizationTarget={ComponentTypeProperties.MATERIALS}
                      value={values.teethMaterial as string | undefined}
                      error={errors?.teethMaterial}
                      customizationKey="teethMaterial"
                      onChange={(id: number) => {
                        handleSelect(id, 'teethMaterial');
                      }}
                      handleSelect={handleSelect}
                    />
                  )}
                  {inlayComponent?.materials?.length > 0 && (
                    <CustomizationDropdown
                      component={currentItem.product?.components.find(
                        (component) => component.componentType === ComponentType.INLAY
                      )}
                      customizationTarget={ComponentTypeProperties.MATERIALS}
                      value={values.inlayMaterial as string | undefined}
                      error={errors?.inlayMaterial}
                      customizationKey="inlayMaterial"
                      onChange={(id: number) => {
                        handleSelect(id, 'inlayMaterial');
                      }}
                      handleSelect={handleSelect}
                    />
                  )}
                  {onlayComponent?.materials?.length > 0 && (
                    <CustomizationDropdown
                      component={currentItem.product?.components.find(
                        (component) => component.componentType === ComponentType.ONLAY
                      )}
                      customizationTarget={ComponentTypeProperties.MATERIALS}
                      value={values.onlayMaterial as string | undefined}
                      error={errors?.onlayMaterial}
                      customizationKey="onlayMaterial"
                      onChange={(id: number) => {
                        handleSelect(id, 'onlayMaterial');
                      }}
                      handleSelect={handleSelect}
                    />
                  )}
                  {overlayComponent?.materials?.length > 0 && (
                    <CustomizationDropdown
                      component={currentItem.product?.components.find(
                        (component) => component.componentType === ComponentType.OVERLAY
                      )}
                      customizationTarget={ComponentTypeProperties.MATERIALS}
                      value={values.overlayMaterial as string | undefined}
                      error={errors?.overlayMaterial}
                      customizationKey="overlayMaterial"
                      onChange={(id: number) => {
                        handleSelect(id, 'overlayMaterial');
                      }}
                      handleSelect={handleSelect}
                    />
                  )}
                  {toothComponent?.toothStratificationTechniques?.length > 0 && (
                    <CustomizationRadioList
                      customizationKey="toothStratificationTechnique"
                      component={toothComponent}
                      customizationTarget={ComponentTypeProperties.TOOTH_STRATIFICATION_TECHNIQUES}
                      selectedValue={values.toothStratificationTechnique as string | undefined}
                      onClick={(id: string) => {
                        handleSelect(id, 'toothStratificationTechnique');
                      }}
                      handleSelect={handleSelect}
                    />
                  )}
                  {gingivaComponent?.materials?.length > 0 && (
                    <CustomizationDropdown
                      component={currentItem.product?.components.find(
                        (component) => component.componentType === ComponentType.GINGIVA
                      )}
                      customizationTarget={ComponentTypeProperties.MATERIALS}
                      value={values.gingivaMaterial as string | undefined}
                      error={errors?.gingivaMaterial}
                      customizationKey="gingivaMaterial"
                      onChange={(id: number) => {
                        handleSelect(id, 'gingivaMaterial');
                      }}
                      handleSelect={handleSelect}
                    />
                  )}
                  {gingivaComponent?.aspects?.length > 0 && (
                    <CustomizationRadioList
                      customizationKey="gingivaAspect"
                      customizationTarget={ComponentTypeProperties.ASPECTS}
                      component={gingivaComponent}
                      selectedValue={values.gingivaAspect as string | undefined}
                      onClick={(id: string) => {
                        handleSelect(id, 'gingivaAspect');
                      }}
                      handleSelect={handleSelect}
                    />
                  )}
                  {gingivaComponent?.shades?.length > 0 && (
                    <CustomizationDropdown
                      component={currentItem.product?.components.find(
                        (component) => component.componentType === ComponentType.GINGIVA
                      )}
                      customizationTarget={ComponentTypeProperties.SHADES}
                      value={values.gingivaShade as string | undefined}
                      error={errors?.gingivaShade}
                      onChange={(id: number) => {
                        handleSelect(id, 'gingivaShade');
                        // dispatch gingiva on map
                        const gingiva = commonTypes.shades.find(
                          (gingiva) => gingiva.id === id
                        )?.code;
                        dispatch(mapActions.setActiveProductGingiva(gingiva as GingivaShadeEnum));
                        dispatch(
                          productCustomizationActions.setGingivaShade(gingiva as GingivaShadeEnum)
                        );
                      }}
                      customizationKey="gingivaShade"
                      handleSelect={handleSelect}
                    />
                  )}
                </div>
              )}
            </div>
          )}

          <div className="form__submit-button form__submit-button--double">
            <Button
              label={t('action.cancel', { ns: 'common' })}
              category="secondary"
              iconLeft="fa-trash"
              onClick={() => handleCancelClick()}
            />
            <Button
              data-cy="add-product-button"
              label={t('createOrder.addProductModal.customization.button')}
              type="submit"
              category="primary"
              variant="success"
              iconLeft="fa-check"
              isDisabled={!currentItem?.product}
            />
          </div>
        </form>
      </Box>
    </>
  );
};
export default AddProductModal;

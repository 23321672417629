// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,400;0,700;1,100;1,400;1,700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.public-app {
  display: flex;
  justify-content: center;
  min-height: 100dvh;
  align-items: center;
  background: linear-gradient(135deg, #6200ee, #14012f);
}`, "",{"version":3,"sources":["webpack://./src/pages/public/public-app.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,uBAAA;EACA,kBAAA;EACA,mBAAA;EACA,qDAAA;AAAF","sourcesContent":["@use '@anatoscope/circlestorybook/dist/vars';\n\n.public-app {\n  display: flex;\n  justify-content: center;\n  min-height: 100dvh;\n  align-items: center;\n  background: linear-gradient(135deg, vars.$primary-color, vars.$black);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

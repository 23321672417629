import { DatagridsSettingsReducer } from '../../models/datagrid';

interface ReducerState {
  datagridsSettingsState: DatagridsSettingsReducer;
}

export const usersDatagridStateSelector = (state: ReducerState) =>
  state.datagridsSettingsState.users;

export const supervisionDatagridStateSelector = (state: ReducerState) =>
  state.datagridsSettingsState.supervision;

export const validationDatagridStateSelector = (state: ReducerState) =>
  state.datagridsSettingsState.validation;

export const designDatagridStateSelector = (state: ReducerState) =>
  state.datagridsSettingsState.design;

export const manufacturingDatagridStateSelector = (state: ReducerState) =>
  state.datagridsSettingsState.manufacturing;

export const shippingDatagridStateSelector = (state: ReducerState) =>
  state.datagridsSettingsState.shipping;

export const controlDatagridStateSelector = (state: ReducerState) =>
  state.datagridsSettingsState.control;

import { ProductCustomizationReducer } from '../../models/product-customization';

interface ReducerState {
  productCustomizationState: ProductCustomizationReducer;
}

export const productCustomizationSelector = (state: ReducerState) => {
  return state?.productCustomizationState;
};

export const activeTeethShadeSelector = (state: ReducerState) => {
  return state?.productCustomizationState.teethShade;
};

export const activeFrameMaterialSelector = (state: ReducerState) => {
  return state?.productCustomizationState.frameMaterial;
};

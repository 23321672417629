import { api } from './api';
import { DentistInfos, DentistListResponse, LabUser, NewDentist, UserInfo } from '../models/user';
import { mapDentists, mapUser } from '../pages/private/dentist-page/dentist';
import { capitalizeFirstLetter } from '../utils/utils';
import { DentistRole, LabUserRole } from '../enum/user';

interface DentistsFormattedList {
  data: DentistInfos[];
  count: number;
}

export const usersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createDentist: builder.mutation<DentistInfos, NewDentist>({
      query: (payload) => ({
        url: '/users',
        method: 'POST',
        body: payload
      }),
      invalidatesTags: ['Dentists']
    }),
    patchDentist: builder.mutation<DentistInfos, Partial<NewDentist>>({
      query: ({ email, ...patch }) => ({
        url: `/users/${email}`,
        method: 'PATCH',
        body: patch
      }),
      invalidatesTags: [{ type: 'Dentists', id: 'PARTIAL-LIST' }, 'User']
    }),
    getAllDentists: builder.query<DentistsFormattedList, void>({
      query: () => {
        return `/users?page=1&limit=0&filter.role=$in:${DentistRole.DENTIST},${DentistRole.ASSISTANT}&filter.deletedAt=$null`;
      },
      transformResponse: (response: DentistListResponse) => {
        return { data: mapDentists(response.data), count: response.meta.totalItems };
      }
    }),
    getPaginatedDentists: builder.query({
      query: (arg) => {
        const { page, limit, filters, sort } = arg;
        return `/users?page=${page}&limit=${limit}${filters}${sort}`;
      },
      transformResponse: (response: DentistListResponse) => {
        return { data: mapDentists(response.data), count: response.meta.totalItems };
      },
      providesTags: (response) => {
        return response
          ? [
              // Provides a tag for each post in the current page,
              // as well as the 'PARTIAL-LIST' tag.
              ...response.data.map(({ id }) => ({
                type: 'Dentists' as const,
                id
              })),
              { type: 'Dentists', id: 'PARTIAL-LIST' }
            ]
          : [{ type: 'Dentists', id: 'PARTIAL-LIST' }];
      }
    }),
    resetPassword: builder.mutation<UserInfo, string>({
      query: (email) => ({
        url: `/users/${email}/reset-password`,
        method: 'POST'
      })
    }),
    deactivateDentist: builder.mutation<DentistInfos, string>({
      query: (email) => ({
        url: `/users/${email}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['User']
    }),
    getLabUsers: builder.query<LabUser[], void>({
      query: () =>
        `/users?limit=0&filter.role=$in:${LabUserRole.LAB_DESIGNER},${LabUserRole.SUPERVISOR},${LabUserRole.PROSTHETIST},${LabUserRole.TECHNICIAN}&filter.deletedAt=$null`,
      providesTags: ['LabUsers'],
      transformResponse: (response: { data: LabUser[] }) => {
        return response.data.map((user: LabUser) => {
          return {
            ...user,
            firstName: capitalizeFirstLetter(user.firstName),
            lastName: capitalizeFirstLetter(user.lastName)
          };
        });
      }
    }),
    getOneUser: builder.query<UserInfo, string>({
      query: (email) => ({
        url: `/users/${email}`,
        method: 'GET'
      }),
      transformResponse: (response: UserInfo) => {
        return mapUser(response);
      },
      providesTags: ['User', 'Laboratories']
    }),
    getConnectedUser: builder.query<UserInfo, void>({
      query: () => ({
        url: `/users/me`,
        method: 'GET'
      }),
      transformResponse: (response: UserInfo) => {
        return mapUser(response);
      },
      providesTags: ['User']
    })
  })
});

export const {
  useGetLabUsersQuery,
  useCreateDentistMutation,
  useGetAllDentistsQuery,
  useLazyGetPaginatedDentistsQuery,
  usePatchDentistMutation,
  useGetOneUserQuery,
  useGetConnectedUserQuery,
  useResetPasswordMutation,
  useDeactivateDentistMutation
} = usersApi;
